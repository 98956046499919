import { Locales, route } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";
import { getGlobalConstants } from ".";
import { SolutionPage, SolutionPath } from "@/lib/types/shared/page.types";
import { homeCompanySliderCldImages } from "@/lib/constants/home";

export const SolutionsSliderItems = [
    {
        title: 'crm_integrations',
        description: 'efficiently_manage_customer_relationships_with_seamless_crm_integrations_that_streamline_your_business',
        image: "Solutions/Swoove-site_dl83uy",
        alt: 'create_your_own_story_with_swoove',
        imageClasses: "rounded-lg",
        classes: "item-1",
        link: "/solutions/crm-integrations",
    },
    {
        title: 'customer_portal',
        description: 'personalized_customer_portals_for_an_improved_user_experience_and_customer_satisfaction',
        image: "Solutions/Billezz-site_ozid30",
        alt: 'turn_your_ideas_into_reality_with_billezz',
        imageClasses: "brightness-75 rounded-lg",
        classes: "item-2",
        link: "/solutions/customer-portal",
    },
    {
        title: 'dashboards',
        description: 'intuitive_dashboards_that_visualize_essential_data_for_better_decision_making',
        image: "Solutions/Actoniq-site_acmmxw",
        alt: 'revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management',
        imageClasses: " rounded-lg",
        classes: "item-3",
        link: "/solutions/dashboards",
    },
    {
        title: 'apis_and_webhooks',
        description: 'robust_apis_and_webhooks_designed_for_optimal_connectivity_and_functionality',
        image: "Solutions/Kegels-site_fsavje",
        alt: 'fastback_integration_with_kegels_turnhout',
        imageClasses: "brightness-75 rounded-lg",
        classes: "item-4",
        link: "/solutions/api",
    },
    {
        title: 'automations',
        description: 'smart_automation_solutions_that_significantly_increase_your_operational_efficiency',
        image: "Solutions/Shopify-site_uhnhl6",
        alt: 'synchronize_your_product_information_with_woocommerce_and_shopify',
        imageClasses: "brightness-75 rounded-lg",
        classes: "item-5",
        link: "/solutions/automations",
    },
];

export const solutionPaths: SolutionPath[] = [
    {
        id: 'examples',
        name: 'examples',
        link: {
            en: '/solutions/examples',
            nl: '/oplossingen/voorbeelden'
        },
    },
    {
        id: 'crm-integrations',
        name: 'integrations',
        link: {
            en: '/solutions/crm-integrations',
            nl: '/oplossingen/crm-integraties'
        },
    },
    {
        id: 'customer-portal',
        name: 'customer_portal',
        link: {
            en: '/solutions/customer-portal',
            nl: '/oplossingen/klantenportaal'
        },
    },
    {
        id: 'dashboards',
        name: 'dashboards',
        link: {
            en: '/solutions/dashboards',
            nl: '/oplossingen/dashboards'
        },
    },
    {
        id: 'api',
        name: 'apis_and_webhooks',
        link: {
            en: '/solutions/api',
            nl: '/oplossingen/api'
        },
    },
    {
        id: 'automations',
        name: 'automations',
        link: {
            en: '/solutions/automations',
            nl: '/oplossingen/automatiseringen'
        },
    },
];

const getSolutionConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    let globalConstants = await getGlobalConstants(locale);

    const reviews = globalConstants.reviews;


    const solutionPages: SolutionPage[] = [
        {
            id: 'examples',
            title: t('examples'),
            description: t('delve_deep_into_webbeukers_portfolio_of_successful_projects_and_detailed_case_studies_showcasing_our_innovative_web_solutions_and_expertise'),
            keywords: `${t('webbeukers_cases')}, ${t('webbeukers_projects')}, ${t('webdevelopment_portfolio')}, ${t('succesfull_web_projects')}, ${t('case_study_webdesign')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("your_digital_idea_becomes_reality"),
                    subTitle: t("customized_web_solutions"),
                    buttons: [
                        {
                            text: t("contact_us"),
                            link: route("/contact", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "Swoove",
                        description: t("laravel_framework__custom_design"),
                        text: t("this_project_combines_custom_design_with_the_versatility_of_laravel_delivering_a_seamless_userfriendly_experience_see_how_our_team_brought_this_unique_web_concept_to_life"),
                        image: "Projects/swoove_ezs9iz",
                        alt: t("laravel_framework_custom_design_from_swoove"),
                        imagePosition: "left",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://swoove.com",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("billezz"),
                        description: t("react__custom_design"),
                        text: t("introducing_billezz_our_inhouse_billing_system_designed_to_simplify_invoicing_built_with_react_our_userfriendly_website_streamlines_the_billing_process_making_invoicing_a_breeze"),
                        image: "Projects/billezz_fknqqr",
                        alt: t("react_customized_design_with_billing_and_subscription_management"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://billezz.com",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("actoniq"),
                        description: t("react__custom_design"),
                        text: t("explore_actoniq_our_revolutionary_application_seamlessly_connecting_multiple_systems_including_jira_for_enhanced_project_management_unleash_efficiency_with_our_intuitive_website_designed_to_showcase_the_power_of_integration_and_system_connectivity"),
                        image: "Projects/actoniq_fvyfqc",
                        alt: t("react_customized_design_with_actoniq_with_also_a_dashboard_system"),
                        imagePosition: "left",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://actoniq.com",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "BRL Registratie",
                        description: t("wordpress_laravel__custom_design"),
                        text: t("webbeukers_custom_portal"),
                        image: "Projects/brl-registratie_osulig",
                        alt: t("wordpress_custom_made_design_thanks_to_webbeukers"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://brlregistratie.nl",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "Morso",
                        description: t("wordpress__custom_design"),
                        text: t("experience_morso_a_culinary_journey_to_1920s_antwerp_our_wordpress_website_enhanced_with_custom_plugins_reflects_our_vintageinspired_restaurant_transport_yourself_to_an_era_of_britchic_interiors_street_terraces_and_enticing_frenchbelgian_flavors_morso__where_every_meal_is_a_story"),
                        image: "Projects/morso_wys8n9",
                        alt: t("wordpress_custom_made_design_thanks_to_webbeukers"),
                        imagePosition: "left",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://morso-antwerp.be",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "De Courant",
                        description: t("wordpress__custom_plugins"),
                        text: t("discover_de_courant_where_dining_meets_technology_our_wordpresscrafted_website_seamlessly_integrated_with_an_api_offers_a_smooth_reservation_experience_explore_the_culinary_delights_of_our_restaurant_as_we_blend_exquisite_cuisine_with_cuttingedge_convenience"),
                        image: "Projects/courant_xfebf2",
                        alt: t("wordpress_customized_plugins_containing_a_reservation_system"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://decourant.be",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "Sjegers",
                        description: t("wordpress__custom_design"),
                        text: t("explore_sjegers_your_goto_for_seamless_goods_transport_in_antwerp_and_beyond_specializing_in_logistics_since_1995_our_laakdalbased_company_near_geel_traverses_the_european_roads_our_wordpress_website_enriched_with_custom_plugins_showcases_our_expertise_in_transport_and_logistics_for_businesses_in_antwerp_and_across_belgian_provinces_trust_sjegers_for_reliable_and_efficient_cargo_solutions"),
                        image: "Projects/sjegers_ufmevp",
                        alt: t("wordpress_customized_design_with_customer_wishes"),
                        imagePosition: "left",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://sjegers.eu",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "Brinks watersport",
                        description: t("wordpress__custom_plugins"),
                        text: t("webbeukers_crafted_a_stylish_wordpress_website_for_brinks_watersport_a_premier_boat_mooring_company_featuring_custom_plugins_for_seamless_reservations_elevate_your_boating_experience_with_our_userfriendly_design"),
                        image: "Projects/brinks_k4pgjt",
                        alt : t("wordpress_customized_plugins_in_your_website"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://brinkswatersport.nl",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: "KOMSIS",
                        description: t("wordpress__custom_plugins"),
                        text: t("discover_komsis_where_security_meets_innovation_specializing_in_technical_solutions_and_services_for_businesses_including_consultancy_security_cctv_ict_and_retail_security_our_wordpress_website_enhanced_with_custom_plugins_reflects_our_commitment_to_providing_holistic_vendorindependent_solutions_with_expert_implementation_and_support"),
                        image: "Projects/komsis_ifnhoa",
                        alt: t("wordpress_customized_plugins_in_your_wordpress_site"),
                        imagePosition: "left",
                        imageWidth: "w-full md:w-4/5 max-w-full md:max-w-full",
                        isExternal: true,
                        link: "https://komsis.eu",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "CompanyBanner",
                    componentProps: {
                        images: homeCompanySliderCldImages
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("control_over_applicants"),
                        description: "",
                        text: t("our_integrations_with_recruitment_systems_such_as_recruitee_tigris_and_recruit_now_simplify_the_application_process_effortlessly_manage_all_your_candidate_data_in_one_central_place_and_improve_your_recruitment_efficiency"),
                        image: "crm-logos_t7ctdo",
                        alt: t("integrations_for_efficient_candidate_management_with_recruitee_tigris_and_recruit_now"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("cross_platform_data"),
                        description: "",
                        text: t("replace_endless_excel_sheets_with_smart_links_with_tools_such_as_jira_monday_and_asana_our_solutions_provide_you_with_accurate_errorfree_and_valuable_information_allowing_you_to_focus_on_the_essence_of_project_management"),
                        image: "cross-platform_nmvqjp",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        alt: t("wellknown_project_management_and_collaboration_tools_such_as_jira_confluence_mondaycom_and_miro"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("get_on_board_of_efficiency"),
                        description: "",
                        text: t("a_water_sports_company_has_simplified_the_boat_creation_process_allowing_customers_to_effortlessly_build_their_own_boat_and_add_options_on_its_website_this_has_significantly_improved_the_sales_process_a_seamless_integration_has_been_added_for_existing_customers_allowing_them_to_plan_winter_storage_during_winter_storage_not_only_storage_is_provided_but_maintenance_is_also_planned_so_that_customers_can_immediately_enjoy_their_boat_after_storage"),
                        image: "Projects/brinks_configuration_rvq6fm",
                        alt: t("diagram_showcasing_various_configuration_options_for_boat_layouts_and_equipment"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("workflow_automation_for_manufacturing_company"),
                        description: "",
                        text: t("for_a_manufacturing_company_we_developed_an_automation_system_that_automates_production_planning_inventory_management_and_shipping_processes_resulting_in_reduced_margins_of_error_and_increased_productivity"),
                        image: "Projects/hostbeukers-create-product_onzagu",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        alt: t("hostbeukers_management_panel_where_customers_can_monitor_their_hosting_packages"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("explore_and_create_3d_magic_on_the_website"),
                        description: "",
                        text: t("in_our_recent_project_we_developed_a_customizable_customer_portal_focused_on_subscription_management_and_direct_debits_this_portal_allows_users_to_manage_their_own_customer_base_products_and_billings_resulting_in_significant_time_savings_and_efficiencies_in_billing_processes_suitable_for_various_industries_this_system_offers_a_flexible_solution_for_automating_and_simplifying_recurring_billing"),
                        image: "Projects/swoove-upload_dthhjf",
                        alt: t("create_your_own_story_with_swoove"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("subscription_management_solution"),
                        description: "",
                        text: t("in_our_recent_project_we_developed_a_customizable_customer_portal_focused_on_subscription_management_and_direct_debits_this_portal_allows_users_to_manage_their_own_customer_base_products_and_billings_resulting_in_significant_time_savings_and_efficiencies_in_billing_processes_suitable_for_various_industries_this_system_offers_a_flexible_solution_for_automating_and_simplifying_recurring_billing"),
                        image: "Solutions/Billezz-site_ozid30",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        alt: t("turn_your_ideas_into_reality_with_billezz"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("crossplatform_data_mapping"),
                        description: "",
                        text: t("we_have_developed_systems_to_collect_and_map_data_from_tools_such_as_jira_and_asana_to_create_crossplatform_reports_and_dashboards_eliminating_the_need_to_switch_between_different_systems_for_a_complete_overview"),
                        image: "Solutions/Actoniq-site_acmmxw",
                        alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("recruitee_integration"),
                        description: "",
                        text: t("we_created_an_integration_with_recruitee_for_an_hr_and_recruitment_company_allowing_them_to_manage_all_recruitment_processes_in_one_place_this_integration_has_also_been_applied_to_other_customers_with_platforms_such_as_recruitnow_and_tigris"),
                        image: "Projects/aceg_zlumb2",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        alt: t("a_look_at_the_aceg_company"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("fastback_integration"),
                        description: "",
                        text: t("we_have_realized_an_integration_with_fastback_for_a_car_garage_which_makes_it_possible_to_view_and_filter_their_car_range_from_an_external_platform_directly_on_their_own_website"),
                        image: "Solutions/Kegels-site_fsavje",
                        alt: t("fastback_integration_with_kegels_turnhout"),
                        imagePosition: "right",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("the_synchroniser_plugin"),
                        description: "",
                        text: t("for_web_shops_that_use_multiple_platforms_we_have_developed_a_plugin_that_synchronizes_product_information_and_inventory_between_woocommerce_and_shopify_this_ensures_consistent_inventory_and_product_updates_across_multiple_stores"),
                        image: "Projects/cheapassbikes_zmc7hl",
                        imageWidth: "w-full md:w-4/5 max-w-full",
                        alt: t("webshop_cheapassbikes_with_second_hand_bicycles"),
                        imagePosition: "left",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    }
                }
            ]
        },
        {
            id: 'crm-integrations',
            title: t('crm_integrations'),
            description: t('strengthen_your_customer_relationship_management_with_webbeukers_crm_integration_services_which_provide_seamless_synchronization_and_efficiency'),
            keywords: `${t('crm_integrations')}, ${t('webbeukers')}, ${t('customer_relationship_management')}, ${t('business_processes')} ${t('crm_systems')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("crm_integrations"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("seamless_crm_integrations_your_key_to_efficient_business_operations"),
                        text: t("transform_your_business_operations_with_our_advanced_crm_integrations_through_powerful_connections_with_leading_systems_we_take_your_customer_management_to_a_higher_level_discover_how_our_solutions_streamline_your_data_flow_and_increase_efficiency_read_more_about_our_successful_projects_and_get_in_touch_to_see_how_we_can_solve_your_crm_challenges"),
                    }
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("control_over_applicants"),
                        description: "",
                        text: t("our_integrations_with_recruitment_systems_such_as_recruitee_tigris_and_recruit_now_simplify_the_application_process_effortlessly_manage_all_your_candidate_data_in_one_central_place_and_improve_your_recruitment_efficiency"),
                        image: "crm-logos_t7ctdo",
                        alt: t("integrations_for_efficient_candidate_management_with_recruitee_tigris_and_recruit_now"),
                        imagePosition: "left",
                        imageWidth: "w-2/3 max-w-full",
                        imageClassName: "md:mx-auto",
                        cols: {
                            left: 'w-full md:w-2/3',
                            right: 'w-full md:w-1/3'
                        }
                    },
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("cross_platform_data"),
                        description: "",
                        text: t("replace_endless_excel_sheets_with_smart_links_with_tools_such_as_jira_monday_and_asana_our_solutions_provide_you_with_accurate_errorfree_and_valuable_information_allowing_you_to_focus_on_the_essence_of_project_management"),
                        image: "cross-platform_nmvqjp",
                        alt: t("wellknown_project_management_and_collaboration_tools_such_as_jira_confluence_mondaycom_and_miro"),
                        imagePosition: "right",
                        imageWidth: "w-2/3 max-w-full",
                        imageClassName: "md:mx-auto",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3 flex justify-end'
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
            ]
        },
        {
            id: 'customer-portal',
            title: t('customer_portal'),
            description: t('offer_your_customers_a_seamless_experience_with_webbeukers_customer_portal_solutions_designed_for_optimal_interaction_and_service'),
            keywords: `${t('customer_portal')}, ${t('webbeukers')}, ${t('customer_interaction')}, ${t('customer_service')}, ${t('online_portal')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("customer_portal"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("advanced_customer_portals_tailored_for_your_success"),
                        text: t("discover_the_versatility_of_our_tailormade_customer_portals_while_we_share_one_specific_case_our_expertise_is_extensive_and_ready_to_design_any_unique_portal_to_suit_your_needs_from_subscription_management_to_automated_collections_we_offer_solutions_that_streamline_your_business_processes_are_you_interested_in_a_solution_specifically_designed_for_your_challenges_contact_us_to_discuss_how_we_can_realize_your_ideal_customer_portal"),
                    }
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("subscription_management_solution"),
                        description: "",
                        text: t("in_our_recent_project_we_developed_a_customizable_customer_portal_focused_on_subscription_management_and_direct_debits_this_portal_allows_users_to_manage_their_own_customer_base_products_and_billings_resulting_in_significant_time_savings_and_efficiencies_in_billing_processes_suitable_for_various_industries_this_system_offers_a_flexible_solution_for_automating_and_simplifying_recurring_billing"),
                        image: "Solutions/Billezz-site_ozid30",
                        alt: t("turn_your_ideas_into_reality_with_billezz"),
                        imagePosition: "left",
                        imageWidth: "w-full max-w-full",
                        imageClassName: "md:mx-auto",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                    },
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("client_portal_energy_labels_title"),
                        description: "",
                        text: t("client_portal_energy_labels_text"),
                        image: "Projects/brl-registratie_osulig",
                        alt: t("client_portal_energy_labels_alt"),
                        imagePosition: "right",
                        imageWidth: "w-full max-w-full",
                        imageClassName: "md:mx-auto",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                    },
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
            ]
        },
        {
            id: 'dashboards',
            title: t('dashboards'),
            description: t('unlock_powerful_insights_with_webbeukers_custom_dashboards_designed_for_clear_data_analysis_and_decision_making'),
            keywords: `${t('dashboards')}, ${t('webbeukers')}, ${t('data_visualization')}, ${t('business_analysis')}, ${t('kpi_tracking')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("dashboards"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("intuitive_dashboards_your_data_our_expertise"),
                        text: t("looking_for_the_ideal_dashboard_that_exactly_meets_your_specific_needs_with_us_you_are_at_the_right_place_we_specialize_in_creating_custom_dashboards_that_seamlessly_integrate_and_visualize_any_type_of_data_from_any_source_our_case_with_actoniq_demonstrates_our_expertise_in_developing_flexible_and_intuitive_dashboards_where_users_can_create_connections_themselves_and_convert_data_into_clear_customizable_graphs_all_this_with_userfriendly_draganddrop_functionality_inspired_by_this_case_we_invite_you_to_join_us_in_designing_the_perfect_dashboard_solution_that_transforms_your_data_insights_contact_us_to_discuss_your_unique_dashboard_needs_and_let_us_help_you_take_your_data_management_to_the_next_level"),
                        images: [
                            {
                                image: "CompanyLogos/actoniq-color_rlcb1w",
                                maxWidth: "max-w-1/2",
                                className: "mx-auto",
                                alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                            },
                            {
                                image: "Solutions/Actoniq-site_acmmxw",
                                alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                            }
                        ],
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                },
            ]
        },
        {
            id: 'api',
            title: t('apis_and_webhooks'),
            description: t('discover_how_webbeukers_api_solutions_can_improve_connectivity_between_your_systems_and_services_for_a_streamlined_workflow'),
            keywords: `${t('api_solutions')}, ${t('webbeukers')}, ${t('system_integration')}, ${t('software_link')}, ${t('data_exchange')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("apis_and_webhooks"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("seamless_api__webhook_integrations_your_connectivity_solutions"),
                        text: t("unlock_unprecedented_possibilities_with_our_api_and_webhook_integrations_whether_you_synchronize_data_between_different_platforms_or_automate_your_workflows_we_offer_tailormade_solutions_that_increase_the_efficiency_and_functionality_of_your_systems_discover_how_our_approach_turns_your_technical_challenges_into_seamless_solutions_from_ecommerce_to_project_management_our_expertise_in_apis_and_webhooks_is_ready_to_meet_your_specific_needs_let_us_explore_the_perfect_integration_solution_for_you_together"),
                    }
                },
                {
                    type: "component",
                    componentKey: "SliderBlock",
                    componentProps: {
                        title: t("the_synchroniser_plugin"),
                        text: t("for_web_shops_that_use_multiple_platforms_we_have_developed_a_plugin_that_synchronizes_product_information_and_inventory_between_woocommerce_and_shopify_this_ensures_consistent_inventory_and_product_updates_across_multiple_stores"),
                        images: [
                            {
                                src: "Projects/cheapassbikes_zmc7hl",
                                alt: t("webshop_cheapassbikes_with_second_hand_bicycles"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/2ndehandsfietsenwinkel_btferr",
                                alt: t("webshop_secondhandbikes_with_the_help_of_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/stationbikes_nrqksb",
                                alt: t("station_bikes_website_whoocommerce_and_shopify_integration"),
                                className: "aspect-16/11 object-cover"
                            }
                        ],
                        className: "md:w-2/3 mr-auto",
                        sliderPosition: "left"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("fastback_integration"),
                        text: t("we_have_realized_an_integration_with_fastback_for_a_car_garage_which_makes_it_possible_to_view_and_filter_their_car_range_from_an_external_platform_directly_on_their_own_website"),
                        image: "Solutions/Kegels-site_fsavje",
                        alt: t("fastback_integration_with_kegels_turnhout"),
                        imagePosition: "right",
                        imageWidth: "md:w-2/3 max-w-full md:ml-auto",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        },
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component",
                    componentKey: "SliderBlock",
                    componentProps: {
                        title: t("recruitee_integration"),
                        text: t("we_created_an_integration_with_recruitee_for_an_hr_and_recruitment_company_allowing_them_to_manage_all_recruitment_processes_in_one_place_this_integration_has_also_been_applied_to_other_customers_with_platforms_such_as_recruitnow_and_tigris"),
                        images: [
                            {
                                src: "Projects/aceg_zlumb2",
                                alt: t("aceg_website_build_by_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/aceg-vacancy_damzor",
                                alt: t("recruitee_integration_with_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/resol_jssfbe",
                                alt: t("resol_website_build_by_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/abl_pkxuia",
                                alt: t("abl_website_with_recruitee_integration"),
                                className: "aspect-16/11 object-cover"
                            }
                        ],
                        className: "md:w-2/3 mr-auto",
                        sliderPosition: "left"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("crossplatform_data_mapping"),
                        text: t("we_have_developed_systems_to_collect_and_map_data_from_tools_such_as_jira_and_asana_to_create_crossplatform_reports_and_dashboards_eliminating_the_need_to_switch_between_different_systems_for_a_complete_overview"),
                        image: "Solutions/Actoniq-site_acmmxw",
                        alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                        imagePosition: "right",
                        imageWidth: "md:w-4/5 max-w-full md:max-w-full md:ml-auto",
                        cols: {
                            left: 'w-full md:w-1/3',
                            right: 'w-full md:w-2/3'
                        },
                    }
                },
            ]
        },
        {
            id: 'automations',
            title: t('automations'),
            description: t('improve_your_business_operations_with_webbeukers_automation_solutions_designed_to_optimize_processes_and_increase_productivity'),
            keywords: `${t('automation_solutions')}, ${t('webbeukers')}, ${t('process_optimization')}, ${t('company_innovation')}, ${t('technologic_advancements')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("automations"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("efficient_automation_solutions_your_work_made_smarter"),
                        text: t("enter_the_world_of_automation_where_innovation_and_efficiency_come_together_our_solutions_are_designed_to_simplify_and_accelerate_your_business_processes_allowing_you_to_focus_on_what_really_matters_from_automating_routine_tasks_to_streamlining_complex_workflows_we_provide_smart_tailored_automation_strategies_that_transform_your_daily_operations_discover_how_our_advanced_automation_techniques_can_take_your_business_to_the_next_level_are_you_ready_to_improve_the_efficiency_of_your_organization_with_our_innovative_automation_solutions"),
                        images: [
                            {
                                image: "CompanyLogos/actoniq-color_rlcb1w",
                                maxWidth: "max-w-1/2",
                                className: "mx-auto",
                                alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                            },
                            {
                                image: "Solutions/Actoniq-site_acmmxw",
                                alt: t("revolutionize_your_business_efficiency_with_actoniq_the_future_of_integrated_project_management"),
                            }
                        ],
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2 flex flex-col gap-4'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("explore_and_create_3d_magic_on_the_website"),
                        text: t("in_our_recent_project_we_developed_a_customizable_customer_portal_focused_on_subscription_management_and_direct_debits_this_portal_allows_users_to_manage_their_own_customer_base_products_and_billings_resulting_in_significant_time_savings_and_efficiencies_in_billing_processes_suitable_for_various_industries_this_system_offers_a_flexible_solution_for_automating_and_simplifying_recurring_billing"),
                        image: "Projects/swoove-upload_dthhjf",
                        alt: t("create_your_own_story_with_swoove"),
                        imagePosition: "left",
                        imageClassName: "md:mx-auto",
                        imageWidth: "w-full max-w-full",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("workflow_automation_for_manufacturing_company"),
                        text: t("for_a_manufacturing_company_we_developed_an_automation_system_that_automates_production_planning_inventory_management_and_shipping_processes_resulting_in_reduced_margins_of_error_and_increased_productivity"),
                        image: "Projects/hostbeukers-create-product_onzagu",
                        alt: t("hostbeukers_management_panel_where_customers_can_monitor_their_hosting_packages"),
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: 'w-full max-w-full',
                        imageClassName: "md:mr-auto"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component",
                    componentKey: "SliderBlock",
                    componentProps: {
                        title: t("recruitee_integration"),
                        text: t("we_created_an_integration_with_recruitee_for_an_hr_and_recruitment_company_allowing_them_to_manage_all_recruitment_processes_in_one_place_this_integration_has_also_been_applied_to_other_customers_with_platforms_such_as_recruitnow_and_tigris"),
                        images: [
                            {
                                src: "Projects/aceg_zlumb2",
                                alt: t("aceg_website_build_by_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/aceg-vacancy_damzor",
                                alt: t("recruitee_integration_with_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/resol_jssfbe",
                                alt: t("resol_website_build_by_webbeukers"),
                                className: "aspect-16/11 object-cover"
                            },
                            {
                                src: "Projects/abl_pkxuia",
                                alt: t("abl_website_with_recruitee_integration"),
                                className: "aspect-16/11 object-cover"
                            }
                        ],
                        className: "md:w-2/3 mr-auto",
                        sliderPosition: "left"
                    }
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                },
                {
                    type: "component",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("get_on_board_of_efficiency"),
                        text: t("a_water_sports_company_has_simplified_the_boat_creation_process_allowing_customers_to_effortlessly_build_their_own_boat_and_add_options_on_its_website_this_has_significantly_improved_the_sales_process_a_seamless_integration_has_been_added_for_existing_customers_allowing_them_to_plan_winter_storage_during_winter_storage_not_only_storage_is_provided_but_maintenance_is_also_planned_so_that_customers_can_immediately_enjoy_their_boat_after_storage"),
                        image: "Projects/brinks_configuration_rvq6fm",
                        alt: t("diagram_showcasing_various_configuration_options_for_boat_layouts_and_equipment"),
                        wrapperStyle: {
                            justifyContent: "flex-start!important",
                            gap: "2.5rem!important"
                        },
                        imagePosition: "right",
                        imageWidth: "w-full max-w-full",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        }
                    }
                },
            ]
        },
    ];

    for (const page of solutionPages) {
        const path = solutionPaths.find(path => path.id === page.id);

        if (path) {
            page.link = path.link;
            page.title = page.title;
        }
    }

    return {
        solutionPages
    };
}

export default getSolutionConstants;