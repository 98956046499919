'use client';

import React from 'react';
import { Container, Typography } from '@mui/material';

import { ButtonOne } from '@webbeukers/wb-library.package';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';

import { convertMarkdownLinks } from '@/lib/utils';
import { DefaultPageProps } from '@/lib/types/shared/page.types';
import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';

type Props = {
    convertTextToLink?: boolean,
    title?: string,
    text?: string,
    text2?: string,
    alt?: string ,
    image?: string,
    images?: Array<any>,
    imagePosition?: string,
    ul?: Array<any>,
    buttons?: Array<any>,
} & DefaultPageProps;

const ImageSection = ({ convertTextToLink, title, text, text2, alt = "", buttons, ul, image, images, imagePosition, params }: Props) => {
    return (
        <section className='py-16'>
            <Container sx={{
                display: 'flex',
            }} className={`flex ${imagePosition === 'left' ? 'flex-col-reverse' : 'flex-col'} lg:flex-row items-center`}>
                {imagePosition === 'left' && image &&
                    <div className='mr-0 md:mr-auto mt-10 xl:mt-0 md:-ml-50px lg:-ml-7/20 w-2/3 lg:w-2/3 xl:w-1/2'>
                        <Image
                            loading='eager'
                            src={image}
                            alt={alt}
                            width={491}
                            height={457}
                            className='mr-0 md:mr-auto max-w-3/4 md:max-w-none h-auto rounded-2xl md:rounded-l-none'
                        />
                    </div>
                }

                {imagePosition === 'left' && images &&
                    <div className='mr-0 md:mr-auto mt-10 xl:mt-0 md:-ml-50px lg:-ml-7/20 w-2/3 lg:w-2/3 xl:w-1/2'>
                        {images.map((image, index) => (
                            <Image
                                key={index}
                                loading='eager'
                                src={image.image}
                                alt={image.alt}
                                width={491}
                                height={457}
                                className={`mr-0 md:mr-auto ${image.maxWidth ? image.maxWidth : 'max-w-3/4 md:max-w-none'} h-auto rounded-2xl md:rounded-l-none ${image.className ? image.className : ''}`}
                            />
                        ))}
                    </div>
                }

                <div className='flex w-full lg:w-2/3'>
                    <div>
                        <MoveFadeInAnimation delay={0} direction="right" distance="xl" amount={0.5}>
                            <HeadingText variant="h3" className='mb-4 max-w-screen-md'>
                                {title}
                            </HeadingText>
                        </MoveFadeInAnimation>
                        <MoveFadeInAnimation delay={100} direction="right" distance="xl" amount={0.5}>
                            <Typography className='text-start normal-case max-w-screen-md' variant='body1'>
                                {convertTextToLink ?
                                    <span className='text-start normal-case max-w-screen-md' dangerouslySetInnerHTML={{
                                        __html: convertMarkdownLinks(text!, params.locale)
                                    }}></span>
                                : text}
                            </Typography>
                        </MoveFadeInAnimation>
                        {text2 &&
                            <Typography className='text-start normal-case max-w-screen-md mt-3' variant='body1'>
                                {text2}
                            </Typography>
                        }
                        
                        {ul &&
                            <ul className="list-disc list-inside mt-5 max-w-17/20">
                                {ul.map((item, index) => (
                                    <li key={index} className="flex gap-3 my-2">
                                        {item.icon && item.icon !== '' && (
                                            <Image
                                                loading='lazy'
                                                src={item.icon}
                                                alt={item.alt}
                                                width={20}
                                                height={19.05}
                                                className="w-5 h-fit mt-1"
                                            />
                                        )}
                                        <MoveFadeInAnimation delay={300 * index} direction="right" distance="xl" amount={0.5}>
                                            <Typography className='text-start normal-case max-w-screen-md' variant='body1'>
                                                <span className="font-bold">{item.title}</span> {item.text}
                                            </Typography>
                                        </MoveFadeInAnimation>
                                    </li>
                                ))}
                            </ul>
                        }

                        {buttons &&
                            <div className="flex flex-col md:flex-row justify-start items-start mx-0  gap-2 mt-5">
                                {buttons.map((button, index) => (
                                    <MoveFadeInAnimation key={index} delay={300 * index} direction="right" distance="xl" amount={0.5}>
                                        <div key={index} className="w-fit">
                                            {(button.onclick) ? (
                                                <ButtonOne variant={button.variant} text={button.text} type="button" onclick={button.onclick} hoverEffect={true} />
                                            ) : (
                                                <ButtonOne variant={button.variant} text={button.text} link={button.link} hoverEffect={true} />
                                            )}
                                            {/* <ButtonOne variant={button.variant} text={button.text} link={button.link} hoverEffect={true} /> */}
                                        </div>
                                    </MoveFadeInAnimation>
                                ))}
                            </div>
                        }

                    </div>
                </div>

                {imagePosition === 'right' && image &&
                    <div className='ml-0 md:ml-auto mt-10 xl:mt-0 md:-mr-50px lg:-mr-7/20 w-full lg:w-2/3 xl:w-1/2'>
                        <Image
                            loading='eager'
                            src={image}
                            alt={alt}
                            width={491}
                            height={457}
                            className='ml-0 md:ml-auto max-w-3/4 md:max-w-none h-auto rounded-2xl md:rounded-r-none'
                        />
                    </div>
                }

                {imagePosition === 'right' && images &&
                    <div className='ml-0 md:ml-auto mt-10 xl:mt-0 md:-mr-50px lg:-mr-7/20 w-2/3 lg:w-2/3 xl:w-1/2'>
                        {images.map((image, index) => (
                            <Image
                                key={index}
                                loading='eager'
                                src={image.image}
                                alt={image.alt}
                                width={491}
                                height={457}
                                className={`ml-0 md:ml-auto ${image.maxWidth ? image.maxWidth : 'max-w-3/4 md:max-w-none'} h-auto rounded-2xl md:rounded-r-none ${image.className ? image.className : ''}`}
                            />
                        ))}
                    </div>
                }
            </Container>
        </section>
    );
}

export default ImageSection;