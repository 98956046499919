'use client'

import { DefaultFieldProps } from '@/lib/types/shared/form.types'
import { Params } from '@/lib/types/shared/page.types'
import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'


type Props = DefaultFieldProps & {
    params: Params,
    value: string | number | null,
    placeholder?: string,
}

export default function Textarea({
    params,
    changeCallBack,
    translation = null,
    className = "",
    value,
    name,
    id,
    placeholder = "",
}: Props) {
    let t = useTranslations(translation?.namespace ?? '') as any;
    if (translation === null) {
        t = (string: string) => string;
    }
    
    const [focusValue, setFocusValue] = useState(value as string);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const setValueBeforeBlur = (e: any) => {
        setFocusValue(e.target.value);
    }
    
    const handleBlurChange = (e: any) => {
        if (focusValue === e.target.value) return;
        changeCallBack(name, e.target.value)
    }

    const handleOnInput = (e: any) => {        
        if (typeof e.target.value === 'string') {
            let lines = e.target.value.split(/\r\n|\r|\n/).length
            if (lines < 2) {
                lines = 2;
            }
            setRows(lines);
        }
    }

    const [rows, setRows] = useState(2);
    useEffect(() => {
        if (typeof value === 'string') {
            let lines = value.toString().split(/\r\n|\r|\n/).length
            if (lines < 2) {
                lines = 2;
            }
            setRows(lines);
        }
    }, [value])

    return (
        <>
            <textarea ref={textareaRef} autoComplete='no' rows={rows} defaultValue={value ?? ''} onInput={handleOnInput} onFocus={(e) => setValueBeforeBlur(e)} onBlur={(e) => handleBlurChange(e)}  id={id} name={name} placeholder={t(placeholder)} className={`${className} py-2 px-5 rounded-xl border-none inline-flex text-gray-950 ring-0 ring-transparent shadow-inner text-base font-bold transition-all duration-300`}>
            </textarea>
        </>
    )
}
