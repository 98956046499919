import { Locales, route } from "@/lib/navigationFunctions";

import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import ms from "ms";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const timeAgo = (timestamp: Date, timeOnly?: boolean): string => {
  if (!timestamp) return "never";
  return `${ms(Date.now() - new Date(timestamp).getTime())}${
    timeOnly ? "" : " ago"
  }`;
};

export async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init);

  if (!res.ok) {
    const json = await res.json();
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number;
      };
      error.status = res.status;
      throw error;
    } else {
      throw new Error("An unexpected error occurred");
    }
  }

  return res.json();
}

export function nFormatter(num: number, digits?: number) {
  if (!num) return "0";
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const rx = /\.0+$|(\.\d*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol
    : "0";
}

export function capitalize(str: string) {
  if (!str || typeof str !== "string") return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const truncate = (str: string, length: number) => {
  if (!str || str.length <= length) return str;
  return `${str.slice(0, length)}...`;
};

export const convertMarkdownLinks = (text: string, locale: Locales, external = false) => {
  const regex = /\[([^\]]+)]\(([^)]+)\)(@[^=]+=[^@]+@)?/g;

	return text.replace(regex, (match, p1, p2, p3 = null) => {
		let attributes = '';

        let suffix = '';
        if (p2.includes(',')) {
            if (!p2.includes('?')) {
                suffix = p2.split(',')[1];
                p2 = p2.split(',')[0];

                p2 = p2.toLowerCase();
            }
        } else {
            if (!p2.includes('?')) {
                p2 = p2.toLowerCase();
            }
        }

		if (p3) {
			const propMatch = /@([^=]+)=([^@]+)@/.exec(p3);
			if (propMatch) {
				const [, prop, value] = propMatch;

				attributes = ` ${prop}=${value}`;
			}
		}

		if (external) {
			return `<a class="underline"${attributes} href="${p2}${suffix}">${p1}</a>`;
		} else {
			return `<a class="underline"${attributes} href="${route(p2, locale, suffix)}">${p1}</a>`;
		}
	});
};

export const convertMarkdownBolds = (text: string) => {
  // for text with "**" to be bold
  const regex = /\*\*([^*]+)\*\*/g;

  return text.replace(regex, (match, p1) => {
    return `<b>${p1}</b>`;
  });
};

export async function catchEm<T>(promise: Promise<T>): Promise<[Error | null, T?]> {
  try {
      const data = await promise;
      return [null, data];
  } catch (err) {
      // Ensure the error is of type Error
      if (err instanceof Error) {
          return [err, undefined];
      } else {
          // Handle non-Error objects thrown as errors
          return [new Error(String(err)), undefined];
      }
  }
}