// src/theme.ts
'use client';
// import { Roboto } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import { blinker } from "@/app/[locale]/fonts/index";

// const roboto = Roboto({
// 	weight: ['300', '400', '500', '700'],
// 	subsets: ['latin'],
// 	display: 'swap',
// });

const partial = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

const theme = createTheme({
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					// fontWeight: 'bold',
					// fontFamily: 'var(--font-blinker)',
					fontFamily: blinker.style.fontFamily,
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					[partial.breakpoints.up('xs')]: {
						paddingLeft: '25px!important',
						paddingRight: '25px!important',
					},
					[partial.breakpoints.up('md')]: {
						paddingLeft: '50px!important',
						paddingRight: '50px!important',
					},
					// paddingLeft: '50px!important',
					// paddingRight: '50px!important',
				},
			},
		},

		MuiMenu: {
			styleOverrides: {
				paper: {
					boxShadow: 'none',
					backgroundColor: 'transparent',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: '6px',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#1d1d1d',
					// boxShadow: 'none',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					// Hier stel je stijlen in voor de root van de MuiTextField
					marginTop: '0.7rem',
					marginBottom: '0.7rem',

					// Om de input borderkleur te wijzigen
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							// borderColor: '#222121', // Verander naar gewenste kleur
						},
						// '&:hover fieldset': {
						//   borderColor: 'rgba(255, 255, 255, 0.5)', // Verander naar gewenste hover kleur
						// },
						// '&.Mui-focused fieldset': {
						//   borderColor: '#fff', // Verander naar gewenste focus kleur
						// },
					},
				},
			},
		},
	},
});

export default theme;