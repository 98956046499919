'use client'

import { DefaultFieldProps, Option } from '@/lib/types/shared/form.types'
import { Params } from '@/lib/types/shared/page.types'
import { useTranslations } from 'next-intl'
import { NPM_ButtonOne } from '@/components/shared/index'
import { useEffect, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import useDebounce from '@/lib/hooks/use-debounce'


type Props = DefaultFieldProps & {
    params: Params,
    value: string | number | null,
    isMultiple?: boolean,
    min: number,
    max: number,
}

export default function Range({
    params,
    changeCallBack,
    translation = null,
    className = "",
    value,
    name,
    id,
    min,
    max
}: Props) {
    let t = useTranslations(translation?.namespace ?? '') as any;
    if (translation === null) {
        t = (string: string) => string;
    }

    const [valueState, setValueState] = useState(value as number);
    useEffect(() => {
        setValueState(value as number);
    }, [value])

    const [rangeValue, setRangeValue] = useState(value as string);
    const debounceValue = useDebounce(rangeValue, 300);

    const controller = new AbortController();
    const controllerRef = useRef(controller);

    const handleOnChange = (e: any) => {
        setValueState(e.target.value);
        setRangeValue(e.target.value);
    }

    const internChangeCallBack = (name: string, value: string) => {
        changeCallBack(name, parseInt(value))
    }

    useEffect(() => {
        if (debounceValue) internChangeCallBack(name, debounceValue)
        return cancelChange()
    }, [debounceValue])
    
    const cancelChange = () => {
        controllerRef.current.abort();
    }

    return (
        <>
            <div className={`w-full flex gap-4 h-10 items-center ${className}`}>
                <div>
                    <Typography variant="body1" className="normal-case text-white font-extrabold whitespace-nowrap whitespace-nowrap-important">{valueState} / {max}</Typography>
                </div>
                <div className='w-full h-full flex items-center relative'>
                    <input type="range" min={min} max={max} id={id} name={name} value={valueState as number} className="w-full" onChange={(e) => handleOnChange(e)} />
                </div>
            </div>
        </>
    )
}
