'use client'

import { DefaultFieldProps } from '@/lib/types/shared/form.types'
import { Params } from '@/lib/types/shared/page.types'
import { useTranslations } from 'next-intl'
import { useState } from 'react'


type Props = DefaultFieldProps & {
    params: Params,
    value: string | number | null,
    type: "text" | "number" | "email" | "password",
    placeholder?: string,
}

export default function Input({
    params,
    changeCallBack,
    translation = null,
    className = "",
    value,
    name,
    id,
    type = "text",
    placeholder = "",
}: Props) {
    let t = useTranslations(translation?.namespace ?? '') as any;
    if (translation === null) {
        t = (string: string) => string;
    }

    if (!value) {
        value = "";
    }

    if (type == "number") {
        value = `${value ?? 0}`;
    }

    const [focusValue, setFocusValue] = useState(value as string);

    const setValueBeforeBlur = (e: any) => {
        setFocusValue(e.target.value);
    }
    
    const handleBlurChange = (e: any) => {
        if (focusValue === e.target.value) return;

        if (type === "number") {
            changeCallBack(name, parseInt(e.target.value))
        } else {
            changeCallBack(name, e.target.value)
        }
    }

    return (
        <>
            <input defaultValue={value as string} autoComplete='no' onFocus={(e) => setValueBeforeBlur(e)} onBlur={(e) => handleBlurChange(e)} id={id} name={name} placeholder={t(placeholder)} type={type} className={`${className} w-full py-2 px-5 rounded-xl border-none inline-flex text-gray-950 ring-0 ring-transparent shadow-inner text-base font-bold`}/>
        </>
    )
}
