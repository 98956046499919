import { locales } from "@/lib/navigation";
import { BreadcrumbItems } from "@/lib/types/shared/page.types";

export const getBreadcrumbArray = (currentPath: string, extendedPathnames: any) => {
    const breadcrumbArray: BreadcrumbItems<typeof locales> = {};
    let currentPathname = currentPath;
    let last = false;

    while (!last) {
        const currentPathnameObject = extendedPathnames[currentPathname];

        if (currentPathnameObject) {
            breadcrumbArray[currentPathname] = currentPathnameObject;
            currentPathname = currentPathnameObject.breadcrumbParentKey ?? '/';
        } else {
            currentPathname = '/';
        }

        if (currentPathnameObject === undefined || !currentPathnameObject.breadcrumbParentKey) {
            last = true;
        }
    }

    const reversedBreadcrumbArray: BreadcrumbItems<typeof locales> = {};
    Object.keys(breadcrumbArray).reverse().forEach(key => {
        reversedBreadcrumbArray[key] = breadcrumbArray[key];
    });

    return reversedBreadcrumbArray;
};

export const divideTextShort = (text: string, length = 640) => {
    const isAlreadyShort = text.length <= length;
    return {
        isAlreadyShort: isAlreadyShort,
        original: text,
        short: text.length > length ? text.slice(0, length) + '...' : text
    }
}