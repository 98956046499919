import { Locales } from "@/lib/navigationFunctions";
import { WebsiteTabItem, PricingTabItem, WebsitePrices, WebsitePaths } from "@/lib/types/shared/page.types";
import { getTranslations } from "next-intl/server";

export const developmentTabs: WebsiteTabItem[] = [
    {
        name: 'Back-end',
        items: [
            'NodeJS',
            'Java',
            // 'Python',
            'PHP',
            // 'C++',
            'C#',
            'TypeScript',
            '.NET',
            // 'Shell',
            'Api',
            'SQL',
            'PostgreSQL',
            'MySQL',
            'MongoDB',
            'oAuth',
            'Azure functions',
            'ServiceBus',
            'KeyVault',
            'Worker Services',
        ]
    },
    {
        name: 'Front-end',
        items: [
            'HTML',
            'CSS',
            'JavaScript',
            'React',
            'Tailwind',
            'ThreeJS',
            'React Spring',
            'SwiperJs',
            // 'Angular',
        ]
    },
    {
        name: 'Frameworks',
        items: [
            'NestJS',
            'NextJS',
            'NuxtJS',
            'Laravel',
            'VueJS',
            'Bootstrap',
            'Material UI',
        ]
    },
    {
        name: 'Webcare',
        items: [
            'Google Analytics',
            'Google Tag Manager',
            'Google Optimize',
            'Google My Business',
            'Microsoft 365',
            'Microsoft Azure',
            'Microsoft Exchange',
        ]
    },
    {
        name: 'Webhosting',
        items: [
            'Apache',
            'Nginx',
            // 'IIS',
            'Plesk',
            // 'VestaCP',
            'DirectAdmin',
            'cPanel',
            'WHM',
            'WHMCS',
        ]
    },
    {
        name: 'CMS',
        items: [
            'WordPress',
            'Joomla',
            'Drupal',
            'WooCommerce',
            'Shopify',
            'Directus',
            'Umbraco',
        ]
    }
];

export const marketingTabs: WebsiteTabItem[] = [
    {
        name: 'Branding / Strategie',
        items: [
            'Rebranding',
            'Employer branding',
            'Brand design',
            'Brand workshop',
            'Merkstrategie',
        ]
    },
    {
        name: 'Grafisch ontwerp',
        items: [
            'Interieur reclame',
            'Gevelreclame',
            'Reclamebord',            
            'Flyer',
            'Brochure',
            'Poster',
            'Banner',
            'Roll-up banner',
            'Spandoek',
            'Vlag',
            'Sticker',
            'Autobelettering',
        ],
    },
    {
        name: 'Content creatie',
        items: [
            'SEO teksten',
            'Blogartikelen',
            'Nieuwsbrieven',
            'Persberichten',
            'Productteksten',
            'Teksten schijven',
            'Webteksten',
            'Whitepapers',  
        ]
    },
    {
        name: 'Digitale marketing',
        items: [
            'Lead generation',
            "Landingspagina's",
            'E-mailmarketing',
            'Heatmaps',
            'Conversie optimalisatie',
            'Social media posts',
            'Social media advertising',
        ],
    },
    {
        name: 'SEA / SEO',
        items: [
            'Keywordresearch',
            'Bing Ads',
            'Google Ads',
            'SEO',
            'Google Analytics',
            'Google Search Console',
            'Google Tag Manager',
            'Google Optimize',
            'Google My Business',
        ]
    },        
];

export const pricingTabs: PricingTabItem[] = [
    {
        name: "wordpress",
        values: [
            {
                "id": "starter",
                "shortLabel": "starter",
                "label": "starter_website",
            },
            {
                "id": "growth",
                "shortLabel": "growth",
                "label": "growth_website",
            },
            {
                "id": "power",
                "shortLabel": "power",
                "label": "power_website",
            },
        ],
        lines: [
            {
                id: "delivery_time",
                name: "delivery_time",
                values: [
                    "2__3_weeks",
                    "5__8_weeks",
                    "in_consultation",
                ]
            },
            {
                id: "brainstorm_session",
                name: "brainstorm_session",
                values: [
                    false,
                    true,
                    true,
                ]
            },
            {
                id: "strategic_plan",
                name: "strategic_plan",
                values: [
                    false,
                    false,
                    true,
                ]
            },
            {
                id: "wireframes_design",
                name: "wireframes_design",
                values: [
                    false,
                    true,
                    true,
                ]
            },
            {
                id: "custom_design",
                name: "custom_design",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "unique_templates",
                name: "unique_templates",
                values: [
                    "[UNTRANSLATE]4",
                    "[UNTRANSLATE]5-10",
                    "[UNTRANSLATE]11-20",
                ]
            },
            {
                id: "responsive_design",
                name: "responsive_design",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "multi_language",
                name: "multi_language_website",
                values: [
                    false,
                    true,
                    true,
                ]
            },
            {
                id: "seo_module",
                name: "seo_module",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "social_media_integration",
                name: "social_media_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "analytics_integration",
                name: "analytics_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "custom_connection",
                name: "custom_connection",
                values: [
                    "[UNTRANSLATE]0",
                    "[UNTRANSLATE]0",
                    "on_request",
                ]
            },
            {
                id: "e_commmerce_functionality",
                name: "ecommerce_functionality",
                values: [
                    false,
                    "on_request",
                    true,
                ]
            },
            {
                id: "site_speed",
                name: "site_speed",
                values: [
                    "fast",
                    "fast",
                    "fast",
                ]
            },
            {
                id: "webhosting",
                name: "webhosting",
                values: [
                    "[UNTRANSLATE]€10",
                    "[UNTRANSLATE]€15",
                    "on_request",
                ]
            },
            {
                id: "email_integration",
                name: "email_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "security_functions",
                name: "security_functions",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "support",
                name: "support",
                values: [
                    "[UNTRANSLATE]€45",
                    "[UNTRANSLATE]€85",
                    "on_request",
                ]
            },
            {
                id: "trainings_material",
                name: "trainings_material",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "price_indication",
                name: "price_indication",
                values: [
                    "[UNTRANSLATE]€950 - €1.750",
                    "[UNTRANSLATE]€2.500 - €4.500",
                    "[UNTRANSLATE]€5.000 - €15.000",
                ]
            },
        ]
    },
    {
        name: "Custom",
        values: [
            {
                "id": "mkb",
                "shortLabel": "mkb",
                "label": "mkb",
            },
            {
                "id": "auto_pilot",
                "shortLabel": "auto_pilot",
                "label": "auto_pilot",
            },
            {
                "id": "custom",
                "shortLabel": "custom",
                "label": "custom",
            },
        ],
        lines: [
            {
                id: "delivery_time",
                name: "delivery_time",
                values: [
                    "5__8_weeks",
                    "in_consultation",
                    "in_consultation",
                ]
            },
            {
                id: "brainstorm_session",
                name: "brainstorm_session",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "strategic_plan",
                name: "strategic_plan",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "wireframes_design",
                name: "wireframes_design",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "custom_design",
                name: "custom_design",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "unique_templates",
                name: "unique_templates",
                values: [
                    "[UNTRANSLATE]5-10",
                    "[UNTRANSLATE]11-20",
                    "on_request",
                ]
            },
            {
                id: "responsive_design",
                name: "responsive_design",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "multi_language",
                name: "multi_language_website",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "seo_module",
                name: "seo_module",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "social_media_integration",
                name: "social_media_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "analytics_integration",
                name: "analytics_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "custom_connection",
                name: "custom_connection",
                values: [
                    "[UNTRANSLATE]2",
                    "[UNTRANSLATE]3+",
                    "on_request",
                ]
            },
            {
                id: "e_commmerce_functionality",
                name: "ecommerce_functionality",
                values: [
                    "on_request",
                    "on_request",
                    "on_request",
                ]
            },
            {
                id: "site_speed",
                name: "site_speed",
                values: [
                    "super_fast",
                    "super_fast",
                    "super_fast",
                ]
            },
            {
                id: "webhosting",
                name: "webhosting",
                values: [
                    "[UNTRANSLATE]€15",
                    "on_request",
                    "on_request",
                ]
            },
            {
                id: "email_integration",
                name: "email_integration",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "security_functions",
                name: "security_functions",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "support",
                name: "support",
                values: [
                    "[UNTRANSLATE]€85",
                    "on_request",
                    "on_request",
                ]
            },
            {
                id: "trainings_material",
                name: "trainings_material",
                values: [
                    true,
                    true,
                    true,
                ]
            },
            {
                id: "price_indication",
                name: "price_indication",
                values: [
                    "[UNTRANSLATE]€4.000 - €7.500",
                    "from_8000",
                    "on_request",
                ]
            },
        ]
    }
];

export const websitePaths: WebsitePaths[] = [
    {
        id: 'wordpress',
        name: 'wordpress_website',
        link: {
            en: '/wordpress-website-creation',
            nl: '/wordpress-website-laten-maken'
        },
    },
    {
        id: 'customized',
        name: 'customized_website',
        link: {
            en: '/custom-website-creation',
            nl: '/maatwerk-website-laten-maken'
        },
    },
    {
        id: 'customized_api_crm',
        name: 'customized_api_crm',
        link: {
            en: '/solutions/crm-integrations',
            nl: '/oplossingen/crm-integraties'
        },
    },
];

const getWebsiteConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    const websitePrices: WebsitePrices[] = [
        {
            id: "wordpress",
            title: "services.cards.0.title",
            text: "services.cards.0.content",
        },
        {
            id: "customized",
            title: "services.cards.1.title",
            text: "services.cards.1.content",
        },
        {
            id: "customized_api_crm",
            title: "services.cards.2.title",
            text: "services.cards.2.content",
        },
    ];

    for (const coreValue of websitePrices) {
        const path = websitePaths.find(path => path.id === coreValue.id);

        if (path) {
            coreValue.link = path.link;
        }
    }

    const questions = [
        {
            title: "how_much_does_it_cost_to_maintain_a_website_annually",
            content: "maintenance_costs_can_range_from_a_few_hundred_to_several_thousand_euros_per_year_depending_on_the_size_and_complexity_of_your_website_regular_maintenance_is_crucial_to_keeping_your_site_secure_and_up_to_date_and_includes_updates_to_the_cms_themes_plugins_and_troubleshooting_any_issues_10000_or_more_depending_on_the_complexity_and_technologies_used_10000_or_more_depending_on_the_complexity_and_technologies_used"
        },
        {
            title: "hidden_costs_when_creating_a_website",
            content: "creating_a_website_often_comes_with_hidden_costs_that_can_surprise_beginners_besides_obvious_expenses_like_domain_registration_and_hosting_the_costs_for_design_customizations_and_premium_plugins_can_quickly_add_up_additionally_security_measures_such_as_ssl_certificates_should_not_be_underestimated_along_with_recurring_costs_for_maintenance_and_site_updates"
        },
        {
            title: "what_is_the_average_price_of_building_a_website",
            content: "the_average_cost_of_building_a_website_depends_on_several_factors_including_the_type_and_complexity_of_the_site_simple_websites_might_cost_as_little_as_a_few_hundred_dollars_while_more_complex_sites_can_run_into_thousands_features_like_custom_designs_or_advanced_functionalities_increase_the_price_significantly_maintenance_and_updates_also_add_to_the_overall_cost"
        }
    ];

    return {
        websitePrices,
        questions
    };
}


export default getWebsiteConstants;