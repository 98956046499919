import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react';

export function start() {
    if (process.env.NEXT_PHASE !== "phase-production-build") {
        const options = {
            apiKey: process.env.NEXT_APP_BUGSNAG_API_KEY ?? '',
            appVersion: process.env.NEXT_APP_BUGSNAG_APP_VERSION ?? '',
            releaseStage: process.env.NEXT_APP_BUGSNAG_RELEASE_STAGE ?? 'development',
            plugins: [new BugsnagPluginReact()],
            enabledReleaseStages: ['production', 'staging'],
        }

        const performanceOptions = {
            ...options,
            plugins: [],
        }

        Bugsnag.start(options)
        BugsnagPerformance.start(performanceOptions);

        return Bugsnag;
    } else {
        return null;
    }
}

