'use client';

import React, { useEffect, useRef, useState } from 'react';
import CalendlyButton from "@/components/shared/CalendlyButton";
import { useSearchParams } from 'next/navigation';

const CalendlyButtonWrapper = () => {
    const searchParams = useSearchParams();

    const [triggerCalendly, setTriggerCalendly] = useState<boolean>(false);
    useEffect(() => {
        const newParams = new URLSearchParams(searchParams.toString());
        if (newParams.get('calendly') === 'true') {
            setTriggerCalendly(true);
        }
    }, [searchParams]);
    return (
        <>
            {triggerCalendly && (
                <div className="hidden">
                    <CalendlyButton />
                </div>
            )}
        </>
    );
};

export default CalendlyButtonWrapper;