import { Locales, route } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";
import { ConceptPage, ConceptPath } from "@/lib/types/shared/page.types";

export const conceptPaths: ConceptPath[] = [
    {
        id: 'ajax',
        name: 'ajax',
        link: {
            en: '/concept/what-is-ajax',
            nl: '/vaktaal/wat-is-ajax'
        },
    },
    {
        id: 'amp',
        name: 'amp',
        link: {
            en: '/concept/what-is-amp',
            nl: '/vaktaal/wat-is-amp'
        }
    },
    {
        id: "anchor-text",
        name: "anchor_text",
        link: {
            en: "/concept/what-is-anchor-text",
            nl: "/vaktaal/wat-is-anchor-tekst"
        }
    },
    {
        id: "backlink-profile",
        name: "backlink_profile",
        link: {
            en: "/concept/what-is-backlink-profile",
            nl: "/vaktaal/wat-is-backlink-profiel"
        }
    },
    {
        id: "a-broken-link",
        name: "broken_link",
        link: {
            en: "/concept/what-is-a-broken-link",
            nl: "/vaktaal/wat-is-een-gebroken-link"
        }
    },
    {
        id: "a-canonical-tag",
        name: "canonical_tag",
        link: {
            en: "/concept/what-is-a-canonical-tag",
            nl: "/vaktaal/wat-is-een-canonical-tag"
        }
    },
    {
        id: "clickbait",
        name: "clickbait",
        link: {
            en: "/concept/what-is-clickbait",
            nl: "/vaktaal/wat-is-clickbait"
        }
    },
    {
        id: "a-cms",
        name: "content_management_system_cms",
        link: {
            en: "/concept/what-is-a-content-management-system-cms",
            nl: "/vaktaal/wat-is-een-content-management-systeem-cms"
        }
    },
    {
        id: "a-conversion-funnel",
        name: "conversion_funnel",
        link: {
            en: "/concept/what-is-a-conversion-funnel",
            nl: "/vaktaal/wat-is-een-conversie-trechter"
        }
    },
    {
        id: "dns",
        name: "domain_name_system_dns",
        link: {
            en: "/concept/what-is-dns",
            nl: "/vaktaal/wat-is-dns"
        }
    },
    {
        id: "e-commerce-platform",
        name: "e_commerce_platform",
        link: {
            en: "/concept/what-is-e-commerce-platform",
            nl: "/vaktaal/wat-is-e-commerce-platform"
        }
    },
    {
        id: 'ftp',
        name: 'ftp',
        link: {
            en: '/concept/what-is-ftp',
            nl: '/vaktaal/wat-is-ftp'
        }
    },
    {
        id: "google-search-console",
        name: "google_search_console",
        link: {
            en: "/concept/what-is-google-search-console",
            nl: "/vaktaal/wat-is-google-search-console"
        }
    },
    {
        id: "a-h1-tag",
        name: "h1_tag",
        link: {
            en: "/concept/what-is-a-h1-tag",
            nl: "/vaktaal/wat-is-een-h1-tag"
        }
    },
    {
        id: "a-hreflang-tag",
        name: "hreflang_tag",
        link: {
            en: "/concept/what-is-a-hreflang-tag",
            nl: "/vaktaal/wat-is-een-hreflang-tag"
        }
    },
    {
        id: "a-inbound-link",
        name: "inbound_link",
        link: {
            en: "/concept/what-is-a-inbound-link",
            nl: "/vaktaal/wat-is-een-inbound-link"
        }
    },
    {
        id: "a-internal-link",
        name: "internal_link",
        link: {
            en: "/concept/what-is-a-internal-link",
            nl: "/vaktaal/wat-is-een-interne-link"
        }
    },
    {
        id: "keyword-stuffing",
        name: "keyword_stuffing",
        link: {
            en: "/concept/what-is-keyword-stuffing",
            nl: "/vaktaal/wat-is-keyword-stuffing"
        }
    },
    {
        id: "landing-page-optimization",
        name: "landing_page_optimization",
        link: {
            en: "/concept/what-is-landing-page-optimization",
            nl: "/vaktaal/wat-is-landingspagina-optimalisatie"
        }
    },
    {
        id: "a-meta-tag",
        name: "meta_tags",
        link: {
            en: "/concept/what-is-a-meta-tag",
            nl: "/vaktaal/wat-is-een-meta-tag"
        }
    },
    {
        id: "mobile-first-indexing",
        name: "mobile_first_indexing",
        link: {
            en: "/concept/what-is-mobile-first-indexing",
            nl: "/vaktaal/wat-is-mobile-first-indexing"
        }
    },
    {
        id: "navigation-structure",
        name: "navigation_structure",
        link: {
            en: "/concept/what-is-navigation-structure",
            nl: "/vaktaal/wat-is-navigatiestructuur"
        }
    },
    {
        id: "a-nofollow-link",
        name: "no_follow_link",
        link: {
            en: "/concept/what-is-a-nofollow-link",
            nl: "/vaktaal/wat-is-een-nofollow-link"
        }
    },
    {
        id: "open-graph-protocol",
        name: "open_graph_protocol",
        link: {
            en: "/concept/what-is-open-graph-protocol",
            nl: "/vaktaal/wat-is-open-graph-protocol"
        }
    },
    {
        id: "page-authority",
        name: "page_authority",
        link: {
            en: "/concept/what-is-page-authority",
            nl: "/vaktaal/wat-is-pagina-autoriteit"
        }
    },
    {
        id: "pay-per-click",
        name: "pay_per_click",
        link: {
            en: "/concept/what-is-pay-per-click",
            nl: "/vaktaal/wat-is-betalen-per-klik"
        }
    },
    {
        id: "a-query-string",
        name: "query_string",
        link: {
            en: "/concept/what-is-a-query-string",
            nl: "/vaktaal/wat-is-een-query-string"
        }
    },
    {
        id: "a-robots-meta-tag",
        name: "robots_meta_tag",
        link: {
            en: "/concept/what-is-a-robots-meta-tag",
            nl: "/vaktaal/wat-is-een-robots-meta-tag"
        }
    },
    {
        id: "serp",
        name: "serp",
        link: {
            en: "/concept/what-is-serp",
            nl: "/vaktaal/wat-is-serp"
        }
    },
    {
        id: "semantic-markup",
        name: "semantic_markup",
        link: {
            en: "/concept/what-is-semantic-markup",
            nl: "/vaktaal/wat-is-semantische-markering"
        }
    },
    {
        id: "a-server-response-code",
        name: "server_response_codes",
        link: {
            en: "/concept/what-is-a-server-response-code",
            nl: "/vaktaal/wat-is-een-server-response-code"
        }
    },
    {
        id: "site-speed",
        name: "site_speed",
        link: {
            en: "/concept/what-is-site-speed",
            nl: "/vaktaal/wat-is-website-snelheid"
        }
    },
    {
        id: "structured-data",
        name: "structured_data",
        link: {
            en: "/concept/what-is-structured-data",
            nl: "/vaktaal/wat-is-gestructureerde-data"
        }
    },
    {
        id: "a-title-tag",
        name: "title_tag",
        link: {
            en: "/concept/what-is-a-title-tag",
            nl: "/vaktaal/wat-is-een-titel-tag"
        }
    },
    {
        id: "url-canonicalization",
        name: "url_canonicalization",
        link: {
            en: "/concept/what-is-url-canonicalization",
            nl: "/vaktaal/wat-is-url-canonicalisatie"
        }
    },
    {
        id: "a-user-agent",
        name: "user_agent",
        link: {
            en: "/concept/what-is-a-user-agent",
            nl: "/vaktaal/wat-is-een-user-agent"
        }
    },
    {
        id: "user-generated-content",
        name: "user_generated_content",
        link: {
            en: "/concept/what-is-user-generated-content",
            nl: "/vaktaal/wat-is-gebruikers-genererende-inhoud"
        }
    },
    {
        id: "web-accessibility",
        name: "web_accessibility",
        link: {
            en: "/concept/what-is-web-accessibility",
            nl: "/vaktaal/wat-is-web-toegankelijkheid"
        }
    },
    {
        id: "web-analytics",
        name: "web_analytics",
        link: {
            en: "/concept/what-is-web-analytics",
            nl: "/vaktaal/wat-is-webanalyse"
        }
    },
    {
        id: "a-xml-sitemap",
        name: "xml_sitemap",
        link: {
            en: "/concept/what-is-a-xml-sitemap",
            nl: "/vaktaal/wat-is-een-xml-sitemap"
        }
    },
    {
        id: "a-404-page",
        name: "404_page",
        link: {
            en: "/concept/what-is-a-404-page",
            nl: "/vaktaal/wat-is-een-404-pagina"
        }
    },
    {
        id: "ab-testing",
        name: "ab_testing",
        link: {
            en: "/concept/what-is-ab-testing",
            nl: "/vaktaal/wat-is-ab-testen"
        }
    },
    {
        id: "alt-text",
        name: "alt_text",
        link: {
            en: "/concept/what-is-alt-text",
            nl: "/vaktaal/wat-is-alt-tekst"
        }
    },
    {
        id: "backend",
        name: "backend",
        link: {
            en: "/concept/what-is-backend",
            nl: "/vaktaal/wat-is-backend"
        }
    },
    {
        id: "cache",
        name: "cache",
        link: {
            en: "/concept/what-is-cache",
            nl: "/vaktaal/wat-is-cache"
        }
    },
    {
        id: "css",
        name: "css",
        link: {
            en: "/concept/what-is-css",
            nl: "/vaktaal/wat-is-css"
        }
    },
    {
        id: "call-to-action",
        name: "call_to_action",
        link: {
            en: "/concept/what-is-call-to-action",
            nl: "/vaktaal/wat-is-call-to-action"
        }
    },
    {
        id: "a-canonical-url",
        name: "canonical_url",
        link: {
            en: "/concept/what-is-a-canonical-url",
            nl: "/vaktaal/wat-is-een-canonical-url"
        }
    },
    {
        id: "domain-authority",
        name: "domain_authority",
        link: {
            en: "/concept/what-is-domain-authority",
            nl: "/vaktaal/wat-is-domain-authority"
        }
    },
    {
        id: "favicon",
        name: "favicon",
        link: {
            en: "/concept/what-is-a-favicon",
            nl: "/vaktaal/wat-is-een-favicon"
        }
    },
    {
        id: "a-featured-snippet",
        name: "featured_snippet",
        link: {
            en: "/concept/what-is-a-featured-snippet",
            nl: "/vaktaal/wat-is-een-uitgelicht-fragment"
        }
    },
    {
        id: "front-end",
        name: "front_end",
        link: {
            en: "/concept/what-is-front-end",
            nl: "/vaktaal/wat-is-front-end"
        }
    },
    {
        id: "google-analytics",
        name: "google_analytics",
        link: {
            en: "/concept/what-is-google-analytics",
            nl: "/vaktaal/wat-is-google-analytics"
        }
    },
    {
        id: "a-header-tag",
        name: "header_tag",
        link: {
            en: "/concept/what-is-a-header-tag",
            nl: "/vaktaal/wat-is-een-header-tag"
        }
    },
    {
        id: "hosting",
        name: "hosting",
        link: {
            en: "/concept/what-is-hosting",
            nl: "/vaktaal/wat-is-hosting"
        }
    },
    {
        id: "html",
        name: "html",
        link: {
            en: "/concept/what-is-html",
            nl: "/vaktaal/wat-is-html"
        }
    },
    {
        id: "indexing",
        name: "indexing",
        link: {
            en: "/concept/what-is-indexing",
            nl: "/vaktaal/wat-is-indexeren"
        }
    },
    {
        id: "javascript",
        name: "javascript",
        link: {
            en: "/concept/what-is-javascript",
            nl: "/vaktaal/wat-is-javascript"
        }
    },
    {
        id: "keyword-density",
        name: "keyword_density",
        link: {
            en: "/concept/what-is-keyword-density",
            nl: "/vaktaal/wat-is-keyword-dichtheid"
        }
    },
    {
        id: "keyword-research",
        name: "keyword_research",
        link: {
            en: "/concept/what-is-keyword-research",
            nl: "/vaktaal/wat-is-zoekwoordonderzoek"
        }
    },
    {
        id: "a-landing-page",
        name: "landing_page",
        link: {
            en: "/concept/what-is-a-landing-page",
            nl: "/vaktaal/wat-is-een-landingspagina"
        }
    },
    {
        id: "local-seo",
        name: "local_seo",
        link: {
            en: "/concept/what-is-local-seo",
            nl: "/vaktaal/wat-is-lokale-seo"
        }
    },
    {
        id: "a-meta-description",
        name: "meta_description",
        link: {
            en: "/concept/what-is-a-meta-description",
            nl: "/vaktaal/wat-is-een-meta-beschrijving"
        }
    },
    {
        id: "off-page-seo",
        name: "off_page_seo",
        link: {
            en: "/concept/what-is-off-page-seo",
            nl: "/vaktaal/wat-is-off-page-seo"
        }
    },
    {
        id: "on-page-seo",
        name: "on_page_seo",
        link: {
            en: "/concept/what-is-on-page-seo",
            nl: "/vaktaal/wat-is-on-page-seo"
        }
    },
    {
        id: "php",
        name: "php",
        link: {
            en: "/concept/what-is-php",
            nl: "/vaktaal/wat-is-php"
        }
    },
    {
        id: "ranking-factors",
        name: "ranking_factors",
        link: {
            en: "/concept/what-is-ranking-factors",
            nl: "/vaktaal/wat-is-rankingfactoren"
        }
    },
    {
        id: "react",
        name: "react",
        link: {
            en: "/concept/what-is-react",
            nl: "/vaktaal/wat-is-react"
        }
    },
    {
        id: "a-redirect",
        name: "redirect",
        link: {
            en: "/concept/what-is-a-redirect",
            nl: "/vaktaal/wat-is-een-redirect"
        }
    },
    {
        id: "responsive-design",
        name: "responsive_design",
        link: {
            en: "/concept/what-is-responsive-design",
            nl: "/vaktaal/wat-is-responsive-design"
        }
    },
    {
        id: "robots-txt",
        name: "robots_txt",
        link: {
            en: "/concept/what-is-robots-txt",
            nl: "/vaktaal/wat-is-robots-txt"
        }
    },
    {
        id: "seo",
        name: "seo",
        link: {
            en: "/concept/what-is-seo",
            nl: "/vaktaal/wat-is-seo"
        }
    },
    {
        id: "a-sitemap",
        name: "sitemap",
        link: {
            en: "/concept/what-is-a-sitemap",
            nl: "/vaktaal/wat-is-een-sitemap"
        }
    },
    {
        id: "a-ssl-certificate",
        name: "ssl_certificate",
        link: {
            en: "/concept/what-is-a-ssl-certificate",
            nl: "/vaktaal/wat-is-een-ssl-certificaat"
        }
    },
    {
        id: "user-interface",
        name: "user_interface",
        link: {
            en: "/concept/what-is-user-interface",
            nl: "/vaktaal/wat-is-user-interface"
        }
    },
    {
        id: "a-url",
        name: "url",
        link: {
            en: "/concept/what-is-a-url",
            nl: "/vaktaal/wat-is-een-url"
        }
    },
    {
        id: "user-experience",
        name: "user_experience",
        link: {
            en: "/concept/what-is-user-experience",
            nl: "/vaktaal/wat-is-gebruikerservaring"
        }
    },
    {
        id: "webhosting",
        name: "webhosting",
        link: {
            en: "/concept/what-is-webhosting",
            nl: "/vaktaal/wat-is-webhosting"
        }
    },
    {
        id: "website-content",
        name: "website_content",
        link: {
            en: "/concept/what-is-website-content",
            nl: "/vaktaal/wat-is-website-content"
        }
    },
    {
        id: "website-design",
        name: "website_design",
        link: {
            en: "/concept/what-is-website-design",
            nl: "/vaktaal/wat-is-website-ontwerp"
        }
    },
    {
        id: 'json',
        name: 'json',
        link: {
            en: '/concept/what-is-json',
            nl: '/vaktaal/wat-is-json'
        }
    },
    {
        id: 'wordpress',
        name: 'wordpress',
        link: {
            en: '/concept/what-is-wordpress',
            nl: '/vaktaal/wat-is-wordpress'
        }
    },
    {
        id: 'a-breadcrumb',
        name: 'breadcrumb',
        link: {
            en: '/concept/what-is-a-breadcrumb',
            nl: '/vaktaal/wat-is-een-breadcrumb'
        }
    },
    {
        id: 'captcha',
        name: 'captcha',
        link: {
            en: '/concept/what-is-captcha',
            nl: '/vaktaal/wat-is-captcha'
        }
    },
    {
        id: 'duplicate-content',
        name: 'duplicate_content',
        link: {
            en: '/concept/what-is-duplicate-content',
            nl: '/vaktaal/wat-is-duplicate-content'
        }
    },
    {
        id: 'a-extension',
        name: 'extension',
        link: {
            en: '/concept/what-is-a-extension',
            nl: '/vaktaal/wat-is-een-extensie'
        }
    },
    {
        id: 'a-footer',
        name: 'footer',
        link: {
            en: '/concept/what-is-a-footer',
            nl: '/vaktaal/wat-is-een-footer'
        }
    },
    {
        id: 'google-ads',
        name: 'google_ads',
        link: {
            en: '/concept/what-is-google-ads',
            nl: '/vaktaal/wat-is-google-ads'
        }
    },
    {
        id: 'htaccess',
        name: 'htaccess',
        link: {
            en: '/concept/what-is-htaccess',
            nl: '/vaktaal/wat-is-htaccess'
        }
    },
    {
        id: 'https',
        name: 'https',
        link: {
            en: '/concept/what-is-https',
            nl: '/vaktaal/wat-is-https'
        }
    },
    {
        id: 'imap',
        name: 'imap',
        link: {
            en: '/concept/what-is-imap',
            nl: '/vaktaal/wat-is-imap'
        }
    },
    {
        id: 'a-ip-address',
        name: 'ip_address',
        link: {
            en: '/concept/what-is-a-ip-address',
            nl: '/vaktaal/wat-is-een-ip-adres'
        }
    },
    {
        id: 'jquery',
        name: 'jquery',
        link: {
            en: '/concept/what-is-jquery',
            nl: '/vaktaal/wat-is-jquery'
        }
    },
    {
        id: 'lite-speed',
        name: 'lite_speed',
        link: {
            en: '/concept/what-is-lite-speed',
            nl: '/vaktaal/wat-is-lite-speed'
        }
    },
    {
        id: 'a-meta-keyword',
        name: 'meta_keyword',
        link: {
            en: '/concept/what-is-a-meta-keyword',
            nl: '/vaktaal/wat-is-een-meta-keyword'
        }
    },
    {
        id: 'pop-3',
        name: 'pop_3',
        link: {
            en: '/concept/what-is-pop-3',
            nl: '/vaktaal/wat-is-pop-3'
        }
    },
    {
        id: 'sea',
        name: 'sea',
        link: {
            en: '/concept/what-is-sea',
            nl: '/vaktaal/wat-is-sea'
        }
    },
    {
        id: 'smtp',
        name: 'smtp',
        link: {
            en: '/concept/what-is-smtp',
            nl: '/vaktaal/wat-is-smtp'
        }
    },
    {
        id: 'webcare',
        name: 'webcare',
        link: {
            en: '/concept/what-is-webcare',
            nl: '/vaktaal/wat-is-webcare'
        }
    },
    {
        id: 'a-500-error',
        name: '500_error',
        link: {
            en: '/concept/what-is-a-500-error',
            nl: '/vaktaal/wat-is-een-500-error'
        }
    },
    {
        id: 'cro',
        name: 'cro',
        link: {
            en: '/concept/what-is-cro',
            nl: '/vaktaal/wat-is-cro'
        }
    },
    {
        id: 'a-anchor-link',
        name: 'anchor_links',
        link: {
            en: '/concept/what-is-a-anchor-link',
            nl: '/vaktaal/wat-is-een-anchor-link'
        }
    },
    {
        id: 'a-bullit-point',
        name: 'bullitpoint',
        link: {
            en: '/concept/what-is-a-bullit-point',
            nl: '/vaktaal/wat-is-een-bullit-point'
        }
    },
    {
        id: 'chatgpt',
        name: 'chatgpt',
        link: {
            en: '/concept/what-is-chatgpt',
            nl: '/vaktaal/wat-is-chatgpt'
        }
    },
    {
        id: 'drm',
        name: 'drm',
        link: {
            en: '/concept/what-is-drm',
            nl: '/vaktaal/wat-is-drm'
        }
    },
    {
        id: 'dynamic-content',
        name: 'dynamic_content',
        link: {
            en: '/concept/what-is-dynamic-content',
            nl: '/vaktaal/wat-is-een-dynamische-content'
        }
    },
    {
        id: 'a-external-link',
        name: 'external_links',
        link: {
            en: '/concept/what-is-a-external-link',
            nl: '/vaktaal/wat-is-een-externe-link'
        }
    },
    {
        id: 'a-framework',
        name: 'framework',
        link: {
            en: '/concept/what-is-a-framework',
            nl: '/vaktaal/wat-is-een-framework'
        }
    },
    {
        id: 'ga4',
        name: 'ga4',
        link: {
            en: '/concept/what-is-ga4',
            nl: '/vaktaal/wat-is-ga4'
        }
    },
    {
        id: 'a-h2-tag',
        name: 'h2_tag',
        link: {
            en: '/concept/a-h2-tag',
            nl: '/vaktaal/a-h2-tag'
        }
    },
    {
        id: 'a-h3-tag',
        name: 'h3_tag',
        link: {
            en: '/concept/a-h3-tag',
            nl: '/vaktaal/a-h3-tag'
        }
    },
    {
        id: 'a-header',
        name: 'header',
        link: {
            en: '/concept/what-is-a-header',
            nl: '/vaktaal/wat-is-een-header'
        }
    },
    {
        id: 'corporate-identity',
        name: 'corporate_identity',
        link: {
            en: '/concept/what-is-corporate-identity',
            nl: '/vaktaal/wat-is-corporate-identity'
        }
    },
    {
        id: 'a-logo',
        name: 'logo',
        link: {
            en: '/concept/what-is-a-logo',
            nl: '/vaktaal/wat-is-een-logo'
        }
    },
    {
        id: 'lorem-ipsum',
        name: 'lorem_ipsum',
        link: {
            en: '/concept/what-is-lorem-ipsum',
            nl: '/vaktaal/wat-is-lorem-ipsum'
        }
    },
    {
        id: 'a-long-tail-keyword',
        name: 'long_tail_keyword',
        link: {
            en: '/concept/what-is-a-long-tail-keyword',
            nl: '/vaktaal/wat-is-een-long-tail-keyword'
        }
    },
    {
        id: 'ahrefs',
        name: 'ahrefs',
        link: {
            en: '/concept/what-is-ahrefs',
            nl: '/vaktaal/wat-is-ahrefs'
        }
    },
    {
        id: 'mouse-tracking',
        name: 'mouse_tracking',
        link: {
            en: '/concept/what-is-mouse-tracking',
            nl: '/vaktaal/wat-is-mouse-tracking'
        }
    },
    {
        id: 'a-placeholder',
        name: 'placeholder',
        link: {
            en: '/concept/what-is-a-placeholder',
            nl: '/vaktaal/wat-is-een-placeholder'
        }
    },
    {
        id: 'pagespeed',
        name: 'pagespeed',
        link: {
            en: '/concept/what-is-pagespeed',
            nl: '/vaktaal/wat-is-pagespeed'
        }
    },
    {
        id: 'redesigning',
        name: 'redesigning',
        link: {
            en: '/concept/what-is-redesigning',
            nl: '/vaktaal/wat-is-redesignen'
        }
    },
    {
        id: 'rss-feed',
        name: 'rss_feed',
        link: {
            en: '/concept/what-is-rss-feed',
            nl: '/vaktaal/wat-is-rss-feed'
        }
    },
    {
        id: 'a-server',
        name: 'server',
        link: {
            en: '/concept/what-is-a-server',
            nl: '/vaktaal/wat-is-een-server'
        }
    },
    {
        id: 'server-side-scripting',
        name: 'server_side_scripting',
        link: {
            en: '/concept/what-is-server-side-scripting',
            nl: '/vaktaal/wat-is-server-side-scripting'
        }
    },
    {
        id: 'spam',
        name: 'spam',
        link: {
            en: '/concept/what-is-spam',
            nl: '/vaktaal/wat-is-spam'
        }
    },
    {
        id: 'tracking-cookies',
        name: 'tracking_cookies',
        link: {
            en: '/concept/what-is-tracking-cookies',
            nl: '/vaktaal/wat-is-tracking-cookies'
        }
    },
    {
        id: 'targeting',
        name: 'targeting',
        link: {
            en: '/concept/what-is-targeting',
            nl: '/vaktaal/wat-is-targeting'
        }
    },
    {
        id: 'a-trigger',
        name: 'trigger',
        link: {
            en: '/concept/what-is-a-trigger',
            nl: '/vaktaal/wat-is-een-trigger'
        }
    },
    {
        id: 'a-url-parameter',
        name: 'url_parameter',
        link: {
            en: '/concept/what-is-a-url-parameter',
            nl: '/vaktaal/wat-is-een-url-parameter'
        }
    },
    {
        id: 'usp',
        name: 'usp',
        link: {
            en: '/concept/what-is-usp',
            nl: '/vaktaal/wat-is-usp'
        }
    },
    {
        id: 'a-search-result',
        name: 'search_result',
        link: {
            en: '/concept/what-is-a-search-result',
            nl: '/vaktaal/wat-is-een-search-result'
        }
    },
    {
        id: 'cornerstone-content',
        name: 'cornerstone_content',
        link: {
            en: '/concept/what-is-cornerstone-content',
            nl: '/vaktaal/wat-is-cornerstone-content'
        }
    },
    {
        id: 'a-back-up',
        name: "back_up",
        link: {
            en: "/concept/what-is-a-back-up",
            nl: "/vaktaal/wat-is-een-back-up"
        }
    },
    {
        id: 'microsoft-tenants',
        name: "microsoft_tenants",
        link: {
            en: "/concept/what-is-microsoft-tenants",
            nl: "/vaktaal/wat-is-microsoft-tenants"
        }
    },
    {
        id: 'a-api',
        name: "api",
        link: {
            en: "/concept/what-is-a-api",
            nl: "/vaktaal/wat-is-een-api"
        }
    }
];

const getConceptConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale, namespace: 'pages.concepts' });
    }
    
    const conceptPages: ConceptPage[] = [
        {
            id: 'ajax',
            title: t('ajax.meta.title'),
            description: t("ajax.meta.description"),
            keywords: `${t('ajax.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ajax.hero.title"),
                    buttons: [
                        {
							text: t("ajax.hero.buttontext"),
							link: route("/concept", locale),
							variant: "primary",
						},
                    ],
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ajax.about.title"),
                        text: t("ajax.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ajax.advantages.title"),
                        ul: [
                            {
                                text: t("ajax.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ajax.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ajax.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ajax.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ajax.cons.title"),
                        ul: [
                            {
                                text: t("ajax.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon",
                            },
                            {
                                text: t("ajax.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon",
                            },
                            {
                                text: t("ajax.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon",
                            },
                            {
                                text: t("ajax.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon",
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'amp',
            title: t('amp.meta.title'),
            description: t("amp.meta.description"),
            keywords: `${t('amp.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("amp.hero.title"),
                    buttons: [
                        {
                            text: t("amp.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("amp.about.title"),
                        text: t("amp.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("amp.advantages.title"),
                        ul: [
                            {
                                text: t("amp.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("amp.cons.title"),
                        ul: [
                            {
                                text: t("amp.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("amp.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'anchor-text',
            title: t('anchor_text.meta.title'),
            description: t("anchor_text.meta.description"),
            keywords: `${t('anchor_text.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("anchor_text.hero.title"),
                    buttons: [
                        {
                            text: t("anchor_text.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_text.about.title"),
                        text: t("anchor_text.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_text.advantages.title"),
                        ul: [
                            {
                                text: t("anchor_text.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_text.cons.title"),
                        ul: [
                            {
                                text: t("anchor_text.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_text.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'backlink-profile',
            title: t('backlink-profile.meta.title'),
            description: t("backlink-profile.meta.description"),
            keywords: `${t('backlink-profile.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("backlink-profile.hero.title"),
                    buttons: [
                        {
                            text: t("backlink-profile.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backlink-profile.about.title"),
                        text: t("backlink-profile.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backlink-profile.advantages.title"),
                        ul: [
                            {
                                text: t("backlink-profile.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backlink-profile.cons.title"),
                        ul: [
                            {
                                text: t("backlink-profile.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backlink-profile.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'a-broken-link',
            title: t('broken_link.meta.title'),
            description: t("broken_link.meta.description"),
            keywords: `${t('broken_link.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("broken_link.hero.title"),
                    buttons: [
                        {
                            text: t("broken_link.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("broken_link.about.title"),
                        text: t("broken_link.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("broken_link.advantages.title"),
                        ul: [
                            {
                                text: t("broken_link.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("broken_link.cons.title"),
                        ul: [
                            {
                                text: t("broken_link.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("broken_link.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'a-canonical-tag',
            title: t('canonical_tag.meta.title'),
            description: t("canonical_tag.meta.description"),
            keywords: `${t('canonical_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("canonical_tag.hero.title"),
                    buttons: [
                        {
                            text: t("canonical_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_tag.about.title"),
                        text: t("canonical_tag.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_tag.advantages.title"),
                        ul: [
                            {
                                text: t("canonical_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_tag.cons.title"),
                        ul: [
                            {
                                text: t("canonical_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_tag.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'clickbait',
            title: t('clickbait.meta.title'),
            description: t("clickbait.meta.description"),
            keywords: `${t('clickbait.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("clickbait.hero.title"),
                    buttons: [
                        {
                            text: t("clickbait.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("clickbait.about.title"),
                        text: t("clickbait.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("clickbait.advantages.title"),
                        ul: [
                            {
                                text: t("clickbait.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("clickbait.cons.title"),
                        ul: [
                            {
                                text: t("clickbait.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("clickbait.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'a-content-management-system-cms',
            title: t('cms.meta.title'),
            description: t("cms.meta.description"),
            keywords: `${t('cms.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("cms.hero.title"),
                    buttons: [
                        {
                            text: t("cms.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cms.about.title"),
                        text: t("cms.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cms.advantages.title"),
                        ul: [
                            {
                                text: t("cms.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cms.cons.title"),
                        ul: [
                            {
                                text: t("cms.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cms.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'a-conversion-funnel',
            title: t('conversion_funnel.meta.title'),
            description: t("conversion_funnel.meta.description"),
            keywords: `${t('conversion_funnel.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("conversion_funnel.hero.title"),
                    buttons: [
                        {
                            text: t("conversion_funnel.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("conversion_funnel.about.title"),
                        text: t("conversion_funnel.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("conversion_funnel.advantages.title"),
                        ul: [
                            {
                                text: t("conversion_funnel.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("conversion_funnel.cons.title"),
                        ul: [
                            {
                                text: t("conversion_funnel.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("conversion_funnel.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'dns',
            title: t('dns.meta.title'),
            description: t("dns.meta.description"),
            keywords: `${t('dns.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("dns.hero.title"),
                    buttons: [
                        {
                            text: t("dns.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dns.about.title"),
                        text: t("dns.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dns.advantages.title"),
                        ul: [
                            {
                                text: t("dns.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dns.cons.title"),
                        ul: [
                            {
                                text: t("dns.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dns.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'e-commerce-platform',
            title: t('e_commerce.meta.title'),
            description: t("e_commerce.meta.description"),
            keywords: `${t('e_commerce.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("e_commerce.hero.title"),
                    buttons: [
                        {
                            text: t("e_commerce.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("e_commerce.about.title"),
                        text: t("e_commerce.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("e_commerce.advantages.title"),
                        ul: [
                            {
                                text: t("e_commerce.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("e_commerce.cons.title"),
                        ul: [
                            {
                                text: t("e_commerce.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("e_commerce.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'ftp',
            title: t('ftp.meta.title'),
            description: t("ftp.meta.description"),
            keywords: `${t('ftp.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ftp.hero.title"),
                    buttons: [
                        {
                            text: t("ftp.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ftp.about.title"),
                        text: t("ftp.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ftp.advantages.title"),
                        ul: [
                            {
                                text: t("ftp.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ftp.cons.title"),
                        ul: [
                            {
                                text: t("ftp.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ftp.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'google-search-console',
            title: t('google_search_console.meta.title'),
            description: t("google_search_console.meta.description"),
            keywords: `${t('google_search_console.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("google_search_console.hero.title"),
                    buttons: [
                        {
                            text: t("google_search_console.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_search_console.about.title"),
                        text: t("google_search_console.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_search_console.advantages.title"),
                        ul: [
                            {
                                text: t("google_search_console.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_search_console.cons.title"),
                        ul: [
                            {
                                text: t("google_search_console.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_search_console.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-h1-tag',
            title: t('h1_tags.meta.title'),
            description: t("h1_tags.meta.description"),
            keywords: `${t('h1_tags.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("h1_tags.hero.title"),
                    buttons: [
                        {
                            text: t("h1_tags.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h1_tags.about.title"),
                        text: t("h1_tags.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h1_tags.advantages.title"),
                        ul: [
                            {
                                text: t("h1_tags.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h1_tags.cons.title"),
                        ul: [
                            {
                                text: t("h1_tags.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h1_tags.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-hreflang-tag',
            title: t('hreflang_tag.meta.title'),
            description: t("hreflang_tag.meta.description"),
            keywords: `${t('hreflang_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("hreflang_tag.hero.title"),
                    buttons: [
                        {
                            text: t("hreflang_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hreflang_tag.about.title"),
                        text: t("hreflang_tag.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hreflang_tag.advantages.title"),
                        ul: [
                            {
                                text: t("hreflang_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hreflang_tag.cons.title"),
                        ul: [
                            {
                                text: t("hreflang_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hreflang_tag.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-inbound-link',
            title: t('inbound_link.meta.title'),
            description: t("inbound_link.meta.description"),
            keywords: `${t('inbound_link.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("inbound_link.hero.title"),
                    buttons: [
                        {
                            text: t("inbound_link.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("inbound_link.about.title"),
                        text: t("inbound_link.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("inbound_link.advantages.title"),
                        ul: [
                            {
                                text: t("inbound_link.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("inbound_link.cons.title"),
                        ul: [
                            {
                                text: t("inbound_link.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("inbound_link.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-internal-link',
            title: t('internal_link.meta.title'),
            description: t("internal_link.meta.description"),
            keywords: `${t('internal_link.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("internal_link.hero.title"),
                    buttons: [
                        {
                            text: t("internal_link.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("internal_link.about.title"),
                        text: t("internal_link.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("internal_link.advantages.title"),
                        ul: [
                            {
                                text: t("internal_link.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("internal_link.cons.title"),
                        ul: [
                            {
                                text: t("internal_link.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("internal_link.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'keyword-stuffing',
            title: t('keyword_stuffing.meta.title'),
            description: t("keyword_stuffing.meta.description"),
            keywords: `${t('keyword_stuffing.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("keyword_stuffing.hero.title"),
                    buttons: [
                        {
                            text: t("keyword_stuffing.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_stuffing.about.title"),
                        text: t("keyword_stuffing.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_stuffing.advantages.title"),
                        ul: [
                            {
                                text: t("keyword_stuffing.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_stuffing.cons.title"),
                        ul: [
                            {
                                text: t("keyword_stuffing.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_stuffing.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'landing-page-optimization',
            title: t('landing_page_optimization.meta.title'),
            description: t("landing_page_optimization.meta.description"),
            keywords: `${t('landing_page_optimization.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("landing_page_optimization.hero.title"),
                    buttons: [
                        {
                            text: t("landing_page_optimization.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page_optimization.about.title"),
                        text: t("landing_page_optimization.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page_optimization.advantages.title"),
                        ul: [
                            {
                                text: t("landing_page_optimization.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page_optimization.cons.title"),
                        ul: [
                            {
                                text: t("landing_page_optimization.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page_optimization.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-meta-tag',
            title: t('meta_tags.meta.title'),
            description: t("meta_tags.meta.description"),
            keywords: `${t('meta_tags.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("meta_tags.hero.title"),
                    buttons: [
                        {
                            text: t("meta_tags.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_tags.about.title"),
                        text: t("meta_tags.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_tags.advantages.title"),
                        ul: [
                            {
                                text: t("meta_tags.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_tags.cons.title"),
                        ul: [
                            {
                                text: t("meta_tags.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_tags.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'mobile-first-indexing',
            title: t('mobile_first_indexing.meta.title'),
            description: t("mobile_first_indexing.meta.description"),
            keywords: `${t('mobile_first_indexing.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("mobile_first_indexing.hero.title"),
                    buttons: [
                        {
                            text: t("mobile_first_indexing.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mobile_first_indexing.about.title"),
                        text: t("mobile_first_indexing.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mobile_first_indexing.advantages.title"),
                        ul: [
                            {
                                text: t("mobile_first_indexing.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mobile_first_indexing.cons.title"),
                        ul: [
                            {
                                text: t("mobile_first_indexing.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mobile_first_indexing.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'navigation-structure',
            title: t('navigation_structure.meta.title'),
            description: t("navigation_structure.meta.description"),
            keywords: `${t('navigation_structure.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("navigation_structure.hero.title"),
                    buttons: [
                        {
                            text: t("navigation_structure.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("navigation_structure.about.title"),
                        text: t("navigation_structure.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("navigation_structure.advantages.title"),
                        ul: [
                            {
                                text: t("navigation_structure.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("navigation_structure.cons.title"),
                        ul: [
                            {
                                text: t("navigation_structure.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("navigation_structure.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-nofollow-link',
            title: t('no_follow_link.meta.title'),
            description: t("no_follow_link.meta.description"),
            keywords: `${t('no_follow_link.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("no_follow_link.hero.title"),
                    buttons: [
                        {
                            text: t("no_follow_link.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("no_follow_link.about.title"),
                        text: t("no_follow_link.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("no_follow_link.advantages.title"),
                        ul: [
                            {
                                text: t("no_follow_link.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("no_follow_link.cons.title"),
                        ul: [
                            {
                                text: t("no_follow_link.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("no_follow_link.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'open-graph-protocol',
            title: t('open_graph_protocol.meta.title'),
            description: t("open_graph_protocol.meta.description"),
            keywords: `${t('open_graph_protocol.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("open_graph_protocol.hero.title"),
                    buttons: [
                        {
                            text: t("open_graph_protocol.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("open_graph_protocol.about.title"),
                        text: t("open_graph_protocol.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("open_graph_protocol.advantages.title"),
                        ul: [
                            {
                                text: t("open_graph_protocol.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("open_graph_protocol.cons.title"),
                        ul: [
                            {
                                text: t("open_graph_protocol.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("open_graph_protocol.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'page-authority',
            title: t('page_authority.meta.title'),
            description: t("page_authority.meta.description"),
            keywords: `${t('page_authority.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("page_authority.hero.title"),
                    buttons: [
                        {
                            text: t("page_authority.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("page_authority.about.title"),
                        text: t("page_authority.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("page_authority.advantages.title"),
                        ul: [
                            {
                                text: t("page_authority.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("page_authority.cons.title"),
                        ul: [
                            {
                                text: t("page_authority.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("page_authority.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'pay-per-click',
            title: t('pay_per_click.meta.title'),
            description: t("pay_per_click.meta.description"),
            keywords: `${t('pay_per_click.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("pay_per_click.hero.title"),
                    buttons: [
                        {
                            text: t("pay_per_click.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pay_per_click.about.title"),
                        text: t("pay_per_click.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pay_per_click.advantages.title"),
                        ul: [
                            {
                                text: t("pay_per_click.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pay_per_click.cons.title"),
                        ul: [
                            {
                                text: t("pay_per_click.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pay_per_click.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-query-string',
            title: t('query_string.meta.title'),
            description: t("query_string.meta.description"),
            keywords: `${t('query_string.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("query_string.hero.title"),
                    buttons: [
                        {
                            text: t("query_string.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("query_string.about.title"),
                        text: t("query_string.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("query_string.advantages.title"),
                        ul: [
                            {
                                text: t("query_string.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("query_string.cons.title"),
                        ul: [
                            {
                                text: t("query_string.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("query_string.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-robots-meta-tag',
            title: t('robots_meta_tag.meta.title'),
            description: t("robots_meta_tag.meta.description"),
            keywords: `${t('robots_meta_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("robots_meta_tag.hero.title"),
                    buttons: [
                        {
                            text: t("robots_meta_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_meta_tag.about.title"),
                        text: t("robots_meta_tag.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_meta_tag.advantages.title"),
                        ul: [
                            {
                                text: t("robots_meta_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_meta_tag.cons.title"),
                        ul: [
                            {
                                text: t("robots_meta_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_meta_tag.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'serp',
            title: t('serp.meta.title'),
            description: t("serp.meta.description"),
            keywords: `${t('serp.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("serp.hero.title"),
                    buttons: [
                        {
                            text: t("serp.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("serp.about.title"),
                        text: t("serp.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("serp.advantages.title"),
                        ul: [
                            {
                                text: t("serp.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("serp.cons.title"),
                        ul: [
                            {
                                text: t("serp.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("serp.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'semantic-markup',
            title: t('semantic_markup.meta.title'),
            description: t("semantic_markup.meta.description"),
            keywords: `${t('semantic_markup.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("semantic_markup.hero.title"),
                    buttons: [
                        {
                            text: t("semantic_markup.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("semantic_markup.about.title"),
                        text: t("semantic_markup.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("semantic_markup.advantages.title"),
                        ul: [
                            {
                                text: t("semantic_markup.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("semantic_markup.cons.title"),
                        ul: [
                            {
                                text: t("semantic_markup.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("semantic_markup.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-server-response-code',
            title: t('server_response_code.meta.title'),
            description: t("server_response_code.meta.description"),
            keywords: `${t('server_response_code.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("server_response_code.hero.title"),
                    buttons: [
                        {
                            text: t("server_response_code.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_response_code.about.title"),
                        text: t("server_response_code.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_response_code.advantages.title"),
                        ul: [
                            {
                                text: t("server_response_code.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_response_code.cons.title"),
                        ul: [
                            {
                                text: t("server_response_code.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_response_code.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'site-speed',
            title: t('site_speed.meta.title'),
            description: t("site_speed.meta.description"),
            keywords: `${t('site_speed.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("site_speed.hero.title"),
                    buttons: [
                        {
                            text: t("site_speed.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("site_speed.about.title"),
                        text: t("site_speed.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("site_speed.advantages.title"),
                        ul: [
                            {
                                text: t("site_speed.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("site_speed.cons.title"),
                        ul: [
                            {
                                text: t("site_speed.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("site_speed.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'structured-data',
            title: t('structured_data.meta.title'),
            description: t("structured_data.meta.description"),
            keywords: `${t('structured_data.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("structured_data.hero.title"),
                    buttons: [
                        {
                            text: t("structured_data.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("structured_data.about.title"),
                        text: t("structured_data.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("structured_data.advantages.title"),
                        ul: [
                            {
                                text: t("structured_data.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("structured_data.cons.title"),
                        ul: [
                            {
                                text: t("structured_data.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("structured_data.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-title-tag',
            title: t('title_tag.meta.title'),
            description: t("title_tag.meta.description"),
            keywords: `${t('title_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("title_tag.hero.title"),
                    buttons: [
                        {
                            text: t("title_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("title_tag.about.title"),
                        text: t("title_tag.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("title_tag.advantages.title"),
                        ul: [
                            {
                                text: t("title_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("title_tag.cons.title"),
                        ul: [
                            {
                                text: t("title_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("title_tag.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'url-canonicalization',
            title: t('url_canonicalization.meta.title'),
            description: t("url_canonicalization.meta.description"),
            keywords: `${t('url_canonicalization.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("url_canonicalization.hero.title"),
                    buttons: [
                        {
                            text: t("url_canonicalization.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_canonicalization.about.title"),
                        text: t("url_canonicalization.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_canonicalization.advantages.title"),
                        ul: [
                            {
                                text: t("url_canonicalization.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_canonicalization.cons.title"),
                        ul: [
                            {
                                text: t("url_canonicalization.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_canonicalization.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-user-agent',
            title: t('user_agent.meta.title'),
            description: t("user_agent.meta.description"),
            keywords: `${t('user_agent.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("user_agent.hero.title"),
                    buttons: [
                        {
                            text: t("user_agent.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_agent.about.title"),
                        text: t("user_agent.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_agent.advantages.title"),
                        ul: [
                            {
                                text: t("user_agent.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_agent.cons.title"),
                        ul: [
                            {
                                text: t("user_agent.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_agent.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'user-generated-content',
            title: t('user_generated_content.meta.title'),
            description: t("user_generated_content.meta.description"),
            keywords: `${t('user_generated_content.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("user_generated_content.hero.title"),
                    buttons: [
                        {
                            text: t("user_generated_content.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_generated_content.about.title"),
                        text: t("user_generated_content.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_generated_content.advantages.title"),
                        ul: [
                            {
                                text: t("user_generated_content.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_generated_content.cons.title"),
                        ul: [
                            {
                                text: t("user_generated_content.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_generated_content.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'web-accessibility',
            title: t('web_accessibility.meta.title'),
            description: t("web_accessibility.meta.description"),
            keywords: `${t('web_accessibility.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("web_accessibility.hero.title"),
                    buttons: [
                        {
                            text: t("web_accessibility.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_accessibility.about.title"),
                        text: t("web_accessibility.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_accessibility.advantages.title"),
                        ul: [
                            {
                                text: t("web_accessibility.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_accessibility.cons.title"),
                        ul: [
                            {
                                text: t("web_accessibility.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_accessibility.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'web-analytics',
            title: t('web_analytics.meta.title'),
            description: t("web_analytics.meta.description"),
            keywords: `${t('web_analytics.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("web_analytics.hero.title"),
                    buttons: [
                        {
                            text: t("web_analytics.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_analytics.about.title"),
                        text: t("web_analytics.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_analytics.advantages.title"),
                        ul: [
                            {
                                text: t("web_analytics.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("web_analytics.cons.title"),
                        ul: [
                            {
                                text: t("web_analytics.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("web_analytics.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-xml-sitemap',
            title: t('xml_sitemap.meta.title'),
            description: t("xml_sitemap.meta.description"),
            keywords: `${t('xml_sitemap.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("xml_sitemap.hero.title"),
                    buttons: [
                        {
                            text: t("xml_sitemap.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("xml_sitemap.about.title"),
                        text: t("xml_sitemap.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("xml_sitemap.advantages.title"),
                        ul: [
                            {
                                text: t("xml_sitemap.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("xml_sitemap.cons.title"),
                        ul: [
                            {
                                text: t("xml_sitemap.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("xml_sitemap.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-404-page',
            title: t('404_page.meta.title'),
            description: t("404_page.meta.description"),
            keywords: `${t('404_page.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("404_page.hero.title"),
                    buttons: [
                        {
                            text: t("404_page.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("404_page.about.title"),
                        text: t("404_page.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("404_page.advantages.title"),
                        ul: [
                            {
                                text: t("404_page.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("404_page.cons.title"),
                        ul: [
                            {
                                text: t("404_page.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("404_page.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'ab-testing',
            title: t('ab_testing.meta.title'),
            description: t("ab_testing.meta.description"),
            keywords: `${t('ab_testing.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ab_testing.hero.title"),
                    buttons: [
                        {
                            text: t("ab_testing.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ab_testing.about.title"),
                        text: t("ab_testing.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ab_testing.advantages.title"),
                        ul: [
                            {
                                text: t("ab_testing.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ab_testing.cons.title"),
                        ul: [
                            {
                                text: t("ab_testing.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ab_testing.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'alt-text',
            title: t('alt_text.meta.title'),
            description: t("alt_text.meta.description"),
            keywords: `${t('alt_text.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("alt_text.hero.title"),
                    buttons: [
                        {
                            text: t("alt_text.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("alt_text.about.title"),
                        text: t("alt_text.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("alt_text.advantages.title"),
                        ul: [
                            {
                                text: t("alt_text.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("alt_text.cons.title"),
                        ul: [
                            {
                                text: t("alt_text.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("alt_text.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'backend',
            title: t('backend.meta.title'),
            description: t("backend.meta.description"),
            keywords: `${t('backend.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("backend.hero.title"),
                    buttons: [
                        {
                            text: t("backend.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backend.about.title"),
                        text: t("backend.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backend.advantages.title"),
                        ul: [
                            {
                                text: t("backend.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("backend.cons.title"),
                        ul: [
                            {
                                text: t("backend.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("backend.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'cache',
            title: t('cache.meta.title'),
            description: t("cache.meta.description"),
            keywords: `${t('cache.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("cache.hero.title"),
                    buttons: [
                        {
                            text: t("cache.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cache.about.title"),
                        text: t("cache.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cache.advantages.title"),
                        ul: [
                            {
                                text: t("cache.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cache.cons.title"),
                        ul: [
                            {
                                text: t("cache.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cache.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'css',
            title: t('css.meta.title'),
            description: t("css.meta.description"),
            keywords: `${t('css.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("css.hero.title"),
                    buttons: [
                        {
                            text: t("css.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("css.about.title"),
                        text: t("css.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("css.advantages.title"),
                        ul: [
                            {
                                text: t("css.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("css.cons.title"),
                        ul: [
                            {
                                text: t("css.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("css.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'call-to-action',
            title: t('call_to_action.meta.title'),
            description: t("call_to_action.meta.description"),
            keywords: `${t('call_to_action.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("call_to_action.hero.title"),
                    buttons: [
                        {
                            text: t("call_to_action.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("call_to_action.about.title"),
                        text: t("call_to_action.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("call_to_action.advantages.title"),
                        ul: [
                            {
                                text: t("call_to_action.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("call_to_action.cons.title"),
                        ul: [
                            {
                                text: t("call_to_action.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("call_to_action.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-canonical-url',
            title: t('canonical_url.meta.title'),
            description: t("canonical_url.meta.description"),
            keywords: `${t('canonical_url.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("canonical_url.hero.title"),
                    buttons: [
                        {
                            text: t("canonical_url.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_url.about.title"),
                        text: t("canonical_url.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_url.advantages.title"),
                        ul: [
                            {
                                text: t("canonical_url.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("canonical_url.cons.title"),
                        ul: [
                            {
                                text: t("canonical_url.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("canonical_url.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'domain-authority',
            title: t('domain_authority.meta.title'),
            description: t("domain_authority.meta.description"),
            keywords: `${t('domain_authority.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("domain_authority.hero.title"),
                    buttons: [
                        {
                            text: t("domain_authority.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("domain_authority.about.title"),
                        text: t("domain_authority.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("domain_authority.advantages.title"),
                        ul: [
                            {
                                text: t("domain_authority.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("domain_authority.cons.title"),
                        ul: [
                            {
                                text: t("domain_authority.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("domain_authority.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-favicon',
            title: t('favicon.meta.title'),
            description: t("favicon.meta.description"),
            keywords: `${t('favicon.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("favicon.hero.title"),
                    buttons: [
                        {
                            text: t("favicon.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("favicon.about.title"),
                        text: t("favicon.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("favicon.advantages.title"),
                        ul: [
                            {
                                text: t("favicon.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("favicon.cons.title"),
                        ul: [
                            {
                                text: t("favicon.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("favicon.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-featured-snippet',
            title: t('featured_snippet.meta.title'),
            description: t("featured_snippet.meta.description"),
            keywords: `${t('featured_snippet.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("featured_snippet.hero.title"),
                    buttons: [
                        {
                            text: t("featured_snippet.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("featured_snippet.about.title"),
                        text: t("featured_snippet.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("featured_snippet.advantages.title"),
                        ul: [
                            {
                                text: t("featured_snippet.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("featured_snippet.cons.title"),
                        ul: [
                            {
                                text: t("featured_snippet.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("featured_snippet.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'front-end',
            title: t('front_end.meta.title'),
            description: t("front_end.meta.description"),
            keywords: `${t('front_end.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("front_end.hero.title"),
                    buttons: [
                        {
                            text: t("front_end.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("front_end.about.title"),
                        text: t("front_end.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("front_end.advantages.title"),
                        ul: [
                            {
                                text: t("front_end.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("front_end.cons.title"),
                        ul: [
                            {
                                text: t("front_end.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("front_end.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'google-analytics',
            title: t('google_analytics.meta.title'),
            description: t("google_analytics.meta.description"),
            keywords: `${t('google_analytics.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("google_analytics.hero.title"),
                    buttons: [
                        {
                            text: t("google_analytics.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_analytics.about.title"),
                        text: t("google_analytics.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_analytics.advantages.title"),
                        ul: [
                            {
                                text: t("google_analytics.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_analytics.cons.title"),
                        ul: [
                            {
                                text: t("google_analytics.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_analytics.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-header-tag',
            title: t('header_tag.meta.title'),
            description: t("header_tag.meta.description"),
            keywords: `${t('header_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("header_tag.hero.title"),
                    buttons: [
                        {
                            text: t("header_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header_tag.about.title"),
                        text: t("header_tag.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header_tag.advantages.title"),
                        ul: [
                            {
                                text: t("header_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header_tag.cons.title"),
                        ul: [
                            {
                                text: t("header_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header_tag.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'hosting',
            title: t('hosting.meta.title'),
            description: t("hosting.meta.description"),
            keywords: `${t('hosting.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("hosting.hero.title"),
                    buttons: [
                        {
                            text: t("hosting.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hosting.about.title"),
                        text: t("hosting.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hosting.advantages.title"),
                        ul: [
                            {
                                text: t("hosting.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("hosting.cons.title"),
                        ul: [
                            {
                                text: t("hosting.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("hosting.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'html',
            title: t('html.meta.title'),
            description: t("html.meta.description"),
            keywords: `${t('html.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("html.hero.title"),
                    buttons: [
                        {
                            text: t("html.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("html.about.title"),
                        text: t("html.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("html.advantages.title"),
                        ul: [
                            {
                                text: t("html.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("html.cons.title"),
                        ul: [
                            {
                                text: t("html.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("html.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'indexing',
            title: t('indexing.meta.title'),
            description: t("indexing.meta.description"),
            keywords: `${t('indexing.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("indexing.hero.title"),
                    buttons: [
                        {
                            text: t("indexing.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("indexing.about.title"),
                        text: t("indexing.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("indexing.advantages.title"),
                        ul: [
                            {
                                text: t("indexing.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("indexing.cons.title"),
                        ul: [
                            {
                                text: t("indexing.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("indexing.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'javascript',
            title: t('javascript.meta.title'),
            description: t("javascript.meta.description"),
            keywords: `${t('javascript.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("javascript.hero.title"),
                    buttons: [
                        {
                            text: t("javascript.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("javascript.about.title"),
                        text: t("javascript.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("javascript.advantages.title"),
                        ul: [
                            {
                                text: t("javascript.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("javascript.cons.title"),
                        ul: [
                            {
                                text: t("javascript.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("javascript.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'keyword-density',
            title: t('keyword_density.meta.title'),
            description: t("keyword_density.meta.description"),
            keywords: `${t('keyword_density.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("keyword_density.hero.title"),
                    buttons: [
                        {
                            text: t("keyword_density.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_density.about.title"),
                        text: t("keyword_density.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_density.advantages.title"),
                        ul: [
                            {
                                text: t("keyword_density.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_density.cons.title"),
                        ul: [
                            {
                                text: t("keyword_density.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_density.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'keyword-research',
            title: t('keyword_research.meta.title'),
            description: t("keyword_research.meta.description"),
            keywords: `${t('keyword_research.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("keyword_research.hero.title"),
                    buttons: [
                        {
                            text: t("keyword_research.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_research.about.title"),
                        text: t("keyword_research.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_research.advantages.title"),
                        ul: [
                            {
                                text: t("keyword_research.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("keyword_research.cons.title"),
                        ul: [
                            {
                                text: t("keyword_research.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("keyword_research.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-landing-page',
            title: t('landing_page.meta.title'),
            description: t("landing_page.meta.description"),
            keywords: `${t('landing_page.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("landing_page.hero.title"),
                    buttons: [
                        {
                            text: t("landing_page.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page.about.title"),
                        text: t("landing_page.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page.advantages.title"),
                        ul: [
                            {
                                text: t("landing_page.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("landing_page.cons.title"),
                        ul: [
                            {
                                text: t("landing_page.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("landing_page.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'local-seo',
            title: t('local_seo.meta.title'),
            description: t("local_seo.meta.description"),
            keywords: `${t('local_seo.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("local_seo.hero.title"),
                    buttons: [
                        {
                            text: t("local_seo.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_seo.about.title"),
                        text: t("local_seo.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_seo.advantages.title"),
                        ul: [
                            {
                                text: t("local_seo.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("local_seo.cons.title"),
                        ul: [
                            {
                                text: t("local_seo.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("local_seo.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-meta-description',
            title: t('meta_description.meta.title'),
            description: t("meta_description.meta.description"),
            keywords: `${t('meta_description.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("meta_description.hero.title"),
                    buttons: [
                        {
                            text: t("meta_description.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_description.about.title"),
                        text: t("meta_description.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_description.advantages.title"),
                        ul: [
                            {
                                text: t("meta_description.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_description.cons.title"),
                        ul: [
                            {
                                text: t("meta_description.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_description.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'off-page-seo',
            title: t('off_page_seo.meta.title'),
            description: t("off_page_seo.meta.description"),
            keywords: `${t('off_page_seo.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("off_page_seo.hero.title"),
                    buttons: [
                        {
                            text: t("off_page_seo.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("off_page_seo.about.title"),
                        text: t("off_page_seo.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("off_page_seo.advantages.title"),
                        ul: [
                            {
                                text: t("off_page_seo.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("off_page_seo.cons.title"),
                        ul: [
                            {
                                text: t("off_page_seo.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("off_page_seo.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'on-page-seo',
            title: t('on_page_seo.meta.title'),
            description: t("on_page_seo.meta.description"),
            keywords: `${t('on_page_seo.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("on_page_seo.hero.title"),
                    buttons: [
                        {
                            text: t("on_page_seo.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("on_page_seo.about.title"),
                        text: t("on_page_seo.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("on_page_seo.advantages.title"),
                        ul: [
                            {
                                text: t("on_page_seo.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("on_page_seo.cons.title"),
                        ul: [
                            {
                                text: t("on_page_seo.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("on_page_seo.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'php',
            title: t('php.meta.title'),
            description: t("php.meta.description"),
            keywords: `${t('php.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("php.hero.title"),
                    buttons: [
                        {
                            text: t("php.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("php.about.title"),
                        text: t("php.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("php.advantages.title"),
                        ul: [
                            {
                                text: t("php.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("php.cons.title"),
                        ul: [
                            {
                                text: t("php.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("php.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'ranking-factors',
            title: t('ranking_factors.meta.title'),
            description: t("ranking_factors.meta.description"),
            keywords: `${t('ranking_factors.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ranking_factors.hero.title"),
                    buttons: [
                        {
                            text: t("ranking_factors.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ranking_factors.about.title"),
                        text: t("ranking_factors.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ranking_factors.advantages.title"),
                        ul: [
                            {
                                text: t("ranking_factors.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ranking_factors.cons.title"),
                        ul: [
                            {
                                text: t("ranking_factors.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ranking_factors.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'react',
            title: t('react.meta.title'),
            description: t("react.meta.description"),
            keywords: `${t('react.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("react.hero.title"),
                    buttons: [
                        {
                            text: t("react.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("react.about.title"),
                        text: t("react.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("react.advantages.title"),
                        ul: [
                            {
                                text: t("react.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("react.cons.title"),
                        ul: [
                            {
                                text: t("react.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("react.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-redirect',
            title: t('redirect.meta.title'),
            description: t("redirect.meta.description"),
            keywords: `${t('redirect.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("redirect.hero.title"),
                    buttons: [
                        {
                            text: t("redirect.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redirect.about.title"),
                        text: t("redirect.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redirect.advantages.title"),
                        ul: [
                            {
                                text: t("redirect.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redirect.cons.title"),
                        ul: [
                            {
                                text: t("redirect.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redirect.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'responsive-design',
            title: t('responsive_design.meta.title'),
            description: t("responsive_design.meta.description"),
            keywords: `${t('responsive_design.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("responsive_design.hero.title"),
                    buttons: [
                        {
                            text: t("responsive_design.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("responsive_design.about.title"),
                        text: t("responsive_design.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("responsive_design.advantages.title"),
                        ul: [
                            {
                                text: t("responsive_design.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("responsive_design.cons.title"),
                        ul: [
                            {
                                text: t("responsive_design.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("responsive_design.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'robots-txt',
            title: t('robots_txt.meta.title'),
            description: t("robots_txt.meta.description"),
            keywords: `${t('robots_txt.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("robots_txt.hero.title"),
                    buttons: [
                        {
                            text: t("robots_txt.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_txt.about.title"),
                        text: t("robots_txt.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_txt.advantages.title"),
                        ul: [
                            {
                                text: t("robots_txt.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("robots_txt.cons.title"),
                        ul: [
                            {
                                text: t("robots_txt.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("robots_txt.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'seo',
            title: t('seo.meta.title'),
            description: t("seo.meta.description"),
            keywords: `${t('seo.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("seo.hero.title"),
                    buttons: [
                        {
                            text: t("seo.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("seo.about.title"),
                        text: t("seo.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("seo.advantages.title"),
                        ul: [
                            {
                                text: t("seo.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("seo.cons.title"),
                        ul: [
                            {
                                text: t("seo.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("seo.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-sitemap',
            title: t('sitemap.meta.title'),
            description: t("sitemap.meta.description"),
            keywords: `${t('sitemap.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("sitemap.hero.title"),
                    buttons: [
                        {
                            text: t("sitemap.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sitemap.about.title"),
                        text: t("sitemap.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sitemap.advantages.title"),
                        ul: [
                            {
                                text: t("sitemap.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sitemap.cons.title"),
                        ul: [
                            {
                                text: t("sitemap.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sitemap.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-ssl-certificate',
            title: t('ssl_certificate.meta.title'),
            description: t("ssl_certificate.meta.description"),
            keywords: `${t('ssl_certificate.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ssl_certificate.hero.title"),
                    buttons: [
                        {
                            text: t("ssl_certificate.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ssl_certificate.about.title"),
                        text: t("ssl_certificate.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ssl_certificate.advantages.title"),
                        ul: [
                            {
                                text: t("ssl_certificate.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ssl_certificate.cons.title"),
                        ul: [
                            {
                                text: t("ssl_certificate.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ssl_certificate.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'user-interface',
            title: t('user_interface.meta.title'),
            description: t("user_interface.meta.description"),
            keywords: `${t('user_interface.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("user_interface.hero.title"),
                    buttons: [
                        {
                            text: t("user_interface.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_interface.about.title"),
                        text: t("user_interface.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_interface.advantages.title"),
                        ul: [
                            {
                                text: t("user_interface.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("user_interface.cons.title"),
                        ul: [
                            {
                                text: t("user_interface.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("user_interface.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-url',
            title: t('url.meta.title'),
            description: t("url.meta.description"),
            keywords: `${t('url.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("url.hero.title"),
                    buttons: [
                        {
                            text: t("url.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url.about.title"),
                        text: t("url.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url.advantages.title"),
                        ul: [
                            {
                                text: t("url.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url.cons.title"),
                        ul: [
                            {
                                text: t("url.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'user-experience',
            title: t('ux.meta.title'),
            description: t("ux.meta.description"),
            keywords: `${t('ux.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ux.hero.title"),
                    buttons: [
                        {
                            text: t("ux.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ux.about.title"),
                        text: t("ux.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ux.advantages.title"),
                        ul: [
                            {
                                text: t("ux.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ux.cons.title"),
                        ul: [
                            {
                                text: t("ux.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ux.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'webhosting',
            title: t('webhosting.meta.title'),
            description: t("webhosting.meta.description"),
            keywords: `${t('webhosting.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("webhosting.hero.title"),
                    buttons: [
                        {
                            text: t("webhosting.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webhosting.about.title"),
                        text: t("webhosting.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webhosting.advantages.title"),
                        ul: [
                            {
                                text: t("webhosting.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webhosting.cons.title"),
                        ul: [
                            {
                                text: t("webhosting.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webhosting.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'website-content',
            title: t('website_content.meta.title'),
            description: t("website_content.meta.description"),
            keywords: `${t('website_content.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("website_content.hero.title"),
                    buttons: [
                        {
                            text: t("website_content.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_content.about.title"),
                        text: t("website_content.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_content.advantages.title"),
                        ul: [
                            {
                                text: t("website_content.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_content.cons.title"),
                        ul: [
                            {
                                text: t("website_content.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_content.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'json',
            title: t('json.meta.title'),
            description: t("json.meta.description"),
            keywords: `${t('json.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("json.hero.title"),
                    buttons: [
                        {
                            text: t("json.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("json.about.title"),
                        text: t("json.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("json.advantages.title"),
                        ul: [
                            {
                                text: t("json.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("json.cons.title"),
                        ul: [
                            {
                                text: t("json.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("json.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'website-design',
            title: t('website_design.meta.title'),
            description: t("website_design.meta.description"),
            keywords: `${t('website_design.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("website_design.hero.title"),
                    buttons: [
                        {
                            text: t("website_design.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_design.about.title"),
                        text: t("website_design.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_design.advantages.title"),
                        ul: [
                            {
                                text: t("website_design.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("website_design.cons.title"),
                        ul: [
                            {
                                text: t("website_design.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("website_design.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'wordpress',
            title: t('wordpress.meta.title'),
            description: t("wordpress.meta.description"),
            keywords: `${t('wordpress.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("wordpress.hero.title"),
                    buttons: [
                        {
                            text: t("wordpress.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("wordpress.about.title"),
                        text: t("wordpress.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("wordpress.advantages.title"),
                        ul: [
                            {
                                text: t("wordpress.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("wordpress.cons.title"),
                        ul: [
                            {
                                text: t("wordpress.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("wordpress.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-breadcrumb',
            title: t('breadcrumb.meta.title'),
            description: t("breadcrumb.meta.description"),
            keywords: `${t('breadcrumb.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("breadcrumb.hero.title"),
                    buttons: [
                        {
                            text: t("breadcrumb.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("breadcrumb.about.title"),
                        text: t("breadcrumb.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("breadcrumb.advantages.title"),
                        ul: [
                            {
                                text: t("breadcrumb.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("breadcrumb.cons.title"),
                        ul: [
                            {
                                text: t("breadcrumb.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("breadcrumb.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'captcha',
            title: t('captcha.meta.title'),
            description: t("captcha.meta.description"),
            keywords: `${t('captcha.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("captcha.hero.title"),
                    buttons: [
                        {
                            text: t("captcha.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("captcha.about.title"),
                        text: t("captcha.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("captcha.advantages.title"),
                        ul: [
                            {
                                text: t("captcha.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("captcha.cons.title"),
                        ul: [
                            {
                                text: t("captcha.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("captcha.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'duplicate-content',
            title: t('duplicate_content.meta.title'),
            description: t("duplicate_content.meta.description"),
            keywords: `${t('duplicate_content.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("duplicate_content.hero.title"),
                    buttons: [
                        {
                            text: t("duplicate_content.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("duplicate_content.about.title"),
                        text: t("duplicate_content.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("duplicate_content.advantages.title"),
                        ul: [
                            {
                                text: t("duplicate_content.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("duplicate_content.cons.title"),
                        ul: [
                            {
                                text: t("duplicate_content.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("duplicate_content.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("duplicate_content.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("duplicate_content.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-extension',
            title: t('extension.meta.title'),
            description: t("extension.meta.description"),
            keywords: `${t('extension.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("extension.hero.title"),
                    buttons: [
                        {
                            text: t("extension.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("extension.about.title"),
                        text: t("extension.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("extension.advantages.title"),
                        ul: [
                            {
                                text: t("extension.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("extension.cons.title"),
                        ul: [
                            {
                                text: t("extension.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("extension.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-footer',
            title: t('footer.meta.title'),
            description: t("footer.meta.description"),
            keywords: `${t('footer.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("footer.hero.title"),
                    buttons: [
                        {
                            text: t("footer.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("footer.about.title"),
                        text: t("footer.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("footer.advantages.title"),
                        ul: [
                            {
                                text: t("footer.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("footer.cons.title"),
                        ul: [
                            {
                                text: t("footer.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("footer.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'google-ads',
            title: t('google_ads.meta.title'),
            description: t("google_ads.meta.description"),
            keywords: `${t('google_ads.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("google_ads.hero.title"),
                    buttons: [
                        {
                            text: t("google_ads.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_ads.about.title"),
                        text: t("google_ads.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_ads.advantages.title"),
                        ul: [
                            {
                                text: t("google_ads.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("google_ads.cons.title"),
                        ul: [
                            {
                                text: t("google_ads.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("google_ads.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'htaccess',
            title: t('htaccess.meta.title'),
            description: t("htaccess.meta.description"),
            keywords: `${t('htaccess.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("htaccess.hero.title"),
                    buttons: [
                        {
                            text: t("htaccess.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("htaccess.about.title"),
                        text: t("htaccess.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("htaccess.advantages.title"),
                        ul: [
                            {
                                text: t("htaccess.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("htaccess.cons.title"),
                        ul: [
                            {
                                text: t("htaccess.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("htaccess.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'https',
            title: t('https.meta.title'),
            description: t("https.meta.description"),
            keywords: `${t('https.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("https.hero.title"),
                    buttons: [
                        {
                            text: t("https.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("https.about.title"),
                        text: t("https.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("https.advantages.title"),
                        ul: [
                            {
                                text: t("https.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("https.cons.title"),
                        ul: [
                            {
                                text: t("https.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("https.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'imap',
            title: t('imap.meta.title'),
            description: t("imap.meta.description"),
            keywords: `${t('imap.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("imap.hero.title"),
                    buttons: [
                        {
                            text: t("imap.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("imap.about.title"),
                        text: t("imap.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("imap.advantages.title"),
                        ul: [
                            {
                                text: t("imap.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("imap.cons.title"),
                        ul: [
                            {
                                text: t("imap.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("imap.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-ip-address',
            title: t('ip_address.meta.title'),
            description: t("ip_address.meta.description"),
            keywords: `${t('ip_address.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ip_address.hero.title"),
                    buttons: [
                        {
                            text: t("ip_address.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ip_address.about.title"),
                        text: t("ip_address.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ip_address.advantages.title"),
                        ul: [
                            {
                                text: t("ip_address.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ip_address.cons.title"),
                        ul: [
                            {
                                text: t("ip_address.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ip_address.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'jquery',
            title: t('jquery.meta.title'),
            description: t("jquery.meta.description"),
            keywords: `${t('jquery.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("jquery.hero.title"),
                    buttons: [
                        {
                            text: t("jquery.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("jquery.about.title"),
                        text: t("jquery.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("jquery.advantages.title"),
                        ul: [
                            {
                                text: t("jquery.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("jquery.cons.title"),
                        ul: [
                            {
                                text: t("jquery.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("jquery.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'lite-speed',
            title: t('lite_speed.meta.title'),
            description: t("lite_speed.meta.description"),
            keywords: `${t('lite_speed.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("lite_speed.hero.title"),
                    buttons: [
                        {
                            text: t("lite_speed.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lite_speed.about.title"),
                        text: t("lite_speed.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lite_speed.advantages.title"),
                        ul: [
                            {
                                text: t("lite_speed.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.advantages.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lite_speed.cons.title"),
                        ul: [
                            {
                                text: t("lite_speed.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lite_speed.cons.text_4"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: 'a-meta-keyword',
            title: t('meta_keyword.meta.title'),
            description: t("meta_keyword.meta.description"),
            keywords: `${t('meta_keyword.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("meta_keyword.hero.title"),
                    buttons: [
                        {
                            text: t("meta_keyword.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_keyword.about.title"),
                        text: t("meta_keyword.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_keyword.advantages.title"),
                        ul: [
                            {
                                text: t("meta_keyword.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_keyword.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_keyword.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("meta_keyword.cons.title"),
                        ul: [
                            {
                                text: t("meta_keyword.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_keyword.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("meta_keyword.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'pop-3',
            title: t('pop3.meta.title'),
            description: t("pop3.meta.description"),
            keywords: `${t('pop3.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("pop3.hero.title"),
                    buttons: [
                        {
                            text: t("pop3.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pop3.about.title"),
                        text: t("pop3.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pop3.advantages.title"),
                        ul: [
                            {
                                text: t("pop3.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pop3.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pop3.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pop3.cons.title"),
                        ul: [
                            {
                                text: t("pop3.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pop3.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pop3.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'sea',
            title: t('sea.meta.title'),
            description: t("sea.meta.description"),
            keywords: `${t('sea.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("sea.hero.title"),
                    buttons: [
                        {
                            text: t("sea.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sea.about.title"),
                        text: t("sea.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sea.advantages.title"),
                        ul: [
                            {
                                text: t("sea.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sea.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sea.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("sea.cons.title"),
                        ul: [
                            {
                                text: t("sea.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sea.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("sea.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'smtp',
            title: t('smtp.meta.title'),
            description: t("smtp.meta.description"),
            keywords: `${t('smtp.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("smtp.hero.title"),
                    buttons: [
                        {
                            text: t("smtp.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("smtp.about.title"),
                        text: t("smtp.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("smtp.advantages.title"),
                        ul: [
                            {
                                text: t("smtp.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("smtp.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("smtp.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("smtp.cons.title"),
                        ul: [
                            {
                                text: t("smtp.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("smtp.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("smtp.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'webcare',
            title: t('webcare.meta.title'),
            description: t("webcare.meta.description"),
            keywords: `${t('webcare.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("webcare.hero.title"),
                    buttons: [
                        {
                            text: t("webcare.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webcare.about.title"),
                        text: t("webcare.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webcare.advantages.title"),
                        ul: [
                            {
                                text: t("webcare.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webcare.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webcare.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("webcare.cons.title"),
                        ul: [
                            {
                                text: t("webcare.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webcare.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("webcare.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: 'a-500-error',
            title: t('500_error.meta.title'),
            description: t("500_error.meta.description"),
            keywords: `${t('500_error.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("500_error.hero.title"),
                    buttons: [
                        {
                            text: t("500_error.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("500_error.about.title"),
                        text: t("500_error.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("500_error.advantages.title"),
                        ul: [
                            {
                                text: t("500_error.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("500_error.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("500_error.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("500_error.cons.title"),
                        ul: [
                            {
                                text: t("500_error.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("500_error.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("500_error.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
            ]
        },
        {
            id: "cro",
            title: t('cro.meta.title'),
            description: t("cro.meta.description"),
            keywords: `${t('cro.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("cro.hero.title"),
                    buttons: [
                        {
                            text: t("cro.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cro.about.title"),
                        text: t("cro.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cro.advantages.title"),
                        ul: [
                            {
                                text: t("cro.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cro.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cro.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("cro.cons.title"),
                        ul: [
                            {
                                text: t("cro.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cro.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("cro.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },        
        {
            id: 'a-anchor-link',
            title: t('anchor_links.meta.title'),
            description: t("anchor_links.meta.description"),
            keywords: `${t('anchor_links.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("anchor_links.hero.title"),
                    buttons: [
                        {
                            text: t("anchor_links.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary",
                        },
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_links.about.title"),
                        text: t("anchor_links.about.content"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_links.advantages.title"),
                        ul: [
                            {
                                text: t("anchor_links.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_links.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_links.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("anchor_links.cons.title"),
                        ul: [
                            {
                                text: t("anchor_links.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_links.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("anchor_links.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-bullit-point",
            title: t('bulletpoints.meta.title'),
            description: t("bulletpoints.meta.description"),
            keywords: `${t('bulletpoints.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("bulletpoints.hero.title"),
                    buttons: [
                        {
                            text: t("bulletpoints.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("bulletpoints.about.title"),
                        text: t("bulletpoints.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("bulletpoints.advantages.title"),
                        ul: [
                            {
                                text: t("bulletpoints.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("bulletpoints.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("bulletpoints.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("bulletpoints.cons.title"),
                        ul: [
                            {
                                text: t("bulletpoints.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("bulletpoints.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("bulletpoints.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "chatgpt",
            title: t('chatgpt.meta.title'),
            description: t("chatgpt.meta.description"),
            keywords: `${t('chatgpt.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("chatgpt.hero.title"),
                    buttons: [
                        {
                            text: t("chatgpt.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("chatgpt.about.title"),
                        text: t("chatgpt.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("chatgpt.advantages.title"),
                        ul: [
                            {
                                text: t("chatgpt.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("chatgpt.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("chatgpt.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("chatgpt.cons.title"),
                        ul: [
                            {
                                text: t("chatgpt.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("chatgpt.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("chatgpt.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "drm",
            title: t('drm.meta.title'),
            description: t("drm.meta.description"),
            keywords: `${t('drm.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("drm.hero.title"),
                    buttons: [
                        {
                            text: t("drm.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("drm.about.title"),
                        text: t("drm.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("drm.advantages.title"),
                        ul: [
                            {
                                text: t("drm.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("drm.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("drm.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("drm.cons.title"),
                        ul: [
                            {
                                text: t("drm.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("drm.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("drm.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "dynamic-content",
            title: t('dynamic_content.meta.title'),
            description: t("dynamic_content.meta.description"),
            keywords: `${t('dynamic_content.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("dynamic_content.hero.title"),
                    buttons: [
                        {
                            text: t("dynamic_content.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dynamic_content.about.title"),
                        text: t("dynamic_content.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dynamic_content.advantages.title"),
                        ul: [
                            {
                                text: t("dynamic_content.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dynamic_content.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dynamic_content.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("dynamic_content.cons.title"),
                        ul: [
                            {
                                text: t("dynamic_content.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dynamic_content.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("dynamic_content.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-external-link",
            title: t('external_links.meta.title'),
            description: t("external_links.meta.description"),
            keywords: `${t('external_links.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("external_links.hero.title"),
                    buttons: [
                        {
                            text: t("external_links.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("external_links.about.title"),
                        text: t("external_links.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("external_links.advantages.title"),
                        ul: [
                            {
                                text: t("external_links.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("external_links.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("external_links.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("external_links.cons.title"),
                        ul: [
                            {
                                text: t("external_links.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("external_links.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("external_links.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-framework",
            title: t('framework.meta.title'),
            description: t("framework.meta.description"),
            keywords: `${t('framework.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("framework.hero.title"),
                    buttons: [
                        {
                            text: t("framework.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("framework.about.title"),
                        text: t("framework.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("framework.advantages.title"),
                        ul: [
                            {
                                text: t("framework.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("framework.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("framework.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("framework.cons.title"),
                        ul: [
                            {
                                text: t("framework.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("framework.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("framework.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "ga4",
            title: t('ga4.meta.title'),
            description: t("ga4.meta.description"),
            keywords: `${t('ga4.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ga4.hero.title"),
                    buttons: [
                        {
                            text: t("ga4.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ga4.about.title"),
                        text: t("ga4.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ga4.advantages.title"),
                        ul: [
                            {
                                text: t("ga4.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ga4.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ga4.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ga4.cons.title"),
                        ul: [
                            {
                                text: t("ga4.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ga4.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ga4.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-h2-tag",
            title: t('h2_tag.meta.title'),
            description: t("h2_tag.meta.description"),
            keywords: `${t('h2_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("h2_tag.hero.title"),
                    buttons: [
                        {
                            text: t("h2_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h2_tag.about.title"),
                        text: t("h2_tag.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h2_tag.advantages.title"),
                        ul: [
                            {
                                text: t("h2_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h2_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h2_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h2_tag.cons.title"),
                        ul: [
                            {
                                text: t("h2_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h2_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h2_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-h3-tag",
            title: t('h3_tag.meta.title'),
            description: t("h3_tag.meta.description"),
            keywords: `${t('h3_tag.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("h3_tag.hero.title"),
                    buttons: [
                        {
                            text: t("h3_tag.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h3_tag.about.title"),
                        text: t("h3_tag.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h3_tag.advantages.title"),
                        ul: [
                            {
                                text: t("h3_tag.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h3_tag.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h3_tag.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("h3_tag.cons.title"),
                        ul: [
                            {
                                text: t("h3_tag.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h3_tag.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("h3_tag.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-header",
            title: t('header.meta.title'),
            description: t("header.meta.description"),
            keywords: `${t('header.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("header.hero.title"),
                    buttons: [
                        {
                            text: t("header.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header.about.title"),
                        text: t("header.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header.advantages.title"),
                        ul: [
                            {
                                text: t("header.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("header.cons.title"),
                        ul: [
                            {
                                text: t("header.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("header.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "corporate-identity",
            title: t('corporate_identity.meta.title'),
            description: t("corporate_identity.meta.description"),
            keywords: `${t('corporate_identity.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("corporate_identity.hero.title"),
                    buttons: [
                        {
                            text: t("corporate_identity.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corporate_identity.about.title"),
                        text: t("corporate_identity.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corporate_identity.advantages.title"),
                        ul: [
                            {
                                text: t("corporate_identity.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corporate_identity.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corporate_identity.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corporate_identity.cons.title"),
                        ul: [
                            {
                                text: t("corporate_identity.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corporate_identity.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corporate_identity.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-logo",
            title: t('logo.meta.title'),
            description: t("logo.meta.description"),
            keywords: `${t('logo.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("logo.hero.title"),
                    buttons: [
                        {
                            text: t("logo.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("logo.about.title"),
                        text: t("logo.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("logo.advantages.title"),
                        ul: [
                            {
                                text: t("logo.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("logo.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("logo.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("logo.cons.title"),
                        ul: [
                            {
                                text: t("logo.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("logo.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("logo.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "lorem-ipsum",
            title: t('lorem_ipsum.meta.title'),
            description: t("lorem_ipsum.meta.description"),
            keywords: `${t('lorem_ipsum.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("lorem_ipsum.hero.title"),
                    buttons: [
                        {
                            text: t("lorem_ipsum.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lorem_ipsum.about.title"),
                        text: t("lorem_ipsum.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lorem_ipsum.advantages.title"),
                        ul: [
                            {
                                text: t("lorem_ipsum.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lorem_ipsum.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lorem_ipsum.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("lorem_ipsum.cons.title"),
                        ul: [
                            {
                                text: t("lorem_ipsum.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lorem_ipsum.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("lorem_ipsum.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-long-tail-keyword",
            title: t('long_tail_keywords.meta.title'),
            description: t("long_tail_keywords.meta.description"),
            keywords: `${t('long_tail_keywords.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("long_tail_keywords.hero.title"),
                    buttons: [
                        {
                            text: t("long_tail_keywords.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("long_tail_keywords.about.title"),
                        text: t("long_tail_keywords.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("long_tail_keywords.advantages.title"),
                        ul: [
                            {
                                text: t("long_tail_keywords.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("long_tail_keywords.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("long_tail_keywords.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("long_tail_keywords.cons.title"),
                        ul: [
                            {
                                text: t("long_tail_keywords.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("long_tail_keywords.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("long_tail_keywords.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "ahrefs",
            title: t('ahrefs.meta.title'),
            description: t("ahrefs.meta.description"),
            keywords: `${t('ahrefs.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("ahrefs.hero.title"),
                    buttons: [
                        {
                            text: t("ahrefs.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ahrefs.about.title"),
                        text: t("ahrefs.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ahrefs.advantages.title"),
                        ul: [
                            {
                                text: t("ahrefs.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ahrefs.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ahrefs.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("ahrefs.cons.title"),
                        ul: [
                            {
                                text: t("ahrefs.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ahrefs.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("ahrefs.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "mouse-tracking",
            title: t('mouse_tracking.meta.title'),
            description: t("mouse_tracking.meta.description"),
            keywords: `${t('mouse_tracking.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("mouse_tracking.hero.title"),
                    buttons: [
                        {
                            text: t("mouse_tracking.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mouse_tracking.about.title"),
                        text: t("mouse_tracking.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mouse_tracking.advantages.title"),
                        ul: [
                            {
                                text: t("mouse_tracking.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mouse_tracking.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mouse_tracking.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("mouse_tracking.cons.title"),
                        ul: [
                            {
                                text: t("mouse_tracking.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mouse_tracking.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("mouse_tracking.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-placeholder",
            title: t('placeholder.meta.title'),
            description: t("placeholder.meta.description"),
            keywords: `${t('placeholder.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("placeholder.hero.title"),
                    buttons: [
                        {
                            text: t("placeholder.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("placeholder.about.title"),
                        text: t("placeholder.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("placeholder.advantages.title"),
                        ul: [
                            {
                                text: t("placeholder.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("placeholder.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("placeholder.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("placeholder.cons.title"),
                        ul: [
                            {
                                text: t("placeholder.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("placeholder.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("placeholder.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "pagespeed",
            title: t('pagespeed.meta.title'),
            description: t("pagespeed.meta.description"),
            keywords: `${t('pagespeed.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("pagespeed.hero.title"),
                    buttons: [
                        {
                            text: t("pagespeed.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pagespeed.about.title"),
                        text: t("pagespeed.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pagespeed.advantages.title"),
                        ul: [
                            {
                                text: t("pagespeed.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pagespeed.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pagespeed.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("pagespeed.cons.title"),
                        ul: [
                            {
                                text: t("pagespeed.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pagespeed.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("pagespeed.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "redesigning",
            title: t('redesign.meta.title'),
            description: t("redesign.meta.description"),
            keywords: `${t('redesign.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("redesign.hero.title"),
                    buttons: [
                        {
                            text: t("redesign.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redesign.about.title"),
                        text: t("redesign.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redesign.advantages.title"),
                        ul: [
                            {
                                text: t("redesign.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redesign.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redesign.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("redesign.cons.title"),
                        ul: [
                            {
                                text: t("redesign.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redesign.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("redesign.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "rss-feed",
            title: t('rss_feed.meta.title'),
            description: t("rss_feed.meta.description"),
            keywords: `${t('rss_feed.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("rss_feed.hero.title"),
                    buttons: [
                        {
                            text: t("rss_feed.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("rss_feed.about.title"),
                        text: t("rss_feed.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("rss_feed.advantages.title"),
                        ul: [
                            {
                                text: t("rss_feed.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("rss_feed.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("rss_feed.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("rss_feed.cons.title"),
                        ul: [
                            {
                                text: t("rss_feed.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("rss_feed.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("rss_feed.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-server",
            title: t('server.meta.title'),
            description: t("server.meta.description"),
            keywords: `${t('server.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("server.hero.title"),
                    buttons: [
                        {
                            text: t("server.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server.about.title"),
                        text: t("server.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server.advantages.title"),
                        ul: [
                            {
                                text: t("server.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server.cons.title"),
                        ul: [
                            {
                                text: t("server.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "server-side-scripting",
            title: t('server_side_scripting.meta.title'),
            description: t("server_side_scripting.meta.description"),
            keywords: `${t('server_side_scripting.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("server_side_scripting.hero.title"),
                    buttons: [
                        {
                            text: t("server_side_scripting.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_side_scripting.about.title"),
                        text: t("server_side_scripting.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_side_scripting.advantages.title"),
                        ul: [
                            {
                                text: t("server_side_scripting.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_side_scripting.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_side_scripting.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("server_side_scripting.cons.title"),
                        ul: [
                            {
                                text: t("server_side_scripting.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_side_scripting.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("server_side_scripting.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "spam",
            title: t('spam.meta.title'),
            description: t("spam.meta.description"),
            keywords: `${t('spam.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("spam.hero.title"),
                    buttons: [
                        {
                            text: t("spam.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("spam.about.title"),
                        text: t("spam.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("spam.advantages.title"),
                        ul: [
                            {
                                text: t("spam.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("spam.cons.title"),
                        ul: [
                            {
                                text: t("spam.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("spam.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("spam.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "tracking-cookies",
            title: t('tracking_cookies.meta.title'),
            description: t("tracking_cookies.meta.description"),
            keywords: `${t('tracking_cookies.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("tracking_cookies.hero.title"),
                    buttons: [
                        {
                            text: t("tracking_cookies.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("tracking_cookies.about.title"),
                        text: t("tracking_cookies.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("tracking_cookies.advantages.title"),
                        ul: [
                            {
                                text: t("tracking_cookies.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("tracking_cookies.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("tracking_cookies.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("tracking_cookies.cons.title"),
                        ul: [
                            {
                                text: t("tracking_cookies.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("tracking_cookies.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("tracking_cookies.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "targeting",
            title: t('targeting.meta.title'),
            description: t("targeting.meta.description"),
            keywords: `${t('targeting.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("targeting.hero.title"),
                    buttons: [
                        {
                            text: t("targeting.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("targeting.about.title"),
                        text: t("targeting.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("targeting.advantages.title"),
                        ul: [
                            {
                                text: t("targeting.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("targeting.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("targeting.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("targeting.cons.title"),
                        ul: [
                            {
                                text: t("targeting.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("targeting.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("targeting.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-trigger",
            title: t('trigger.meta.title'),
            description: t("trigger.meta.description"),
            keywords: `${t('trigger.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("trigger.hero.title"),
                    buttons: [
                        {
                            text: t("trigger.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("trigger.about.title"),
                        text: t("trigger.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("trigger.advantages.title"),
                        ul: [
                            {
                                text: t("trigger.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("trigger.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("trigger.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("trigger.cons.title"),
                        ul: [
                            {
                                text: t("trigger.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("trigger.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("trigger.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-url-parameter",
            title: t('url_parameters.meta.title'),
            description: t("url_parameters.meta.description"),
            keywords: `${t('url_parameters.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("url_parameters.hero.title"),
                    buttons: [
                        {
                            text: t("url_parameters.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_parameters.about.title"),
                        text: t("url_parameters.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_parameters.advantages.title"),
                        ul: [
                            {
                                text: t("url_parameters.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_parameters.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_parameters.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("url_parameters.cons.title"),
                        ul: [
                            {
                                text: t("url_parameters.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_parameters.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("url_parameters.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },        
        {
            id: "usp",
            title: t('usp.meta.title'),
            description: t("usp.meta.description"),
            keywords: `${t('usp.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("usp.hero.title"),
                    buttons: [
                        {
                            text: t("usp.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("usp.about.title"),
                        text: t("usp.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("usp.advantages.title"),
                        ul: [
                            {
                                text: t("usp.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("usp.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("usp.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("usp.cons.title"),
                        ul: [
                            {
                                text: t("usp.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("usp.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("usp.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-search-result",
            title: t('search_results.meta.title'),
            description: t("search_results.meta.description"),
            keywords: `${t('search_results.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("search_results.hero.title"),
                    buttons: [
                        {
                            text: t("search_results.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("search_results.about.title"),
                        text: t("search_results.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("search_results.advantages.title"),
                        ul: [
                            {
                                text: t("search_results.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("search_results.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("search_results.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("search_results.cons.title"),
                        ul: [
                            {
                                text: t("search_results.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("search_results.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("search_results.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "cornerstone-content",
            title: t('corner_stone.meta.title'),
            description: t("corner_stone.meta.description"),
            keywords: `${t('corner_stone.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("corner_stone.hero.title"),
                    buttons: [
                        {
                            text: t("corner_stone.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corner_stone.about.title"),
                        text: t("corner_stone.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corner_stone.advantages.title"),
                        ul: [
                            {
                                text: t("corner_stone.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corner_stone.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corner_stone.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("corner_stone.cons.title"),
                        ul: [
                            {
                                text: t("corner_stone.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corner_stone.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("corner_stone.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-back-up",
            title: t('back_ups.meta.title'),
            description: t("back_ups.meta.description"),
            keywords: `${t('back_ups.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("back_ups.hero.title"),
                    buttons: [
                        {
                            text: t("back_ups.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("back_ups.about.title"),
                        text: t("back_ups.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("back_ups.advantages.title"),
                        ul: [
                            {
                                text: t("back_ups.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("back_ups.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("back_ups.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("back_ups.cons.title"),
                        ul: [
                            {
                                text: t("back_ups.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("back_ups.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("back_ups.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "microsoft-tenants",
            title: t('microsoft_tenants.meta.title'),
            description: t("microsoft_tenants.meta.description"),
            keywords: `${t('microsoft_tenants.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("microsoft_tenants.hero.title"),
                    buttons: [
                        {
                            text: t("microsoft_tenants.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("microsoft_tenants.about.title"),
                        text: t("microsoft_tenants.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("microsoft_tenants.advantages.title"),
                        ul: [
                            {
                                text: t("microsoft_tenants.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("microsoft_tenants.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("microsoft_tenants.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("microsoft_tenants.cons.title"),
                        ul: [
                            {
                                text: t("microsoft_tenants.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("microsoft_tenants.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("microsoft_tenants.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        },
        {
            id: "a-api",
            title: t('api.meta.title'),
            description: t("api.meta.description"),
            keywords: `${t('api.meta.keywords')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("api.hero.title"),
                    buttons: [
                        {
                            text: t("api.hero.buttontext"),
                            link: route("/concept", locale),
                            variant: "primary"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("api.about.title"),
                        text: t("api.about.content")
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("api.advantages.title"),
                        ul: [
                            {
                                text: t("api.advantages.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("api.advantages.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("api.advantages.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("api.cons.title"),
                        ul: [
                            {
                                text: t("api.cons.text_1"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("api.cons.text_2"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                            {
                                text: t("api.cons.text_3"),
                                icon: "Icons/plus_inimhj",
                                alt: "list icon"
                            },
                        ]
                    }
                }
            ]
        }                                                        
    ];

    for (const page of conceptPages) {
        const path = conceptPaths.find(path => path.id === page.id);

        if (path) {
            page.link = path.link;
        }
    }

    return {
        conceptPages
    };
}

export default getConceptConstants;