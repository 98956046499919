import { FC, HTMLAttributes, useMemo } from 'react';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

interface GoogleMapsProps extends HTMLAttributes<HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement> {
    banner?: React.ReactNode,
    apiKey: string,
    lat: number,
    lng: number,
    mapId: string,
    markers: Array<{
        lat: number,
        lng: number,
        icon: string,
        width: number,
        height: number,
    }>,
    height?: string,
    className?: string,
}

const GoogleMaps: FC<GoogleMapsProps> = ({ banner = '', apiKey = '', lat, lng, mapId, markers = [], height, className = '' }: GoogleMapsProps) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey ?? "",
    });
    const center = useMemo(() => ({ lat: lat, lng: lng }), []);

    const mapOptions = {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapId: mapId ?? "",
    };

    return (
        <div className={`w-full ${height ? height : 'h-full'} aspect-16/6 relative overflow-hidden rounded-md ${className}`}>
            {!isLoaded ? (
                <p className='text-white text-xl'>Loading...</p>
            ) : (
                <>
                    <GoogleMap mapContainerClassName="w-full h-full relative overflow-hidden" center={center} zoom={14} options={mapOptions}>
                        {markers.length > 0 && markers.map((marker, index) => (
                            <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} icon={{ "url": marker.icon, scaledSize: new window.google.maps.Size(marker.width, marker.height) }} />
                        ))}
                    </GoogleMap>

                    {banner && banner}
                </>
            )}
        </div>
    );
};

export default GoogleMaps;