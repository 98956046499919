import React from 'react';
import { useState, useEffect, useRef, forwardRef } from 'react'

// export const useDebounce = (value: any, milliSeconds: number) => {
// 	const [debouncedValue, setDebouncedValue] = useState(value)
// 	useEffect(() => {
// 		const timer = setTimeout(() => setDebouncedValue(value), milliSeconds || 1000)

// 		return () => {
// 			clearTimeout(timer)
// 		};

// 	}, [value, milliSeconds])

// 	return debouncedValue
// };


export default function useDebounce<T>(
	value: T,
	milliSeconds: number
): T {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), milliSeconds || 1000)

		return () => {
			clearTimeout(timer)
		};

	}, [value, milliSeconds])

	return debouncedValue
}