"use client";

import React, { useRef } from "react";

import { homeCompanySliderCldImages } from "@/lib/constants/home";
import { CompanySliderImage } from "@/lib/types/shared/page.types";
import Image from "@/components/shared/Image";

const getCldImagesHTML = (images: CompanySliderImage[]) => {
    return (
		<div className="flex gap-20 items-center scroll-to-left-slow">
			{images.map((image, index) => (
				<div key={index} className={`max-w-1/4 aspect-video m-2 ${image.size === 'big' ? 'h-100px md:h-150px' : 'h-66.66px md:h-100px'}`}>
					<Image
						loading="eager"
						width={image.size === 'big' ? 267 : 178}
						height={image.size === 'big' ? 150 : 100}
						src={image.image}
						alt={image.alt}
						className="w-full h-full object-contain grayscale hover:grayscale-0 transition-all duration-300"
					/>
				</div>
			))}
		</div>
    )
};

type Props = {
	minimalTopSpacing?: boolean,
};

const CompanyBanner = ({minimalTopSpacing = false}: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	
	const pauseAnimation = () => {
		if (ref.current !== null && ref.current !== undefined) {
			ref.current.childNodes.forEach((child: any) => {
				child.style.animationPlayState = 'paused';
			});
		}
	};
	
	const playAnimation = () => {
		if (ref.current !== null && ref.current !== undefined) {
			ref.current.childNodes.forEach((child: any) => {
				child.style.animationPlayState = 'running';
			});
		}
	};

	return (
		<div ref={ref} className={`max-w-9/10 mx-auto ${minimalTopSpacing ? 'mt-1 mb-16' : 'my-16'} overflow-x-hidden flex gap-20 relative`} onMouseEnter={pauseAnimation} onMouseLeave={playAnimation}>
			<div className="absolute top-1/2 left-0 -translate-y-1/2 bg-primary-gray-950-to-transparent-right w-20 h-full z-10" />

			{getCldImagesHTML(homeCompanySliderCldImages)}
			{getCldImagesHTML(homeCompanySliderCldImages)}
			{getCldImagesHTML(homeCompanySliderCldImages)}

			<div className="absolute top-1/2 right-0 -translate-y-1/2 bg-primary-gray-950-to-transparent-left w-20 h-full z-10" />
		</div>
	);
};

export default CompanyBanner;