'use client';

import React from "react";
import { Container, Typography } from "@mui/material";
import { ButtonOne } from "@webbeukers/wb-library.package";
import { MoveFadeInAnimation } from "@/lib/hooks/use-animations";
import Breadcrumb from "@/components/shared/Breadcrumb";
import { DefaultPageProps } from "@/lib/types/shared/page.types";
import HeadingText from "@/components/shared/HeadingText";

type Props = {
	title?: React.ReactNode,
	alignTitle?: string,
	children?: React.ReactNode,
	subTitle?: string,
	text?: string,
	buttons?: Array<any>,
	buttonText?: string
	buttonLink?: string
	secondaryButtonText?: string
	secondaryButtonLink?: string
} & DefaultPageProps;

const Hero = ({ title, alignTitle = "start", subTitle, text, buttons, params }: Props) => {
	return (
		<Container maxWidth="lg" className={`m-auto`}>
			<Breadcrumb params={params} />

			{title !== undefined && title !== null && title !== '' &&
				<MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
					<HeadingText variant="h1" className={`text-${alignTitle}`}>
						{title}
					</HeadingText>
				</MoveFadeInAnimation>
			}
			{subTitle !== undefined && subTitle !== null && subTitle !== '' &&
				<MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
					<HeadingText variant="h2">
						{subTitle}
					</HeadingText>
				</MoveFadeInAnimation>
			}
			{text !== undefined && text !== null && text !== '' &&
				<MoveFadeInAnimation delay={400} direction="right" distance="xl" amount={0.5}>
					<Typography className="text-start py-4">
						{text}
					</Typography>
				</MoveFadeInAnimation>
			}
			{buttons !== undefined && buttons !== null &&
				<div className="flex flex-col md:flex-row items-start w-fit gap-2 mt-10">
					{buttons.map((button, index) => (
						<MoveFadeInAnimation key={index} delay={400 * (index + 1)} direction="right" distance="xl" amount={0.5}>
							<div key={index} className="w-fit m-auto">
								<ButtonOne variant={button.variant} text={button.text} link={button.link} target={button.target ? button.target : ''} hoverEffect={true} />
							</div>
						</MoveFadeInAnimation>
					))}
				</div>
			}
		</Container>
	);
}

export default Hero;