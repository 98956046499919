'use client';

import React, { useEffect } from "react";

type Props = {
    tag: 'script' | 'style';
    type: string;
    content: string;
};

const Head = ({ tag, type, content }: Props) => {
    useEffect(() => {
        const tagElem = document.createElement(tag);
        tagElem.type = type;

        tagElem.innerHTML = content;

        document.head.appendChild(tagElem);
    }, []);

    return (
        null
    )
};

export default Head;