import React from "react";

type Props = {
    children: React.ReactNode,
}

const ErrorMessage = ({ children } :Props ) => {
    return (
        <div className="text-red-400 mt-1">
            {children}
        </div>
    );
}
 
export default ErrorMessage;