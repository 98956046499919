const emailTemplateConfigQuote = (company:string, func:string, name:string, email: string, phone:string, pages:string, wishes:string, links:string, multiLanguage:boolean, domain:boolean, webhosting:boolean, packageName:string, packageType:string) => {
    return {
        subject: 'Offerte Formulier',
        html: `
            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
            <head>
            <meta name=x-apple-disable-message-reformatting>
            <meta http-equiv=X-UA-Compatible>
            <meta charset=utf-8>
            <meta name=viewport content=target-densitydpi=device-dpi>
            <meta content=true name=HandheldFriendly>
            <meta content=width=device-width name=viewport>
            <style type="text/css">
            table {
            border-collapse: separate;
            table-layout: fixed;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt
            }
            table td {
            border-collapse: collapse
            }
            .ExternalClass {
            width: 100%
            }
            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
            line-height: 100%
            }
            * {
            line-height: inherit;
            text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            -moz-text-size-adjust: 100%;
            -o-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
            }
            html {
            -webkit-text-size-adjust: none !important
            }
            img+div {
            display: none;
            display: none !important
            }
            img {
            Margin: 0;
            padding: 0;
            -ms-interpolation-mode: bicubic
            }
            h1, h2, h3, p, a {
            line-height: 1;
            overflow-wrap: normal;
            white-space: normal;
            word-break: break-word
            }
            a {
            text-decoration: none
            }
            h1, h2, h3, p {
            min-width: 100%!important;
            width: 100%!important;
            max-width: 100%!important;
            display: inline-block!important;
            border: 0;
            padding: 0;
            margin: 0
            }
            a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important
            }
            a[href^="mailto"],
            a[href^="tel"],
            a[href^="sms"] {
            color: inherit;
            text-decoration: none
            }
            @media (min-width: 481px) {
            .hd { display: none!important }
            }
            @media (max-width: 480px) {
            .hm { display: none!important }
            }
            [style*="Inter"] {font-family: 'Inter', BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif !important;} [style*="Inter Tight"] {font-family: 'Inter Tight', BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif !important;}
            @media only screen and (min-width: 481px) {.t9{padding-left:40px!important;padding-right:40px!important}.t11{padding-left:40px!important;padding-right:40px!important;width:540px!important}.t31{line-height:41px!important;font-size:39px!important;mso-text-raise:1px!important}.t64,.t83{width:50%!important}}
            </style>
            <!--[if !mso]><!-->
            <link href="https://fonts.googleapis.com/css2?family=Inter+Tight:wght@500;600;800&family=Inter:wght@400&display=swap" rel="stylesheet" type="text/css">
            <!--<![endif]-->
            <!--[if mso]>
            <style type="text/css">
            td.t11,td.t9{padding-left:40px !important;padding-right:40px !important}h1.t31{line-height:41px !important;font-size:39px !important;mso-text-raise:1px !important}div.t64,div.t83{width:50% !important}
            </style>
            <![endif]-->
            <!--[if mso]>
            <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            </head>
            <body class=t0 style="min-width:100%;Margin:0px;padding:0px;background-color:#ffffff;"><div class=t1 style="background-color:#ffffff;"><table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center><tr><td class=t177 style="font-size:0;line-height:0;mso-line-height-rule:exactly;" valign=top align=center>
            <!--[if mso]>
            <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false">
            <v:fill color=#ffffff />
            </v:background>
            <![endif]-->
            <table role=presentation width=100% cellpadding=0 cellspacing=0 border=0 align=center><tr><td>
            <table class=t10 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t11 style="background-color:#FFFFFF;overflow:hidden;width:560px;padding:50px 30px 50px 30px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t11 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:50px 30px 50px 30px;"><![endif]-->
            <table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            <table class=t155 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t156 style="background-color:#FFFFFF;overflow:hidden;width:540px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t156 style="background-color:#FFFFFF;overflow:hidden;width:620px;padding:40px 40px 40px 40px;border-radius:10px 10px 10px 10px;"><![endif]-->
            <table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            <table class=t159 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t160 style="width:55px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t160 style="width:55px;"><![endif]-->
            <div style="font-size:0px;"><img loading="lazy" class=t166 style="display:block;border:0;height:auto;width:100%;Margin:0;max-width:100%;" width=55 height=37.5 src=https://res.cloudinary.com/dxd5axwvg/image/upload/v1715866103/logo-small_cuwx5q.webp /></div></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t14 role=presentation cellpadding=0 cellspacing=0 align=left><tr>
            <!--[if !mso]><!--><td class=t15 style="width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t15 style="width:800px;"><![endif]-->
            <h1 class=t21 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:16px;font-weight:500;font-style:normal;font-size:14px;text-decoration:none;text-transform:uppercase;letter-spacing:6px;direction:ltr;color:#222222;text-align:center;mso-line-height-rule:exactly;mso-text-raise:1px;">Hoi Stephan</h1></td>
            </tr></table>
            </td></tr><tr><td><div class=t13 style="mso-line-height-rule:exactly;mso-line-height-alt:5px;line-height:5px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
            <table class=t24 role=presentation cellpadding=0 cellspacing=0 align=left><tr>
            <!--[if !mso]><!--><td class=t25 style="width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t25 style="width:800px;"><![endif]-->
            <h1 class=t31 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:38px;font-weight:800;font-style:normal;font-size:28px;text-decoration:none;text-transform:none;direction:ltr;color:#222222;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">Offerte aangevraagd</h1></td>
            </tr></table>
            </td></tr><tr><td><div class=t23 style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
            <table class=t34 role=presentation cellpadding=0 cellspacing=0 align=left><tr>
            <!--[if !mso]><!--><td class=t35 style="width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t35 style="width:800px;"><![endif]-->
            <p class=t41 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:28px;font-weight:500;font-style:normal;font-size:18px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:3px;">${name ?? ''} heeft op de website het offerteformulier ingediend. Hieronder zie je de ingevulde gegevens.</p></td>
            </tr></table>
            </td></tr><tr><td><div class=t33 style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
            <table class=t49 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t50 style="background-color:#F6F6F6;overflow:hidden;width:520px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t50 style="background-color:#F6F6F6;overflow:hidden;width:600px;padding:30px 40px 30px 40px;border-radius:12px 12px 12px 12px;"><![endif]-->
            <table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            <table class=t53 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t54 style="overflow:hidden;width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t54 style="overflow:hidden;width:800px;"><![endif]-->
            <div class=t60 style="display:inline-table;width:100%;text-align:left;vertical-align:middle;">
            <!--[if mso]>
            <table role=presentation cellpadding=0 cellspacing=0 align=left valign=middle><tr><td width=230 valign=middle><![endif]-->
            <div class=t64 style="display:inline-table;text-align:initial;vertical-align:inherit;width:100%;max-width:800px;">
            <table role=presentation width=100% cellpadding=0 cellspacing=0 class=t66><tr>
            <td class=t67 style="overflow:hidden;"><table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            <table class=t72 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t73 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t73 style="width:600px;"><![endif]-->
            <p class=t79 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Naam</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t111 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t112 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t112 style="width:600px;"><![endif]-->
            <p class=t118 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${name ?? ''}</p></td>
            </tr></table>
            <table class=t72 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t73 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t73 style="width:600px;"><![endif]-->
            <p class=t79 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Bedrijf</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t111 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t112 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t112 style="width:600px;"><![endif]-->
            <p class=t118 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${company ?? ''}</p></td>
            </tr></table>
            <table class=t72 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t73 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t73 style="width:600px;"><![endif]-->
            <p class=t79 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Functie</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t111 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t112 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t112 style="width:600px;"><![endif]-->
            <p class=t118 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${func ?? ''}</p></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            <table class=t72 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t73 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t73 style="width:600px;"><![endif]-->
            <p class=t79 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">E-mail</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t111 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t112 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t112 style="width:600px;"><![endif]-->
            <p class=t118 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${email ?? ''}</p></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </div>
            <!--[if mso]>
            </td><td width=230 valign=middle><![endif]-->
            <div class=t83 style="display:inline-table;text-align:initial;vertical-align:inherit;width:100%;max-width:800px;">
            <table role=presentation width=100% cellpadding=0 cellspacing=0 class=t85><tr>
            <td class=t86 style="overflow:hidden;"><table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Telefoonnummer</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${phone ?? ''}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Pagina's</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${pages ?? ''}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Specifieke wensen</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${wishes ?? ''}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Koppelingen</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${links ?? ''}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Meertalig</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${multiLanguage ? 'Ja' : 'Nee'}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Heeft al domeinnaam</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${domain ? 'Ja' : 'Nee'}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Heeft al web hosting</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${webhosting ? 'Ja' : 'Nee'}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Pakket</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${packageName ?? ''}</p></td>
            </tr></table>
            <table class=t91 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t92 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t92 style="width:600px;"><![endif]-->
            <p class=t98 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:600;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#333333;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">Soort</p></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t101 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t102 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t102 style="width:600px;"><![endif]-->
            <p class=t108 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter Tight';line-height:22px;font-weight:500;font-style:normal;font-size:16px;text-decoration:none;text-transform:none;direction:ltr;color:#787878;text-align:left;mso-line-height-rule:exactly;mso-text-raise:2px;">${packageType ?? ''}</p></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </div>
            <!--[if mso]>
            </td>
            </tr></table>
            <![endif]-->
            </div></td>
            </tr></table>
            </td></tr><tr><td>
            <table class=t126 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t127 style="overflow:hidden;width:800px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t127 style="overflow:hidden;width:800px;"><![endif]-->
            <table role=presentation width=100% cellpadding=0 cellspacing=0><tr><td>
            </tr></table>
            </td></tr></table></td>
            </td></tr><tr><td>
            <table class=t130 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t131 style="width:600px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t131 style="width:600px;"><![endif]-->
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </td></tr><tr><td><div class=t167 style="mso-line-height-rule:exactly;mso-line-height-alt:20px;line-height:20px;font-size:1px;display:block;">&nbsp;</div></td></tr><tr><td>
            <table class=t169 role=presentation cellpadding=0 cellspacing=0 align=center><tr>
            <!--[if !mso]><!--><td class=t170 style="width:500px;">
            <!--<![endif]-->
            <!--[if mso]><td class=t170 style="width:500px;"><![endif]-->
            <p class=t176 style="margin-bottom:0;Margin-bottom:0;font-family:BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif,'Inter';line-height:16px;font-weight:400;font-style:normal;font-size:11px;text-decoration:none;text-transform:none;direction:ltr;color:#777777;text-align:center;mso-line-height-rule:exactly;mso-text-raise:2px;">Copyright © ${new Date().getFullYear()} Webbeukers</p></td>
            </tr></table>
            </td></tr></table></td>
            </tr></table>
            </td></tr></table></td></tr></table></div></body>
            </html>
        `
    }

};

export default emailTemplateConfigQuote;