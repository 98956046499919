'use client'

const useGtagConversions = () => {

    const GTAG_contactConversion = () => {
        console.log('GTAG_contactConversion Loaded');
    }

    const GTAG_calendlyConversion = () => {
        console.log('GTAG_calendlyConversion Loaded');
    }

    type QuoteType = "starter" | "growth" | "groei" | "power" | "mkb" | "autoPilot" | "custom" | "maatwerk"
    const GTAG_quoteConversion = (type: QuoteType) => {
        console.log('GTAG_quoteConversion Loaded', type);
    }

    const GTAG_locationConversion = () => {
        console.log('GTAG_locationConversion Loaded');
    }

    const GTAG_RapportConversion = () => {
        console.log('GTAG_RapportConversion Loaded');
    }

    const GTAG_GeneralConversion = () => {
        console.log('GTAG_GeneralConversion Loaded');
    }

    return {
        GTAG_contactConversion,
        GTAG_calendlyConversion,
        GTAG_quoteConversion,
        GTAG_locationConversion,
        GTAG_RapportConversion,
        GTAG_GeneralConversion,
    }
}

export default useGtagConversions