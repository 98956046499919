'use client';

import React, { useEffect, useRef, useState } from 'react';

import { developmentTabs, marketingTabs } from '@/lib/constants/websites';

import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import SquareIcon from '@mui/icons-material/Square';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface TabPanelSubProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

type Props = {
    itemOne?: Array<any>;
    itemTwo?: Array<any>;
};

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
        {value === index && (
            <div>{children}</div>
        )}
        </div>
    );
}
function CustomSubTabPanel(props: TabPanelSubProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className='h-full w-full'
            role="tabpanel"
            hidden={value !== index}
            id={`simple-sub-tabpanel-${index}`}
            aria-labelledby={`simple-sub-tab-${index}`}
            {...other}
        >
        {value === index && (
            <div>{children}</div>
        )}
        </div>
    );
}

function a11yPropsSubs(index: number) {
    return {
      id: `simple-sub-tab-${index}`,
      'aria-controls': `simple-sub-tabpanel-${index}`,
    };
}
  
const WebsiteTabs = ({ itemOne, itemTwo }: Props) => {
    const [subValue, setSubValue] = useState(0);

    const itemsRef = useRef<HTMLDivElement[] | null[]>([]);

    const handleSubChange = (event: React.SyntheticEvent, newValue: number) => {
        const child = document.createElement("div");
        child.classList.add("absolute", "bottom-0", "left-0", "h-5px", "bg-primary-yellow-400", "progress-animation");
        itemsRef.current[subValue]?.appendChild(child);

        if (newValue >= developmentTabs.length) {
            newValue = 0;
        }

        itemsRef.current[subValue]?.addEventListener('animationend', () => {
            if (itemsRef.current[subValue] !== null && itemsRef.current[subValue] !== undefined && itemsRef.current[subValue]?.contains(child)) {
                itemsRef.current[subValue]?.removeChild(child);
                setSubValue(newValue);
            }
        });
    };

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, developmentTabs.length);
    }, []);

    useEffect(() => {
        const child = document.createElement("div");
        child.classList.add("absolute", "bottom-0", "left-0", "h-5px", "bg-primary-yellow-400", "progress-animation");
        itemsRef.current[subValue]?.appendChild(child);

        let newValue = subValue + 1;
        if (newValue >= developmentTabs.length) {
            newValue = 0;
        }

        itemsRef.current[subValue]?.addEventListener('animationend', () => {
            if (itemsRef.current[subValue] !== null && itemsRef.current[subValue] !== undefined && itemsRef.current[subValue]?.contains(child)) {
                itemsRef.current[subValue]?.removeChild(child);
                setSubValue(newValue);
            }
        });
    }, [subValue]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Item>
                    <CustomTabPanel value={0} index={0}>
                        <Grid container spacing={3} className="justify-center">
                            <Grid item xs={12} md={itemOne !== undefined && itemOne[0].md ? itemOne[0].md : 3} lg={itemOne !== undefined && itemOne[0].lg ? itemOne[0].lg : 3}>
                                <Tabs orientation="vertical" value={subValue} onChange={handleSubChange} aria-label="tabs" className="flex flex-col"
                                    sx={{
                                        "& .MuiTabs-flexContainer": {
                                            xs: {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                columnGap: '0.5rem'
                                            },
                                            md: {
                                                flexDirection: 'column',
                                                flexWrap: 'nowrap',
                                                columnGap: '0.5rem'
                                            },
                                        },
                                        "& button": {backgroundColor: 'transparent', color: '#ffffff',transitionDuration: '0.3s'},
                                        "& button.Mui-selected": {backgroundColor: 'white', color: '#000000'},
                                        "& .MuiTabs-indicator": { display: 'none' }
                                    }}
                                >
                                    {developmentTabs.map((item, index) => (
                                        <Tab ref={el => itemsRef.current[index] = el} key={index} label={item.name} {...a11yPropsSubs(index)} className="w-fit items-start px-2 py-0 my-0 min-w-0 text-base text-start rounded-xl" />
                                    ))}
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} md={itemTwo !== undefined && itemTwo[0].md ? itemTwo[0].md : 9} lg={itemTwo !== undefined && itemTwo[0].lg ? itemTwo[0].lg : 9}>
                                <Item className="pl-0 flex">
                                    { developmentTabs.map((item, index) => (
                                        <CustomSubTabPanel key={index} value={subValue} index={index}>
                                            <List className="lg:pl-4 m-0 grid grid-cols-2 md:grid-cols-3 grid-flow-row gap-4 text-start">
                                                { item.items.map((subItem, index) => (
                                                    <ListItem key={index} className="p-0 m-0">
                                                        <ListItemIcon className="w-4 min-w-fit pr-2">
                                                            <SquareIcon className="fill-white w-4 text-xl" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={subItem}
                                                        />
                                                    </ListItem> 
                                                ))}
                                            </List>
                                        </CustomSubTabPanel>
                                    ))}
                                </Item>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Item>
                <Item>
                    <CustomTabPanel value={0} index={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} lg={3}>
                                <Tabs orientation="vertical" value={subValue} onChange={handleSubChange} aria-label="tabs" className="flex flex-col"
                                    sx={{
                                        "& .MuiTabs-flexContainer": {
                                            xs: {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                columnGap: '0.5rem'
                                            },
                                            md: {
                                                flexDirection: 'column',
                                                flexWrap: 'nowrap',
                                                columnGap: '0.5rem'
                                            },
                                        },
                                        "& button": {backgroundColor: 'transparent', color: '#ffffff',transitionDuration: '0.3s'},
                                        "& button.Mui-selected": {backgroundColor: 'white', color: '#000000'},
                                        "& .MuiTabs-indicator": {display: 'none'},
                                    }}
                                >
                                    { marketingTabs.map((item, index) => (
                                        <Tab key={index} label={item.name} {...a11yPropsSubs(index)} className="w-fit items-start px-2 py-0 my-0 min-w-0 text-base text-start" />
                                    ))}
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} md={8} lg={9}>
                                <Item className="pl-0">
                                    { marketingTabs.map((item, index) => (
                                        <CustomSubTabPanel key={index} value={subValue} index={index}>
                                            <List className="lg:pl-4 m-0 grid grid-cols-2 md:grid-cols-3 grid-flow-row gap-4 text-start">
                                                { item.items.map((subItem, index) => (
                                                    <ListItem key={index} className="p-0 m-0">
                                                        <ListItemIcon className="w-4 min-w-fit pr-2">
                                                            <SquareIcon className="fill-white w-4 text-xl" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={subItem}
                                                        />
                                                    </ListItem> 
                                                ))}
                                            </List>
                                        </CustomSubTabPanel>
                                    ))}
                                </Item>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Item>
            </Grid>
        </Grid>
    );
}
 
export default WebsiteTabs;