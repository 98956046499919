'use client';

import React, {useCallback, useEffect, useState} from 'react';
import { useFormik } from 'formik';

// Unique form Components
import Input from '@mui/material/Input';
import ErrorMessage from '@/components/shared/forms/ErrorMessage';

import { ButtonOne } from '@webbeukers/wb-library.package';

import emailApi from '@/lib/api/EmailApi';
import { useTranslations } from 'next-intl';

import { Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import Dropzone from '@/components/shared/Dropzone';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { emailTemplateConfigVacancy } from '@/lib/constants/mailTemplates';
import { DefaultPageProps, Params, VacanciesValues } from '@/lib/types/shared/page.types';

import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';

// define types of values
type Values = {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    fileUpload: string;
    extraInformation: string;
};

// define types of errors
type Errors = {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    fileUpload?: string;
    extraInformation?: string;
};

const validate = (values: Values) => {
  const errors:Errors={};
  
  // Name Validation
  if (!values.firstName) {
    errors.firstName = 'required';
  } else if (values.firstName.length < 2) {
    errors.firstName = 'first_name_must_be_at_least_2_characters';
  } else {
    if(errors.firstName) delete errors.firstName;
  }

  if (!values.lastName) {
    errors.lastName = 'required';
  } else if (values.lastName.length < 2) {
    errors.lastName = 'last_name_must_be_at_least_2_characters';
  } else {
    if(errors.lastName) delete errors.lastName;
  }

  // Phone Validation
  if (!values.phone) {
    errors.phone = 'required';
  } else if (values.phone.length < 10) {
    errors.phone = 'phone_must_be_at_least_10_characters';
  } else {
    if (errors.phone) delete errors.phone;
  }

  // Email Validation
  if (!values.email) {
    errors.email = 'required';
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  } else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalid_email_address';
  } else {
    if (errors.email) delete errors.email;
  }

  if(!values.fileUpload) {
    errors.fileUpload = 'required';
  } else if (values.fileUpload.length > 4) {
    // errors.fileUpload = t('You can only upload 4 files at a time.');
  } else {
    if(errors.fileUpload) delete errors.fileUpload;
  }

  return errors;
};

interface ExtendedParams extends Params {
  vacancy_page: string;
}
interface Props extends DefaultPageProps {
  params: ExtendedParams;
  vacancyData: VacanciesValues;
}

const ApplyForm = ({ params, vacancyData }: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<string | ArrayBuffer | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState('');
  
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const t = useTranslations();
  
  // const { executeRecaptcha } = useGoogleReCaptcha();
  
  const formik = useFormik({
    initialValues: {
      firstName: '',  
      lastName: '',
      phone: '',
      email: '',
      fileUpload: '',
      extraInformation: '',
    },
    
    validate,
    
    onSubmit: async (values) => {
      // console.log('values', values);
      setSubmitting(true);
      // if (!executeRecaptcha) {
      //   console.log("Execute recaptcha not yet available");
      //   setHasErrors(true);
      //   setErrorMessage('Recaptcha niet vollledig geladen');
      //   return;
      // }
    //   executeRecaptcha("contactFormSubmit").then((gReCaptchaToken) => {
        const vacatureTemplate = emailTemplateConfigVacancy(values.firstName, values.lastName, values.email, values.phone, values.extraInformation);
        
      emailApi.sendEmail(vacatureTemplate.subject, vacatureTemplate.html, {
        name: uploadedFileName,
        content: uploadedFile,
      }).then((response: any) => {
          // console.log('response', response);
          formik.resetForm();
          setSubmitting(false);
          setSubmitted(true);
        }).catch((error: any) => {
          console.error('error', error);
          setHasErrors(true);
          // setErrorMessage(error.message);
          setErrorMessage(t('something_went_wrong_applying_for_the_job_please_try_again_later'));
          setSubmitting(false);
        });
      // });
    }
  });

  // define types for event
  type Event = {
    target: {
      name: string;
      value: string;
      files?: any;
    };
  }

  const handleChange = (event: Event) => {
    if (event.target.name === 'fileUpload') {
      if (event.target.files.length > 0) {
        
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            setUploadedFile(fileReader.result);
            setUploadedFileName(event.target.files[0].name);
            formik.setFieldValue(event.target.name, event.target.value);
          }
        };
        fileReader.readAsDataURL(event.target.files[0]);
      }
    } else {
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };
  const handleBlur = (event: Event) => {
    formik.setFieldTouched(event.target.name);
  };
  const { errors, touched, values } = formik;

  return (
    <div>
      {isSubmitted ? (
        <div>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <div>
                  <Image
                    loading="lazy"
                    width={348}
                    height={463}
                    src="TeamMembers/thumb-avatar_qwzg91"
                    alt="Afbeelding"
                    className='w-7/20 h-auto'
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div>
                    <HeadingText variant='h3' className='text-start mb-3'>{t("thanks_for_your_application")}</HeadingText>
                    <Typography className='text-start mb-4' variant='body1'>
                        {t("we_have_received_your_application_and_will_contact_you_as_soon_as_possible")}
                    </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
          <div className='grid grid-cols-1 md:grid-cols-2'>
            <div>
                <HeadingText variant='h3' className='text-start mb-3'>{`${t("are_you_our_new_")}${vacancyData.title}`}</HeadingText>
                <Typography className='text-start mb-4' variant='body1'>
                    {t("if_you_are_looking_for_a_versatile_role_where_you_can_make_a_real_difference_we_want_to_hear_from_you_apply_directly_cv_via_the_form_and_hopefully_we_will_speak_soon")}
                </Typography>

                <div className='flex items-center gap-5 mx-0 mb-10 md:mb-0 w-fit'>
                    <Image
                      loading="lazy"
                      width={80}
                      height={80}
                      src="TeamMembers/stephan_a11ipm"
                      alt='Stephan Striekwold'
                      className='w-20 h-20 bg-white-accent rounded-full'
                    />
                    <div>
                        <HeadingText variant='h5' className='mb-0'>Stephan Striekwold</HeadingText>
                        <Typography variant='body1'>
                            {t("ceo")}
                        </Typography>
                    </div>
                </div>
            </div>
            <div>
              <div>
                {hasErrors ? (
                  <div>
                      <h1>{ errorMessage}</h1>
                      <div className='w-fit'>
                        <ButtonOne text="Refresh" variant="primary" onclick={() => window.location.reload()} hoverEffect={true} />
                      </div>
                  </div>
                ) : (
                  <form className='flex flex-col w-full gap-2' onSubmit={formik.handleSubmit}>
                    <div className="flex gap-2">
                      <div className="field flex-grow">
                        <div className="transition rounded-xl bg-white">
                          <Input 
                            className='w-full rounded-xl text-white px-4 py-1'
                            type="text"
                            name="firstName"
                            placeholder={t('first_name')}
                            disableUnderline={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            required
                            style={{ boxShadow: "0px 4px 10px 2px #00000040" }}
                          />
                        </div>
                        <ErrorMessage>
                          {errors.firstName && touched.firstName && t(errors.firstName)}
                        </ErrorMessage>
                      </div>
                      <div className="field flex-grow">
                        <div className="transition rounded-xl bg-white">
                          <Input 
                            className='w-full rounded-xl text-white px-4 py-1'
                            type="text"
                            name="lastName"
                            placeholder={t('last_name')}
                            disableUnderline={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            required
                            style={{ boxShadow: "0px 4px 10px 2px #00000040" }}
                          />
                        </div>
                        <ErrorMessage>
                          {errors.lastName && touched.lastName && t(errors.lastName)}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="flex gap-2">
                      <div className="field flex-grow">
                        <div className="transition rounded-xl bg-white">
                          <Input 
                            className='w-full rounded-xl text-white px-4 py-1'
                            type="number"
                            name="phone"
                            placeholder={t('phonenumber')}
                            disableUnderline={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            required
                            style={{ boxShadow: "0px 4px 10px 2px #00000040" }}
                          />
                        </div>
                        <ErrorMessage>
                          {errors.phone && touched.phone && t(errors.phone)}
                        </ErrorMessage>
                      </div>
                      <div className="field flex-grow">
                        <div className="transition rounded-xl bg-white">
                          <Input 
                            className='w-full rounded-xl text-white px-4 py-1'
                            type="email"
                            name="email"
                            placeholder={t('email')}
                            disableUnderline={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            required
                            style={{ boxShadow: "0px 4px 10px 2px #00000040" }}
                          />
                        </div>
                        <ErrorMessage>
                          {errors.email && touched.email && errors.email}
                        </ErrorMessage>
                      </div>
                    </div>

                    <Dropzone errors={errors} formik={formik} handleChange={handleChange} />
              
                    <div className="field">
                      <div className="transition rounded-xl bg-white">
                        <Input
                          className='w-full rounded-xl text-white px-4 py-1'
                          type="text"
                          name="extraInformation"
                          placeholder={t('extra_information_optional')}
                          disableUnderline={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.extraInformation}
                          required
                          style={{ boxShadow: "0px 4px 10px 2px #00000040" }}
                        />
                      </div>
                      <ErrorMessage>
                        {errors.extraInformation && touched.extraInformation && t(errors.extraInformation)}
                      </ErrorMessage>
                    </div>
                    
                    <div className="flex md:justify-end justify-center">
                      <MoveFadeInAnimation delay={0} direction="right" distance="sm" amount={0.5}>
                          <ButtonOne variant="primary" text={isSubmitting ? t('sending') : t('apply')} type="button" disabled={isSubmitting} hoverEffect={true} />
                      </MoveFadeInAnimation>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default ApplyForm;