'use client';

import { route, defaultLocale } from '@/lib/navigationFunctions';
import { menuExtendedPathnames } from '@/lib/navigation';


import { AppBar, Container, Typography, Grid, List, Stack, IconButton, ListItemButton, ListItemText } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Params } from "@/lib/types/shared/page.types";
import { useEffect, useRef, useState } from "react";
import { useTranslations } from "next-intl";
import { NPM_ButtonOne, Review } from "@/components/shared";
import Link from "next/link";
import { untranslatedReviews } from "@/lib/constants";

import { usePathname } from 'next/navigation';
import useGtagConversions from "@/lib/hooks/use-gtag-conversions";

import LanguageSwitcher from '@/components/shared/LanguageSwitcher';
import QuoteFormModal from '@/components/shared/modals/QuoteFormModal';
import QuoteActionModal from '@/components/shared/modals/QuoteActionModal';
import HideOnScroll from '@/components/shared/HideOnScroll';
import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';

type HeaderProps = {
	params: Params;
	pathname: any;
}

interface MegaMenuItem {
	name: string,
	en: string,
}

type MegaMenuItems = {
	[key: string]: MegaMenuItem[][];
}

export default function Header({ params, pathname }: HeaderProps) {
	const t = useTranslations();

	if (!params.locale) {
		params.locale = defaultLocale;
	}
	
	const [reviews, setReviews] = useState<any[]>([]);
	const translatedReviews = untranslatedReviews.map((review) => {
		return {
			...review,
			function: t(review.function),
			review: t(review.review),
		}
	});

	const { GTAG_calendlyConversion } = useGtagConversions();

	const mobileMenuRef = useRef<HTMLDivElement>(null);
	const megaMenuWrapperRef = useRef<HTMLDivElement>(null);
	const megaMenuRef = useRef<HTMLDivElement>(null);

	const [menuOpen, setMenuOpen] = useState(false);
	const [animation, setAnimation] = useState("");

	const [isHovered, setIsHovered] = useState(false);
	const [hoveredParent, setHoveredParent] = useState<string>('');

	const [activeMobileParent, setActiveMobileParent] = useState<string>('');
	
	const toggleMenu = (state = false) => {
		if (state) {
			setMenuOpen(state);

			setAnimation("fadeInQuick");

			setTimeout(() => {
				setAnimation("");
			}, 500);
		} else {
			setAnimation("fadeOutQuick");

			setTimeout(() => {
				setAnimation("");

				setMenuOpen(state);
			}, 500);
		}
	};

	const hideMegaMenu = () => {
		setHoveredParent('');
		setIsHovered(false);
	};

	const [adviceOpen, setAdviceOpen] = useState(false);
	const handleAdviceOpen = () => setAdviceOpen(true);
	const handleAdviceClose = () => setAdviceOpen(false);

	const currentPathname = usePathname().replace(/\/$/, '');

	// const [megaMenuItems, setMegaMenuItems] = useState<MegaMenuItems>({});
	
	const [megaMenuItemsFiltered, setMegaMenuItemsFiltered] = useState<MegaMenuItems>({});


	useEffect(() => {
		setReviews(translatedReviews);
		
		// const loopMegaMenuItems: MegaMenuItems = {};
		// const menuItems = Object.keys(extendedPathnames).filter((key) => {
		// 	const extendedPathname = extendedPathnames[key];
		// 	return extendedPathname.showInMenu === true;
		// });

		// for(const [key, value] of Object.entries(menuItems)) {
		// 	const subItems = Object.values(extendedPathnames).filter((item) => item.parentKey === value);
			
		// 	const subItemsGroups = [];
		// 	for (let i = 0; i < subItems.length; i += 4) {
		// 		subItemsGroups.push(subItems.slice(i, i + 4));
		// 	}
		// 	loopMegaMenuItems[value] = subItemsGroups;

		// 	if (parseInt(key) === menuItems.length - 1) {
		// 		setMegaMenuItems(loopMegaMenuItems);
		// 	}
		// }

		const loopMegaMenuItemsFiltered: MegaMenuItems = {};
		const menuItems = Object.keys(menuExtendedPathnames).filter((key) => {
			const extendedPathname = menuExtendedPathnames[key];
			return extendedPathname.showInMenu === true;
		});

		for(const [key, value] of Object.entries(menuItems)) {
			const subItems = Object.values(menuExtendedPathnames).filter((item) => item.parentKey === value);
			
			const subItemsGroups = [];
			for (let i = 0; i < subItems.length; i += 4) {
				subItemsGroups.push(subItems.slice(i, i + 4));
			}
			loopMegaMenuItemsFiltered[value] = subItemsGroups;

			if (parseInt(key) === menuItems.length - 1) {
				setMegaMenuItemsFiltered(loopMegaMenuItemsFiltered);
			}
		}
		

	}, []);

	return (
		<>
			<div className="classes hidden" />
			<AppBar
				position="fixed"
				sx={{
					boxShadow: "unset",
					backgroundColor: "transparent",
					color: "#1D1D1B",
				}}
			>
				<Container>
					<div className="flex justify-start w-full">
						<div className='bg-primary-gray-950'>
							<Link href={route('/', params.locale)} className="flex items-start relative z-10 bg-primary-gray-950 visible lg:invisible" title='Menu'>
								<div className='w-16 aspect-square flex px-2' onClick={() => toggleMenu(true)}>
									<MenuIcon id="mobile-menu-trigger-menu" className="mobile-menu-trigger m-auto" />
								</div>
							</Link>

							<HideOnScroll topClass={'mt-0'} topClassScrolled={'-mt-16'} className='relative z-0'>
								<Link href={route('/', params.locale)} className="flex items-start">
									<div className='w-16 aspect-square flex px-2 transition-all duration-300 relative -z-1'>
										<Image
											loading="lazy"
											src="logo_nqhpzn"
											className="w-full h-fit m-auto object-contain shadow shadow-primary-grey-300"
											width={64}
											height={128.59}
											alt="logo-banner"
										/>
									</div>
								</Link>
							</HideOnScroll>
						</div>

						<div ref={megaMenuWrapperRef} className={`relative hidden md:flex items-center flex-grow px-5 transition parent duration-700 nav-fade ${isHovered ? 'bg-[#1d1d1bd8] blur-effect' : ''}`} onMouseLeave={() => hideMegaMenu()}>
							<Grid flexGrow={1}>
								<List component={Stack} direction="row" gap={2} justifyContent="space-between" alignItems="center" className="w-fit ml-auto lg:w-auto lg:ml-0">
									{Object.keys(menuExtendedPathnames).map((key) => {
										const extendedPathname = menuExtendedPathnames[key as keyof typeof menuExtendedPathnames];
										const index = Object.keys(menuExtendedPathnames).indexOf(key);
										if (extendedPathname !== undefined && extendedPathname.showInMenu === true ) {
											return (
												<div key={index} data-mega-menu-id={`${extendedPathname.en}`} onMouseEnter={(e) => {
													const parent = e.currentTarget.getAttribute('data-mega-menu-id');
													if(typeof megaMenuItemsFiltered[parent as string] !== "undefined" && Object.keys(megaMenuItemsFiltered[parent as string]).length > 0){
														setHoveredParent(parent as string);
														setIsHovered(true);
													} else {
														setHoveredParent('');
														setIsHovered(false);
													}
												}} className={`hidden lg:block navigation-link-effect text-shadow ${route(extendedPathname[params.locale], params.locale) == currentPathname ? 'navigation-link-active-effect' : ''} `}>
													<ListItemButton
														{...{component: Link, to: route(extendedPathname.en, params.locale) }}
														sx={{ justifyContent: 'right', flexGrow: 0 }}
														aria-label={t(extendedPathname.name)}
													>
														<ListItemText
															disableTypography
															primary={
																<Typography variant="body1" className="font-semibold normal-case tracking-wider" sx={{color:"white"}} component="h4">
																	{t(extendedPathname.name)}
																</Typography>
															}
														/>
													</ListItemButton>
												</div>
											)
										} else {
											return null;
										}
									})}

									<div></div>
									<div></div>
									<div></div>
								</List>

								<div ref={megaMenuRef} className={`w-full px-mega-menu pb-5 justify-between absolute bottom-0 left-1/2 translate-y-full -translate-x-1/2 transition duration-700 nav-fade-children block ${isHovered ? 'bg-[#1d1d1bd8] blur-effect' : ''}`} onMouseLeave={() => hideMegaMenu()}>
									<div className="flex">
										{megaMenuItemsFiltered[hoveredParent] && megaMenuItemsFiltered[hoveredParent].map((item, index) => (
											<div key={index} className="fadeIn w-1/4">
												{item.map((subItem, subIndex) => (
													<Link key={subIndex} href={route(subItem.en, params.locale)} className="flex items-start w-fit navigation-link-effect">{t(subItem.name)}</Link>
												))}
											</div>
										))}

										{megaMenuItemsFiltered[hoveredParent] && (
										<>
											{hoveredParent === '/websites' && (
												<Review type="single" reviewId="sander" reviews={reviews} />
											)}
											{hoveredParent === '/solutions' && (
												<Review type="single" reviewId="rudy" reviews={reviews} />
											)}
											{hoveredParent === '/marketing-transformation' && (
												<Review type="single" reviewId="arian" reviews={reviews} />
											)}
											{hoveredParent === '/about' && (
												<Review type="single" reviewId="thomas" reviews={reviews} />
											)}
										</>
										)}
									</div>
								</div>
							</Grid>
						</div>
					</div>

					{/* MOBILE */}
					<div ref={mobileMenuRef} style={{
						zIndex: 9999999,
					}} className={`${menuOpen ? 'block menu-open' : 'hidden'} ${animation} bg-[#1d1d1be3] absolute inset-0 overflow-y-auto h-screen-mobile px-10`}> 
						<IconButton
							onClick={() => toggleMenu(false) }
							sx={{
								position: 'absolute',
								top: 15,
								right: 15,
							}}
							color="inherit"
							>
							<CloseIcon />
						</IconButton>

						<Container className="py-16">
							{Object.keys(menuExtendedPathnames).map((key) => {
								const extendedPathname = menuExtendedPathnames[key as keyof typeof menuExtendedPathnames];
								const index = Object.keys(menuExtendedPathnames).indexOf(key);
								if (extendedPathname !== undefined && extendedPathname.showInMenu === true ) {
									return (
										<div key={index}>
											<div className="flex gap-2">
												<ListItemButton
													{...{component: Link, to: route(extendedPathname.en, params.locale) }}
													sx={{ justifyContent: 'right', flexGrow: 0, paddingLeft: 0 }}
													aria-label={t(extendedPathname.name)}
												>
													<ListItemText onClick={(e) => {
															toggleMenu(false)
														}}
														disableTypography
														primary={
															<Typography variant="body1" className="font-semibold normal-case tracking-wider" sx={{color:"white"}} component="h4">
																{t(extendedPathname.name)}
															</Typography>
														}
													/>
												</ListItemButton>

												{typeof megaMenuItemsFiltered[extendedPathname.en as string] !== "undefined" && Object.keys(megaMenuItemsFiltered[extendedPathname.en as string]).length > 0 && (
													<IconButton data-mobile-menu-parent-id={`${extendedPathname.en}`} className="block" color="inherit" onClick={(e) => {
														const parent = e.currentTarget.getAttribute('data-mobile-menu-parent-id');
														if(typeof megaMenuItemsFiltered[parent as string] !== "undefined" && Object.keys(megaMenuItemsFiltered[parent as string]).length > 0){
															if(activeMobileParent === parent) {
																setActiveMobileParent('');
															} else {
																setActiveMobileParent(parent as string);
															}
														} else {
															setActiveMobileParent('');
														}
													}}>
														<ExpandMoreIcon className={` transition-all duration-300 ${activeMobileParent === extendedPathname.en ? 'rotate-180' : ''}`} />
													</IconButton>
												)}
											</div>

											<div className={`overflow-y-hidden ${activeMobileParent === extendedPathname.en ? 'h-auto' : 'h-0'}`}>
												{megaMenuItemsFiltered[extendedPathname.en] && megaMenuItemsFiltered[extendedPathname.en].map((item, index) => (
													<div key={index} className="">
														{item.map((subItem, subIndex) => (
															<ListItemButton
																{...{component: Link, to: route(subItem.en, params.locale) }}
																key={subIndex}
																sx={{ justifyContent: 'right', flexGrow: 0, paddingLeft: 3 }}
																aria-label={t(subItem.name)}
															>
																<ListItemText onClick={(e) => {
																		toggleMenu(false)
																	}}
																	disableTypography
																	primary={
																		<Typography variant="body1" className="font-semibold normal-case tracking-wider" sx={{color:"white"}} component="h5">
																			{t(subItem.name)}
																		</Typography>
																	}
																/>
															</ListItemButton>
														))}
													</div>
												))}
											</div>
										</div>
									)
								} else {
									return null;
								}
							})}
						</Container>

						<Container className="mb-10">
							<NPM_ButtonOne variant="secondary" text={t("schedule_meeting")} link={route("/contact", params.locale, "?calendly=true")} hoverEffect={true} />
						</Container>
					</div>
					
					{/* <QuoteActionModal params={params} /> */}
					<QuoteFormModal params={params} />

					<HideOnScroll className='right-5 h-12 grid place-items-center' topClass='top-10' topClassScrolled='-top-header'>
						<LanguageSwitcher params={params} pathname={pathname} />
					</HideOnScroll>
				</Container>
			</AppBar>
		</>
	)
}
