import React from 'react';

import { ComponentProps, DefaultPageProps } from '@/lib/types/shared/page.types';
import { Container, Typography } from '@mui/material';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { convertMarkdownLinks } from '@/lib/utils';
import { ButtonOne } from '@/components/shared';
import { ButtonOneProps } from '@/components/shared/ButtonOne';

import HeadingText from '@/components/shared/HeadingText';

type Props = {
    title? : string | Array<string>,
    convertTitle?: boolean,
    text? : string,
    textClassName?: string,
    convertTextToLink?: boolean,
    buttons?: ButtonOneProps[],
    children? : React.ReactNode
} & DefaultPageProps & ComponentProps;

export default function CaseSection({ title, convertTitle = false, text, textClassName = '', convertTextToLink = false, buttons, children, params }: Props) {
    return (
        <section className="my-16 py-16 scroll-mt-16 relative">
            <div className="w-1/2 h-full bg-gradient-to-r from-primary-gray-950 to-50% to-green-700 absolute top-1/2 -translate-y-1/2 left-0 z-0" />

            <Container className='relative z-1'>
                {title &&
                    typeof title === 'object' ? (
                        title.map((title, index) => (
                            <MoveFadeInAnimation key={index} direction="right" distance="xl" amount={0.5}>
                                <HeadingText variant="h3" className='text-center mb-4' enableMaxWidth={false}>
                                    {convertTitle ?
                                        <span dangerouslySetInnerHTML={{
                                            __html: convertMarkdownLinks(title, params.locale)
                                        }}></span>
                                    : title}
                                </HeadingText>
                            </MoveFadeInAnimation>
                        ))
                    ) : (
                        <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                            <HeadingText variant="h3" className='text-center mb-4' enableMaxWidth={false}>
                                {convertTitle ?
                                    <span dangerouslySetInnerHTML={{
                                        __html: convertMarkdownLinks(title ?? '', params.locale)
                                    }}></span>
                                : title}
                            </HeadingText>
                        </MoveFadeInAnimation>
                    )
                }
                {text &&
                    <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                        <Typography className={`text-center normal-case max-w-screen-lg ${textClassName}`} variant='body1'>
                            {convertTextToLink ?
                                <span className={`text-center normal-case max-w-screen-lg ${textClassName}`} dangerouslySetInnerHTML={{
                                    __html: convertMarkdownLinks(text, params.locale)
                                }}></span>
                            : text}
                        </Typography>
                    </MoveFadeInAnimation>
                }
                {buttons !== undefined &&
                    <div className="w-fit mx-auto mt-5 flex flex-wrap gap-3">
                        {buttons.map((button, index) => (
                            <MoveFadeInAnimation key={index} delay={400 * index} direction="right" distance="xl" amount={0.5}>
                                <ButtonOne key={index} variant={button.variant} text={button.text} link={button.link} hoverEffect={true} />
                            </MoveFadeInAnimation>
                        ))}
                    </div>
                }

                {children && children}
            </Container>

            <div className="w-1/2 h-full bg-gradient-to-r from-green-700 to-50% to-primary-gray-950 absolute top-1/2 -translate-y-1/2 right-0 z-0" />
        </section>
    );
}
