'use client';

import { Typography } from "@mui/material";

type Props = {
    children: React.ReactNode;
    className?: string;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    alignText?: 'start' | 'center' | 'end';
    enableMaxWidth?: boolean;
}

export const DefaultHeadingClasses = 'font-bold normal-case';

export const GetDefaultHeadingClasses = (variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6", enableMaxWidth = true) => {
    let headingClasses = "font-bold normal-case";

    if(enableMaxWidth) headingClasses += ' max-w-screen-md';

    if (variant === 'h1') headingClasses += ' leading-extra-tight';
    if (variant === 'h2') headingClasses += ' leading-tighter';
    if (variant === 'h3') headingClasses += ' leading-7 sm:leading-10';
    if (variant === 'h4') headingClasses += ' leading-7 sm:leading-10';
    if (variant === 'h5') headingClasses += ' leading-7 sm:leading-10';
    if (variant === 'h6') headingClasses += ' leading-7 sm:leading-10';

    return headingClasses;
}

export default function HeadingText({
    children,
    className = '',
    variant = 'h1',
    enableMaxWidth = true,
}: Props) {
    return (
        <>
            <Typography className={`${GetDefaultHeadingClasses(variant, enableMaxWidth)} ${className}`} variant={variant}>
                {children}
            </Typography>
        </>
    )
}
