'use client';

import React from 'react';
import { start } from '@/lib/hooks/use-bugsnag';

const BugsnagContext = React.createContext <
    [instance: any, setInstance: React.Dispatch<React.SetStateAction<any>>] | undefined
>(undefined);

export function BugsnagProvider({ children }: { children: React.ReactNode }) {
    const [instance, setInstance] = React.useState(start());
    return (
        <BugsnagContext.Provider value={[instance, setInstance]}>
            {children}
        </BugsnagContext.Provider>
    );
}

export function useBugsnag() {
    const context = React.useContext(BugsnagContext);
    if (context === undefined) {
        throw new Error('useBugsnag must be used within a BugsnagProvider');
    }
    return context;
}
