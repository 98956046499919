import Schema, { SchemaDefinition } from 'validate'

export type FormValue = string | null | string[] | number | number[];
export type FormValues = {
    [key: string]: FormValue
}

export type DefaultFieldProps = {
    changeCallBack: (name: string, value: FormValue) => void;
    translation?: {
        namespace: string;
    } | null;
    className?: string,
    name: string;
    id: string;
}

export type Tooltip = {
    text: string,
    content: string
}

export type Option = {
    value: string,
    label: string
    disabled?: boolean,
    tooltip? : Tooltip
}

export type ActionLog = {
    timestamp: string,
    action_name: string,
    element_id: string,
    extra?: any
}

export type LocalStorageObject = {
    selectedABVariant?: string,
    uniqueId?: string,
    loaded_at?: string,
    isSubmitted?: boolean,
    isSubmittedMailchimp?: boolean,
    action_log: ActionLog[],
    form_values: FormValues,
}

export type QuoteFormQuestion = {
    order: number,
    question: string,
    id: string,
    type: "text" | "dropdown" | "buttons"| "checkboxes" | "radio" | "text" | "textarea" | "range" | "number" | "subitems",
    // required: boolean,

    ignoreInProgress?: boolean,

    topWrapperClassname?: string,

    buttonsClassname?: string,
    classname?: string,

    value?: FormValue,

    placeholder?: string,
    
    description?: string,

    tooltip?: Tooltip,
    
    isMultiple?: boolean,
    options?: Array<Option>

    min?: number,
    max?: number,

    translateLabelInEmail?: boolean,
    translateValueInEmail?: boolean,

    parent?: QuoteFormQuestion,
    subItems?: Array<QuoteFormQuestion>,
    wrapSubItems?: boolean,
    showSubItemsWhen?: {
        id: string,
        value: string
    },
}

export const QuoteFormSchemaObject: SchemaDefinition = {
    website_type: {
        type: String,
        required: false,
        enum: ['interactive', 'business-card', 'e-commerce', 'custom'],
        message: 'website_type'
    },
    page_count: {
        type: Number,
        required: false,
        size: {
            min: 1,
            max: 40
        },
        message: 'page_count'
    },
    // self_customizing: {
    //     type: String,
    //     required: true,
    //     enum: ['yes', 'no'],
    //     message: 'self_customizing'
    // },
    // self_content_delivery: {
    //     type: String,
    //     required: true,
    //     enum: ['yes', 'no'],
    //     message: 'self_content_delivery'
    // },
    // needed_content: {
    //     each: {
    //         type: String,
    //         enum: ['images', 'texts', 'logo', 'corporate_identity']
    //     },
    //     message: 'needed_content',
    // },
    self_arrange_designs: {
        type: String,
        required: false,
        enum: ['yes', 'no'],
        message: 'self_arrange_designs'
    },
    integrations: {
        type: String,
        required: false,
        message: 'integrations'
    },
    budget: {
        type: String,
        required: false,
        message: 'budget'
    },
    // company_name: {
    //     type: String,
    //     required: true,
    //     message: 'company_name'
    // },
    // name: {
    //     type: String,
    //     required: true,
    //     message: 'name'
    // },
    phone: {
        type: String,
        required: true,
        match: /^\d{10,14}$/,
        message: 'phone'
    },
    email: {
        type: String,
        required: true,
        match: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'email'
    },
    // street_and_number: {
    //     type: String,
    //     required: true,
    //     message: 'street_and_number'
    // },
    // city: {
    //     type: String,
    //     required: true,
    //     message: 'city'
    // }
}

export const QuoteFormSchema = new Schema(QuoteFormSchemaObject)
