'use client'

import React from 'react';
import { Container } from '@mui/material';
import CombinedContactForm from '@/components/shared/forms/CombinedContactForm';
import Image from '@/components/shared/Image';

type Props = {
    title?: string,
    text?: string,
    onButtonClick?: (buttonId: string) => void,
}

const ContactSection = ({ title, text, onButtonClick = (buttonId: string) => {} }: Props) => {
    return (
        <section className='relative my-16'>
            {/* Shown on phone */}
            <Image
                loading="lazy"
                src="TeamMembers/stephan-calling_qtebus"
                alt='Stephan Striekwold'
                width={549}
                height={523}
                className='block md:hidden w-4/5 ml-auto h-80 mb-5 object-cover rounded-l-2xl'
            />

            <Container>
                {/* Hidden on phone */}
                <div className="hidden md:block w-1/2 h-1/2 bg-gradient-to-r from-transparent to-50% to-green-600 absolute top-1/2 -translate-y-1/2 left-0 z-min-1" />
                {/* Shown on phone */}
                <div className="block md:hidden w-1/2 h-48 bg-gradient-to-r from-transparent to-50% to-green-600 absolute top-16 left-0 z-min-1" />

                <div className='bg-primary-gray-950 max-w-screen-lg rounded-2xl overflow-hidden small-spacing'>
                    <CombinedContactForm title={title} text={text} onButtonClicked={onButtonClick} />
                </div>

                {/* Hidden on phone */}
                <div className="hidden md:block w-1/2 h-1/2 bg-gradient-to-l from-transparent to-50% to-green-600 absolute top-1/2 -translate-y-1/2 right-0 z-min-1" />
                {/* Shown on phone */}
                <div className="block md:hidden w-1/2 h-48 bg-gradient-to-l from-transparent to-50% to-green-600 absolute top-16 right-0 z-min-1" />
            </Container>
        </section>
    );
}

export default ContactSection;