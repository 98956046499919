import client from "@/lib/api/ApiClient";

interface AttachmentObject {
    name: string;
    content: string | ArrayBuffer | null;
}

export const sendEmail = (Subject: string, Body: string, Attachment: AttachmentObject | null = null) => {
    return client.post("/email/send", { Subject, Body, Attachment }, { headers: { 'wb-email-token': process.env.NEXT_API_HEADER_TOKEN ?? '' } });
}

export default {
    sendEmail
};