import { ComponentProps, QuestionProps } from '@/lib/types/shared/page.types';
import { Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

type Props = ComponentProps & {
    questions: Array<QuestionProps>;
};

export default function Questions({ questions }: Props) {
    // const t = useTranslations('pages.home.faq');
    const t = useTranslations();

    const [active, setActive] = useState(1);

    const handleClick = (e: any, id: number) => {
        if (id === active) {
            setActive(0);
            return;
        }

        if (active !== 0) {
            setActive(0);
    
            setTimeout(() => {
                setActive(id);
            }, 300);
        } else {
            setActive(id);
        }
    };

    return (
        <>
            <div className='md:w-2/3 h-full inset-0 z-10 transition-all duration-300'>
                <div className='h-full relative'>
                    <div className="max-w-2/3 h-2/3 md:h-1/2 xl:h-3/4 overflow-y-auto relative">
                        <div className='w-full h-6 sticky top-0 z-10' />
                        
                        <div className="flex flex-col gap-3">
                            {questions.map((question, index) => (
                                <React.Fragment key={index}>
                                    <div>
                                        {/* <h3 className={`question ${active === index + 1 ? 'active' : ''} mb-2`} onClick={(e) => handleClick(e, index + 1)}>{question.title}</h3> */}
                                        <Typography className={`question ${active === index + 1 ? 'active' : ''} text-2xl font-semibold my-2`} variant="body1" onClick={(e) => handleClick(e, index + 1)}>
                                            {t(question.title)}
                                        </Typography>

                                        <div className={`answer ${active === index + 1 ? 'max-h-full scale-y-100' : 'max-h-0 scale-y-0'} my-2`}>
                                            <p className='text-white'>{t(question.content)}</p>
                                        </div>
                                    </div>

                                    {index !== questions.length - 1 && <hr className='block  border-white' />}
                                </React.Fragment>
                            ))}
                        </div>

                        <div className='w-full h-6 sticky bottom-0 bg-transparent-to-dark-bottom' />
                    </div>
                </div>
            </div>
        </>
    )
}
