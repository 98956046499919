'use client';

import { useTranslations } from 'next-intl';
import React, { useEffect, useRef, useState } from 'react';
import { PopupButton } from 'react-calendly';

const CalendlyButton = () => {
    const t = useTranslations();

    const calendlyButtonRef = useRef<HTMLDivElement>(null);
    const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setRootElement(document.getElementById('root'));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            try {    
                const button = calendlyButtonRef.current?.querySelector('button');                
                if (button) {
                    button.click();
                    window.history.replaceState({}, '', window.location.pathname);
                }
            } catch (error) {
                console.error('Calendly button error:', error);                
            }
        }, 100);
    }, [rootElement]);

    return (
        <>
            {rootElement && (
                <div ref={calendlyButtonRef}>
                    <PopupButton
                        url="https://calendly.com/webbeukers/30min"
                        rootElement={rootElement}
                        text={t("schedule_a_meeting")}
                    />
                </div>
            )}
        </>
    );
};

export default CalendlyButton;