'use client'

import { createRef, useEffect} from 'react';

type Props = {
    children: React.ReactNode;
    className?: string;
    topClass: string;
    topClassScrolled: string;
}

export default function HideOnScroll({ children, className = '', topClass, topClassScrolled }: Props) {
    const headerRef = createRef<HTMLDivElement>();

    // const topClass = 'top-10';
    // const topClassScrolled = '-top-header';

	let prevScrollpos = 0;

    const handleScroll = () => {
        // window.onscroll = function() {
        const currentScrollPos = window.pageYOffset;
        
        if (currentScrollPos <= 5) {
            if (headerRef.current !== null) {
                headerRef.current.classList.add(topClass);
                headerRef.current.classList.remove(topClassScrolled);
            } else {
                console.log('currentScrollPos <= 5 - headerRef.current is null');
            }
        } else {
            if (prevScrollpos > currentScrollPos) {
                if (headerRef.current !== null) {
                    headerRef.current.classList.add(topClass);
                    headerRef.current.classList.remove(topClassScrolled);
                } else {
                    console.log('prevScrollpos > currentScrollPos - headerRef.current is null');
                }
            } else {
                if (headerRef.current !== null) {
                    headerRef.current.classList.remove(topClass);
                    headerRef.current.classList.add(topClassScrolled);
                } else {
                    console.log('prevScrollpos <= currentScrollPos - headerRef.current is null');
                }
            }
        }

        prevScrollpos = currentScrollPos;
        // }
	}

    useEffect(() => {
        if(!headerRef.current) return;
		prevScrollpos = window.pageYOffset;
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
	}, [headerRef]);

    return (
      <>
        <div ref={headerRef} style={{zIndex: 9999998}} className={`fixed ${topClass} transition-all duration-500 ${className}`}>
            {children}
        </div>
      </>
  )
}
