'use client'

import { DefaultFieldProps, FormValue, Option } from '@/lib/types/shared/form.types'
import { Params } from '@/lib/types/shared/page.types'
import { useTranslations } from 'next-intl'
import { NPM_ButtonOne } from '@/components/shared/index'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import HeadingText from "@/components/shared/HeadingText"


type Props = DefaultFieldProps & {
    params: Params,
    selectedValue: string | string[],
    options: Array<Option>
    isMultiple?: boolean
}

export default function Buttons({
    params,
    changeCallBack,
    translation = null,
    className = "",
    name,
    id,
    options,
    isMultiple = false,
    selectedValue,
}: Props) {
    let t = useTranslations(translation?.namespace ?? '') as any;
    if (translation === null) {
        t = (string: string) => string;
    }

    const handleOnClick = (value: string) => {
        if (isMultiple) {
            if (Array.isArray(selectedValue)) {
                if (selectedValue.includes(value)) {
                    changeCallBack(name, selectedValue.filter(val => val !== value))
                } else {
                    changeCallBack(name, [...selectedValue, value])
                }
            } else {
                changeCallBack(name, [value])
            }
        } else {
            if (selectedValue === value) {
                changeCallBack(name, null)
            } else {
                changeCallBack(name, value)
            }
        }
    }

    return (
        <>
            <div className={`w-full flex flex-wrap gap-4 ${className}`}>
                {options.map((option, index) => {
                    const spreadProps = option.disabled ? { disabled: true } : {};

                    let isSelected = false;
                    if (isMultiple) {
                        isSelected = Array.isArray(selectedValue) && selectedValue.includes(option.value);
                    } else {
                        isSelected = selectedValue === option.value;
                    }

                    const variant = isSelected ? 'secondary' : 'primary';

                    return (
                        <NPM_ButtonOne {...spreadProps} type='button' onclick={() => handleOnClick(option.value ?? '')} className={`w-full md:w-fit md:flex-1 mx-0 flex items-center gap-2 justify-between`} key={index} variant={variant} hoverEffect={true} >
                            {t(option.label)}

                            {option.tooltip && (
                                <div className="custom-tooltip flex items-center gap-2 cursor-pointer">
                                    <div className="bg-transparent border-2 border-gray-950 rounded-full w-6 h-6 flex justify-center items-center relative">
                                        <HeadingText variant="h5">?</HeadingText>
                                        <div className="custom-tooltip-child bg-primary-gray-950 p-3 rounded-lg absolute -right-28 md:left-0 top-full w-screen max-w-350px">
                                            <Typography variant="body2" className="font-normal text-white">{t(option.tooltip.content)}</Typography>
                                        </div>
                                    </div>
                                    {option.tooltip.text && (
                                        <div className="h-6 flex items-center">
                                            <Typography variant="body1" className="normal-case text-gray-950">{t(option.tooltip.text)}</Typography>
                                        </div>
                                    )}
                                </div>
                            )}
                        </NPM_ButtonOne>
                    )
                })}
            </div>
        </>
    )
}
