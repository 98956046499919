'use client'

import { Container } from '@mui/material';
import React from 'react';
import ApplyForm from '@/components/shared/forms/ApplyForm';
import { DefaultPageProps, Params, VacanciesValues } from '@/lib/types/shared/page.types';

interface ExtendedParams extends Params {
    vacancy_page: string;
  }
interface Props extends DefaultPageProps {
    params: ExtendedParams;
    vacancyData: VacanciesValues;
}

const ApplySection = ({params, vacancyData}: Props) => {

    return (
        <section className='relative my-16 px-12'>
            <div className="w-1/2 h-1/2 bg-gradient-to-r from-transparent to-50% to-green-600 absolute top-1/2 -translate-y-1/2 left-0 z-min-1"></div>

            <Container className='bg-green-600 max-w-screen-lg py-10 rounded-2xl small-spacing'>
                <ApplyForm vacancyData={vacancyData} params={params} />
            </Container>

            <div className="w-1/2 h-1/2 bg-gradient-to-l from-transparent to-50% to-green-600 absolute top-1/2 -translate-y-1/2 right-0 z-min-1"></div>
        </section>
    );
}

export default ApplySection;