import { Locales, route } from "@/lib/navigationFunctions";

import { getTranslations } from "next-intl/server";
import { getGlobalConstants } from ".";
import { MarketingTransformationPage, MarketingTransformationPath, MarketingValues } from "@/lib/types/shared/page.types";

export const marketingTransformationPaths: MarketingTransformationPath[] = [
    {
        id: 'seo',
        name: 'seo_optimalization',
        link: {
            en: '/marketing-transformation/seo',
            nl: '/marketing-transformatie/seo'
        },
    },
    {
        id: 'sea',
        name: 'sea_advertising',
        link: {
            en: '/marketing-transformation/sea',
            nl: '/marketing-transformatie/sea'
        },
    },
    {
        id: 'cro',
        name: 'cro_optimization',
        link: {
            en: '/marketing-transformation/cro',
            nl: '/marketing-transformatie/cro'
        },
    },
    {
        id: 'texts',
        name: 'texts_and_content',
        link: {
            en: '/marketing-transformation/texts',
            nl: '/marketing-transformatie/teksten'
        },
    },
    {
        id: 'branding-strategy',
        name: 'branding_strategy',
        link: {
            en: '/marketing-transformation/branding-strategy',
            nl: '/marketing-transformatie/branding-strategie'
        },
    },
    {
        id: 'social-media',
        name: 'social_media',
        link: {
            en: '/marketing-transformation/social-media',
            nl: '/marketing-transformatie/social-media'
        },
    },
];

// export const MarketingCorevalues: MarketingValues[] = [
//     {
//         title: "seo_search_engine_optimization",
//         text: "increase_your_online_findability_with_our_tailormade_seo_strategies_we_ensure_that_your_website_is_not_only_found_but_also_excels_in_the_search_results",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
//     {
//         title: "have_texts_written",
//         text: "have_your_brand_story_told_by_our_professional_copywriters_we_create_compelling_content_that_fits_your_brand_and_attracts_your_target_group",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
//     {
//         title: "sea_search_engine_advertising",
//         text: "reach_your_target_group_quickly_and_effectively_with_our_sea_solutions_we_manage_and_optimize_your_paid_search_campaigns_for_maximum_impact",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
//     {
//         title: "branding_and_strategy",
//         text: "develop_an_unforgettable_brand_identity_with_our_creative_branding_and_strategic_planning_we_help_you_stand_out_in_a_crowded_market",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
//     {
//         title: "cro_conversion_optimization",
//         text: "increase_the_effectiveness_of_your_website_our_cro_strategies_are_designed_to_convert_visitors_into_customers",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
//     {
//         title: "social_media_management",
//         text: "strengthen_your_social_media_presence_our_social_media_strategies_increase_your_visibility_and_engagement_on_platforms_that_matter_to_you",
//         // link: route("/marketing-transformation/seo", params.locale),
//     },
// ];

const getMarketingTransformationConstants = async (locale: Locales | 'nl') => {
    let t = (variable: string) => {
        return variable;
    }

    if (locale) {
        t = await getTranslations({ locale: locale });
    }

    let globalConstants = await getGlobalConstants(locale);

    const reviews = globalConstants.reviews;
    

    const marketingTransformationPages: MarketingTransformationPage[] = [
        {
            id: 'seo',
            title: t('seo'),
            description: t('strengthen_your_online_presence_with_webbeukers_seo_strategies_discover_how_our_services_can_support_your_marketing_transformation'),
            keywords: `${t('seo')}, ${t('search_engine_optimization')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('online_visibility')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("seo"),
                    subTitle: t("search_engine_optimization"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("maximize_your_online_visibility_with_our_seo_services"),
                        text: t("at_web_beukers_we_understand_the_problem_that_many_companies_experience_their_beautiful_website_does_not_get_the_attention_it_deserves_invisibility_in_search_engines_leads_to_missed_opportunities_and_loss_of_potential_customers_which_is_frustrating_after_investing_in_a_website"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("why_is_seo_important"),
                        text: t("seo_or_search_engine_optimization_is_crucial_for_any_online_business_as_it_helps_improve_visibility_on_search_engines_like_google_by_optimizing_your_website_for_relevant_search_terms_you_increase_the_chances_of_potential_customers_finding_you_leading_to_more_organic_traffic_and_potential_sales"),
                        ul: [
                            {
                                title: t("increased_reliability"),
                                text: t("high_search_engine_rankings_are_associated_with_reliability_and_credibility"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("sustainable_results"),
                                text: t("seo_delivers_lasting_results_and_continues_to_generate_traffic"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("investment_in_future"),
                                text: t("investing_in_seo_is_investing_in_future_growth_and_success"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("costeffective"),
                                text: t("seo_is_more_costeffective_than_paid_advertising_in_the_long_run"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                        ],
                        image: "Articles/seo_tyxyso",
                        imagePosition: "right",
                        alt: t("an_overview_of_seo_tools_and_strategies_for_improving_a_websites_online_visibility"),
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("outsource_seo_let_us_help_you"),
                        text: t("outsourcing_seo_can_be_a_strategic_move_to_improve_your_online_presence_without_the_stress_of_managing_everything_yourself_at_web_beukers_we_understand_the_importance_of_search_engine_optimization_and_how_it_can_grow_your_business_heres_how_we_can_help_you"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("indepth_keyword_research"),
                                        text: t("we_not_only_identify_relevant_keywords_but_also_analyze_search_behavior_and_trends_to_strengthen_your_seo_strategy_with_popular_tools_such_as_google_analytics__search_console"),
                                    },
                                    {
                                        title: t("page_seo_optimization"),
                                        text: t("optimizing_the_content_and_structure_of_the_current_website_including_titles_meta_tags_and_the_use_of_keywords_in_the_texts_and_headers"),
                                    },
                                    {
                                        title: t("landing_page_creation"),
                                        text: t("developing_highquality_relevant_and_informative_content_that_matches_the_users_search_intent"),
                                    },
                                    {
                                        title: t("local_seo_optimization"),
                                        text: t("optimizing_the_website_for_local_search_results_which_is_especially_important_for_businesses_that_serve_a_local_audience"),
                                    },
                                    {
                                        title: t("monthly_seo_optimization"),
                                        text: t("essential_for_keeping_track_of_search_engine_trends_and_continuously_improving_your_online_visibility_and_userfriendliness"),
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("enjoy_full_service_everything_under_1_roof"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("website_speed_optimization"),
                                        text: t("boost_your_online_performance_with_our_professional_website_speed_optimization_increase_loading_speed_and_improve_user_experience_for_maximum_impact"),
                                    },
                                    {
                                        title: t("conversion_management"),
                                        text: t("optimize_your_conversions_with_our_effective_conversion_management_we_help_you_maximize_your_returns_and_improve_your_online_conversion_rates"),
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("free_seo_tips"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("linkbuilding"),
                                        text: t("obtaining_quality_backlinks_from_other_websites_which_helps_to_increase_the_authority_and_credibility_of_the_website"),
                                    },
                                    {
                                        title: t("blogging"),
                                        text: t("blogging_is_an_effective_way_to_share_expertise_and_build_an_audience_it_contributes_to_seo_by_using_relevant_keywords_and_increases_the_online_visibility_of_a_website"),
                                    },
                                ],
                                text: t("we_understand_that_building_and_maintaining_an_effective_blog_or_obtaining_external_links_can_be_timeconsuming_especially_if_you_want_to_focus_on_other_aspects_of_your_business_it_is_also_possible_to_have_these_services_provided_by_web_beukers_contact_us_for_more_information")
                            }
                        }
                    },
                },
            ]
        },
        {
            id: 'sea',
            title: t('sea'),
            description: t('boost_your_advertising_campaigns_with_web_beukers_sea_strategies_discover_how_our_sea_services_can_strengthen_your_marketing_transformation'),
            keywords: `${t('sea')}, ${t('search_engine_advertising')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('online_visibility')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("sea"),
                    subTitle: t("search_engine_advertising"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("shine_in_the_digital_arena_maximize_your_online_visibility_with_our_sea_services"),
                        text: t("improve_your_online_visibility_with_our_powerful_sea_services_do_you_have_a_great_website_but_no_one_can_seem_to_find_it_web_beukers_has_the_solution_we_understand_the_frustration_of_companies_that_are_invisible_in_search_engines_and_that_is_why_we_offer_highquality_sea_search_engine_advertising_services"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("why_is_sea_important"),
                        text: t("the_concept_of_sea_which_stands_for_search_engine_advertising_is_of_immense_importance_in_the_modern_business_world_sea_encompasses_the_strategies_and_practices_of_advertising_on_search_engines_such_as_google_and_bing_where_paid_advertisements_are_displayed_above_or_next_to_organic_search_results_there_are_several_reasons_why_sea_is_crucial_for_companies_and_marketers"),
                        ul: [
                            {
                                title: t("visibility_and_reach"),
                                text: t("sea_places_your_ads_directly_at_the_top_of_search_results_pages_allowing_potential_customers_to_find_them_quickly_increasing_your_visibility_and_reach"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("targeted_marketing"),
                                text: t("sea_enables_precise_targeting_based_on_keywords_location_and_demographics_allowing_you_to_target_your_ads_to_people_who_are_already_looking_for_your_products_or_services"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("measurability_and_control"),
                                text: t("with_sea_you_have_full_control_over_your_advertising_budget_placements_and_targeting_while_detailed_statistics_and_reports_help_you_measure_and_optimize_the_performance_of_your_campaigns"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("competitive_advantage"),
                                text: t("as_many_companies_invest_in_sea_it_provides_a_competitive_advantage_by_placing_your_brand_and_products_at_the_top_of_search_results_allowing_you_to_capitalize_on_trends_promote_offers_and_respond_quickly_to_market_changes"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                        ],
                        image: "Articles/sea_royhim",
                        imagePosition: "left",
                        alt: t("a_graphical_representation_of_sea_assets_for_targeted_online_advertising_campaigns"),
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("why_outsource_sea"),
                        text: t("outsourcing_sea_can_be_your_key_to_online_success_let_experts_optimize_your_ads_and_increase_your_visibility_while_you_focus_on_your_core_business_discover_the_benefits_of_sea_outsourcing_today"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("keyword_analysis_and_strategy"),
                                        text: t("this_includes_researching_relevant_keywords_and_setting_up_a_strategy_for_targeting_these_keywords_in_your_ad_campaigns"),
                                    },
                                    {
                                        title: t("campaign_management_and_optimization"),
                                        text: t("this_includes_setting_up_managing_and_continuously_optimizing_your_advertising_campaigns_to_improve_performance_and_maximize_your_roi"),
                                    },
                                    {
                                        title: t("advertising_creation_and_design"),
                                        text: t("this_refers_to_the_process_of_creating_effective_advertisements_including_designing_ad_copy_and_images"),
                                    },
                                    {
                                        title: t("remarketing_and_retargeting"),
                                        text: t("this_includes_retargeting_users_who_have_previously_interacted_with_your_ads_or_website"),
                                    },
                                    {
                                        title: t("budget_management"),
                                        text: t("this_involves_managing_your_advertising_budgets_effectively_to_ensure_you_achieve_your_marketing_objectives_within_your_financial_means"),
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("enjoy_full_service_everything_under_1_roof"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("competitive_analysis"),
                                        text: t("this_allows_you_to_keep_an_eye_on_your_competitors_and_understand_how_you_can_differentiate_yourself_in_the_market"),
                                    },
                                    {
                                        title: t("audience_targeting"),
                                        text: t("this_involves_identifying_and_targeting_specific_target_groups_based_on_demographics_interests_location_and_behavior"),
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                }
            ]
        },
        {
            id: 'cro',
            title: t('cro'),
            description: t('optimize_your_conversion_rates_with_webbeukers_cro_services_improve_the_performance_of_your_marketing_campaigns_and_increase_your_roi'),
            keywords: `${t('cro_optimization')}, ${t('conversion_optimization_services')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('online_visibility')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("cro"),
                    subTitle: t("conversion_rate_optimization"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("from_visitor_to_customer_the_power_of_optimized_conversions"),
                        text: t("are_you_frustrated_by_low_conversions_despite_high_website_traffic_and_attractive_products_this_often_points_to_ineffective_conversion_rate_optimization_cro_and_product_optimization_leading_to_lost_revenue_higher_advertising_costs_and_competitive_disadvantages_by_implementing_an_effective_cro_strategy_and_product_optimization_you_can_address_these_challenges_significantly_improving_your_business_success_start_transforming_your_future_now"),
                        ul: [
                            {
                                title: t("cost_reduction"),
                                text: t("by_increasing_conversion_rates_you_can_reduce_costs_per_acquisition"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("better_user_experience"),
                                text: t("cro_focuses_on_improving_the_user_experience_which_leads_to_greater_customer_loyalty"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("datadriven_decisions"),
                                text: t("cro_relies_on_data_analysis_which_leads_to_more_informed_strategic_decisions"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("increased_sales"),
                                text: t("this_is_the_direct_result_of_improved_conversion_rates__more_sales_without_additional_traffic"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                        ],
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("the_power_of_expertise_why_outsourcing_your_cro_pays_off"),
                        text: t("outsourcing_conversion_rate_optimization_cro_means_access_to_specialized_expertise_and_advanced_tools_without_the_need_for_inhouse_development_this_allows_your_business_to_focus_on_core_activities_while_experts_optimize_your_conversion_rates_leading_to_better_performance_and_competitive_advantage"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("data_analysis_and_user_behavior_research"),
                                        text: t("analyzing_website_traffic_and_user_behavior_to_gain_insights_into_how_visitors_interact_with_your_website_this_includes_the_use_of_heat_maps_session_recordings_and_other_analysis_tools"),
                                    },
                                    {
                                        title: t("ab_testing_and_multivariate_testing"),
                                        text: t("designing_and_running_tests_to_compare_different_versions_of_web_page_elements_to_determine_which_combinations_perform_best_in_terms_of_user_engagement_and_conversions"),
                                    },
                                    {
                                        title: t("landing_page_optimization"),
                                        text: t("creating_and_finetuning_landing_pages_to_increase_conversion_rates_this_can_include_rewriting_content_redesigning_the_layout_and_customizing_calltoaction_buttons"),
                                    },
                                    {
                                        title: t("conversionoriented_web_design"),
                                        text: t("designing_or_redesigning_websites_with_a_focus_on_maximizing_conversions_where_userfriendliness_and_customer_journey_are_central"),
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
            ]
        },
        {
            id: 'texts',
            title: t('writing_your_texts'),
            description: t('strengthen_your_marketing_strategy_with_the_optimized_textual_content_of_webbeukers_improve_your_brand_communication_and_engage_your_target_group'),
            keywords: `${t('texts_and_content')}, ${t('content_creation')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('target_group_engagement')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("writing_your_texts"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("professional_content_creation_your_key_to_effective_online_communication"),
                        text: t("at_webbeukers_we_understand_the_power_of_words_writing_effective_texts_is_an_art_and_a_science_at_the_same_time_whether_its_web_content_blog_posts_ad_copy_or_technical_documentation_our_team_of_experienced_writers_dive_deep_into_your_brand_story_to_create_messages_that_resonate_with_a_perfect_mix_of_creativity_and_strategic_insight_we_ensure_that_every_text_not_only_appeals_to_your_target_group_but_also_contributes_to_your_business_objectives"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("turn_words_into_profit_effective_content_by_professionals"),
                        ul: [
                            {
                                title: t("improved_quality_and_professionalism"),
                                text: t("professionally_written_texts_ensure_a_higher_quality_standard_they_are_grammatically_correct_well_structured_and_reflect_a_professional_image_which_is_essential_for_building_trust_with_your_target_audience"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("time_and_cost_savings"),
                                text: t("outsourcing_copywriting_saves_you_time_and_resources_this_allows_you_to_focus_on_other_crucial_aspects_of_your_business_while_experts_take_care_of_your_content_needs"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                            {
                                title: t("consistent_and_compelling_content"),
                                text: t("by_writing_copy_you_ensure_a_consistent_tone_and_style_that_matches_your_brand_identity_while_keeping_the_content_engaging_and_relevant_to_your_readers_this_increases_the_chance_of_returning_visitors_and_loyal_customers"),
                                icon: "Icons/plus_inimhj",
                                alt: t("plus_icon")
                            },
                        ],
                        image: "Articles/texts_e904sh",
                        imagePosition: "left",
                        alt: t("an_image_depicting_the_process_of_writing_texts_with_webbeukers_possibly_featuring_writing_tools_or_a_person_typing"),
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("our_content_creation_services"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("web_content_writing"),
                                        text: t("creating_engaging_and_informative_content_for_websites_including_homepage_copy_about_us_pages_services_pages_and_more"),
                                    },
                                    {
                                        title: t("blog_post_and_article_writing"),
                                        text: t("this_includes_setting_up_managing_and_continuously_optimizing_your_advertising_campaigns_to_improve_performance_and_maximize_your_roi"),
                                    },
                                    {
                                        title: t("seo_copywriting"),
                                        text: t("writing_optimized_content_for_search_engines_with_the_correct_use_of_keywords_to_improve_your_online_visibility_and_search_engine_ranking"),
                                    },
                                    {
                                        title: t("advertising_and_marketing_copywriting"),
                                        text: t("creating_catchy_and_persuasive_copy_for_advertising_and_marketing_campaigns_including_slogans_email_campaigns_and_social_media_content"),
                                    }
                                ]
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
            ]
        },
        {
            id: 'branding-strategy',
            title: t("branding_strategy"),
            description: t('refine_your_brand_identity_with_webbeukers_branding_strategies_strengthen_your_position_in_the_market_and_improve_the_connection_with_your_target_group'),
            keywords: `${t('branding_strategy')}, ${t('brand_development')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('brand_identity')}, ${t('target_group_connection')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("branding_strategy"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "ImageBlock",
                    componentProps: {
                        title: t("masterful_brand_development_your_guide_to_effective_branding_strategies"),
                        text: t("in_todays_competitive_marketplace_a_strong_brand_identity_is_not_just_desirable_it_is_essential_our_approach_to_branding_goes_beyond_aesthetics_its_a_strategic_journey_to_tell_your_unique_story_embody_your_values_and_make_an_indelible_impression_on_your_target_audience_at_masterful_brand_development_we_focus_on_creating_a_coherent_and_resonant_brand_experience_that_builds_real_connections_and_drives_customer_loyalty"),
                        image: "Articles/branding_vupmxj",
                        alt: t("a_workplace_with_a_digital_branding_strategy_presentation_on_a_tablet_and_associated_design_notes_and_color_swatches"),
                        imagePosition: "right",
                        cols: {
                            left: 'w-full md:w-1/2',
                            right: 'w-full md:w-1/2'
                        },
                        imageWidth: "w-full"
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("our_branding_services"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("brand_development__positioning"),
                                        text: t("defining_your_brand_personality_tone_and_the_unique_value_proposition_that_sets_you_apart"),
                                    },
                                    {
                                        title: t("visual_identity_and_design"),
                                        text: t("creating_a_visual_language_that_reflects_your_brand_story_including_logos_color_palettes_and_typography"),
                                    },
                                    {
                                        title: t("digital_strategy"),
                                        text: t("developing_a_digital_presence_that_makes_your_brand_stand_out_from_website_design_to_social_media_content"),
                                    },
                                    {
                                        title: t("marketing_and_advertising"),
                                        text: t("effective_campaigns_that_put_your_brand_in_the_spotlight_and_make_a_lasting_impression"),
                                    },
                                ],
                                text: t("with_our_help_you_can_expect_to_build_a_brand_that_is_not_only_visible_but_also_meaningful_and_sustainable_take_the_step_towards_a_more_powerful_brand_identity_today_and_see_how_our_masterful_brand_development_transforms_your_business")
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                },
                {
                    type: "component",
                    componentKey: "Review",
                    componentProps: {
                        reviews: reviews
                    }
                }
            ]
        },
        {
            id: 'social-media',
            title: t("social_media"),
            description: t('enhance_your_social_media_visibility_with_Webbeukers_expert_marketing_strategies_for_maximum_online_impact_and_engagement'),
            keywords: `${t('social_media')}, ${t('social_media_management')}, ${t('webbeukers')}, ${t('marketing_transformation')}, ${t('customer_loyalty')}, ${t('social_networks')}`,
            pageLayout: [
                {
                    type: "hero",
                    title: t("social_media"),
                    buttons: [
                        {
                            text: t("get_in_touch"),
                            link: route("/contact", locale),
                            variant: "primary"
                        },
                        {
                            text: t("plan_a_meeting"),
                            link: route("/contact", locale, "?calendly=true"),
                            variant: "transparent"
                        }
                    ]
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("professional_content_creation_your_key_to_effective_online_communication"),
                        text: t("social_media_management_is_crucial_for_modern_businesses_but_it_comes_with_challenges_managing_social_media_accounts_requires_a_balance_between_content_creation_customer_engagement_and_data_analysis_ignoring_trends_or_customer_feedback_can_cause_missed_opportunities_and_a_negative_image_finding_the_right_balance_between_promotion_and_authentic_content_is_essential_success_is_measured_in_conversions_and_customer_engagement_in_short_social_media_management_requires_strategic_insight_and_digital_marketing_knowledge_to_thrive_in_a_competitive_market"),
                    }
                },
                {
                    type: "component-section",
                    componentKey: "NormalSection",
                    componentProps: {
                        title: t("our_social_media_management_services"),
                        children: {
                            type: "component",
                            componentKey: "ArrowCardGrid",
                            componentProps: {
                                cards: [
                                    {
                                        title: t("social_media_audit"),
                                        text: t("a_thorough_analysis_of_the_clients_current_social_media_accounts_to_identify_strengths_and_weaknesses"),
                                    },
                                    {
                                        title: t("content_creation__planning"),
                                        text: t("creating_tailored_and_relevant_content_including_images_videos_and_messages_and_planning_when_and_where_it_is_posted"),
                                    },
                                    {
                                        title: t("competitive_analysis"),
                                        text: t("analyzing_competitors_social_media_strategies_to_identify_opportunities_for_differentiation"),
                                    },
                                    {
                                        title: t("content_calendar"),
                                        text: t("developing_and_maintaining_a_detailed_content_calendar_to_ensure_consistency"),
                                    },
                                    {
                                        title: t("platform_optimization"),
                                        text: t("optimizing_individual_social_media_accounts_including_profile_descriptions_images_and_settings"),
                                    },
                                    {
                                        title: t("strategy_development"),
                                        text: t("creating_a_longterm_social_media_strategy_that_aligns_with_business_objectives_and_brand_identity"),
                                    },
                                ],
                            }
                        }
                    }
                },
                {
                    type: "component-section",
                    componentKey: "ContactSection",
                }
            ]
        },
    ];

    for (const page of marketingTransformationPages) {
        const path = marketingTransformationPaths.find(path => path.id === page.id);

        if (path) {
            page.link = path.link;
        }
    }

    const marketingCorevalues: MarketingValues[] = [
        {
            id: "seo",
            title: "seo_search_engine_optimization",
            text: "increase_your_online_findability_with_our_tailormade_seo_strategies_we_ensure_that_your_website_is_not_only_found_but_also_excels_in_the_search_results",
        },
        {
            id: "sea",
            title: "sea_search_engine_advertising",
            text: "reach_your_target_group_quickly_and_effectively_with_our_sea_solutions_we_manage_and_optimize_your_paid_search_campaigns_for_maximum_impact",
        },
        {
            id: "cro",
            title: "cro_conversion_optimization",
            text: "increase_the_effectiveness_of_your_website_our_cro_strategies_are_designed_to_convert_visitors_into_customers",
        },
        {
            id: "texts",
            title: "have_texts_written",
            text: "have_your_brand_story_told_by_our_professional_copywriters_we_create_compelling_content_that_fits_your_brand_and_attracts_your_target_group",
        },
        {
            id: "branding-strategy",
            title: "branding_and_strategy",
            text: "develop_an_unforgettable_brand_identity_with_our_creative_branding_and_strategic_planning_we_help_you_stand_out_in_a_crowded_market",
        },
        {
            id: "social-media",
            title: "social_media_management",
            text: "strengthen_your_social_media_presence_our_social_media_strategies_increase_your_visibility_and_engagement_on_platforms_that_matter_to_you",
        },
    ];

    for (const coreValue of marketingCorevalues) {
        const path = marketingTransformationPaths.find(path => path.id === coreValue.id);

        if (path) {
            coreValue.link = path.link;
        }
    }


    return {
        marketingTransformationPages,
        marketingCorevalues,
    };
}

export default getMarketingTransformationConstants;