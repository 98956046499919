'use client'

import NormalSection from "@/components/shared/NormalSection"
import { Location } from "@/lib/constants/creation"
import { divideTextShort } from "@/lib/helpers/sharedHelper"
import { Params } from "@/lib/types/shared/page.types"
import { useTranslations } from "next-intl"
import { useState } from "react"

type Props = {
    params: Params
    location: Location
}
export default function LocationInformationSection({ location, params }: Props) {
    const [isShowingShort, setIsShowingShort] = useState(true);
    
    const t = useTranslations('shared');
    const locationTextDivided = divideTextShort(t(`locations.${location.id}.information`));

    const showOriginal = () => {
        setIsShowingShort(false);
    }

    return (
        isShowingShort ? (
            <>
                <NormalSection
                    title={t("about_location", { location: location.name })}
                    text={locationTextDivided.short}
                    convertTextToLink={true}
                    params={params}
                >
                    {locationTextDivided.isAlreadyShort ? null : (
                        <button onClick={showOriginal} className=" underline">{t('read_more')}</button>
                    )}
                </NormalSection>
            </>
        ): (
            <NormalSection
                title={t("about_location", { location: location.name })}
                text={locationTextDivided.original}
                convertTextToLink={true}
                params={params}
            />
        )
    )
}
