import { Path } from "@/lib/types/shared/page.types";
import { ExtendedPathnames, locales } from "@/lib/navigationFunctions";
import { capitalize } from "@/lib/utils";

// Locations
// ===============================================================================================================================================================================
export interface Location {
    name: string;
    description: string;
    id?: string;
}

export const allLocationsNetherlands: Location[] = [ // alle gemeentes in Nederland
    {
        "name": "Aa en Hunze",
        "description": "(gemeente)",
        "id": "aa-en-hunze"
    },
    {
        "name": "Aalsmeer",
        "description": "(gemeente)",
        "id": "aalsmeer"
    },
    {
        "name": "Aalten",
        "description": "(gemeente)",
        "id": "aalten"
    },
    {
        "name": "Achtkarspelen",
        "description": "(gemeente)",
        "id": "achtkarspelen"
    },
    {
        "name": "Alblasserdam",
        "description": "(gemeente)",
        "id": "alblasserdam"
    },
    {
        "name": "Albrandswaard",
        "description": "(gemeente)",
        "id": "albrandswaard"
    },
    {
        "name": "Alkmaar",
        "description": "(gemeente)",
        "id": "alkmaar"
    },
    {
        "name": "Almelo",
        "description": "(gemeente)",
        "id": "almelo"
    },
    {
        "name": "Almere",
        "description": "(gemeente)",
        "id": "almere"
    },
    {
        "name": "Alphen aan den Rijn",
        "description": "(gemeente)",
        "id": "alphen-aan-den-rijn"
    },
    {
        "name": "Alphen-Chaam",
        "description": "(gemeente)",
        "id": "alphen-chaam"
    },
    {
        "name": "Altena",
        "description": "(gemeente)",
        "id": "altena"
    },
    {
        "name": "Ameland",
        "description": "(gemeente)",
        "id": "ameland"
    },
    {
        "name": "Amersfoort",
        "description": "(gemeente)",
        "id": "amersfoort"
    },
    {
        "name": "Amstelveen",
        "description": "(gemeente)",
        "id": "amstelveen"
    },
    {
        "name": "Amsterdam",
        "description": "(gemeente)",
        "id": "amsterdam"
    },
    {
        "name": "Apeldoorn",
        "description": "(gemeente)",
        "id": "apeldoorn"
    },
    {
        "name": "Arnhem",
        "description": "(gemeente)",
        "id": "arnhem"
    },
    {
        "name": "Assen",
        "description": "(gemeente)",
        "id": "assen"
    },
    {
        "name": "Asten",
        "description": "(gemeente)",
        "id": "asten"
    },
    {
        "name": "Baarle-Nassau",
        "description": "(gemeente)",
        "id": "baarle-nassau"
    },
    {
        "name": "Baarn",
        "description": "(gemeente)",
        "id": "baarn"
    },
    {
        "name": "Barendrecht",
        "description": "(gemeente)",
        "id": "barendrecht"
    },
    {
        "name": "Barneveld",
        "description": "(gemeente)",
        "id": "barneveld"
    },
    {
        "name": "Beek",
        "description": "(gemeente)",
        "id": "beek"
    },
    {
        "name": "Beekdaelen",
        "description": "(gemeente)",
        "id": "beekdaelen"
    },
    {
        "name": "Beesel",
        "description": "(gemeente)",
        "id": "beesel"
    },
    {
        "name": "Berg en Dal",
        "description": "(gemeente)",
        "id": "berg-en-dal"
    },
    {
        "name": "Bergeijk",
        "description": "(gemeente)",
        "id": "bergeijk"
    },
    {
        "name": "Bergen (L)",
        "description": "(gemeente)",
        "id": "bergen-l"
    },
    {
        "name": "Bergen NH",
        "description": "(gemeente)",
        "id": "bergen-nh"
    },
    {
        "name": "Bergen op Zoom",
        "description": "(gemeente)",
        "id": "bergen-op-zoom"
    },
    {
        "name": "Berkelland",
        "description": "(gemeente)",
        "id": "berkelland"
    },
    {
        "name": "Bernheze",
        "description": "(gemeente)",
        "id": "bernheze"
    },
    {
        "name": "Best",
        "description": "(gemeente)",
        "id": "best"
    },
    {
        "name": "Beuningen",
        "description": "(gemeente)",
        "id": "beuningen"
    },
    {
        "name": "Beverwijk",
        "description": "(gemeente)",
        "id": "beverwijk"
    },
    {
        "name": "Bladel",
        "description": "(gemeente)",
        "id": "bladel"
    },
    {
        "name": "Blaricum",
        "description": "(gemeente)",
        "id": "blaricum"
    },
    {
        "name": "Bloemendaal",
        "description": "(gemeente)",
        "id": "bloemendaal"
    },
    {
        "name": "Bodegraven-Reeuwijk",
        "description": "(gemeente)",
        "id": "bodegraven-reeuwijk"
    },
    {
        "name": "Boekel",
        "description": "(gemeente)",
        "id": "boekel"
    },
    {
        "name": "Borger-Odoorn",
        "description": "(gemeente)",
        "id": "borger-odoorn"
    },
    {
        "name": "Borne",
        "description": "(gemeente)",
        "id": "borne"
    },
    {
        "name": "Borsele",
        "description": "(gemeente)",
        "id": "borsele"
    },
    {
        "name": "Boxtel",
        "description": "(gemeente)",
        "id": "boxtel"
    },
    {
        "name": "Breda",
        "description": "(gemeente)",
        "id": "breda"
    },
    {
        "name": "Bronckhorst",
        "description": "(gemeente)",
        "id": "bronckhorst"
    },
    {
        "name": "Brummen",
        "description": "(gemeente)",
        "id": "brummen"
    },
    {
        "name": "Brunssum",
        "description": "(gemeente)",
        "id": "brunssum"
    },
    {
        "name": "Bunnik",
        "description": "(gemeente)",
        "id": "bunnik"
    },
    {
        "name": "Bunschoten",
        "description": "(gemeente)",
        "id": "bunschoten"
    },
    {
        "name": "Buren",
        "description": "(gemeente)",
        "id": "buren"
    },
    {
        "name": "Capelle aan den IJssel",
        "description": "(gemeente)",
        "id": "capelle-aan-den-ijssel"
    },
    {
        "name": "Castricum",
        "description": "(gemeente)",
        "id": "castricum"
    },
    {
        "name": "Coevorden",
        "description": "(gemeente)",
        "id": "coevorden"
    },
    {
        "name": "Cranendonck",
        "description": "(gemeente)",
        "id": "cranendonck"
    },
    {
        "name": "Culemborg",
        "description": "(gemeente)",
        "id": "culemborg"
    },
    {
        "name": "Dalfsen",
        "description": "(gemeente)",
        "id": "dalfsen"
    },
    {
        "name": "Dantumadiel",
        "description": "(gemeente)",
        "id": "dantumadiel"
    },
    {
        "name": "De Bilt",
        "description": "(gemeente)",
        "id": "de-bilt"
    },
    {
        "name": "De Fryske Marren",
        "description": "(gemeente)",
        "id": "de-fryske-marren"
    },
    {
        "name": "De Ronde Venen",
        "description": "(gemeente)",
        "id": "de-ronde-venen"
    },
    {
        "name": "De Wolden",
        "description": "(gemeente)",
        "id": "de-wolden"
    },
    {
        "name": "Delft",
        "description": "(gemeente)",
        "id": "delft"
    },
    {
        "name": "Den Haag",
        "description": "(gemeente)",
        "id": "den-haag"
    },
    {
        "name": "Den Helder",
        "description": "(gemeente)",
        "id": "den-helder"
    },
    {
        "name": "Deurne",
        "description": "(gemeente)",
        "id": "deurne"
    },
    {
        "name": "Deventer",
        "description": "(gemeente)",
        "id": "deventer"
    },
    {
        "name": "Diemen",
        "description": "(gemeente)",
        "id": "diemen"
    },
    {
        "name": "Dijk en Waard",
        "description": "(gemeente)",
        "id": "dijk-en-waard"
    },
    {
        "name": "Dinkelland",
        "description": "(gemeente)",
        "id": "dinkelland"
    },
    {
        "name": "Doesburg",
        "description": "(gemeente)",
        "id": "doesburg"
    },
    {
        "name": "Doetinchem",
        "description": "(gemeente)",
        "id": "doetinchem"
    },
    {
        "name": "Dongen",
        "description": "(gemeente)",
        "id": "dongen"
    },
    {
        "name": "Dordrecht",
        "description": "(gemeente)",
        "id": "dordrecht"
    },
    {
        "name": "Drechterland",
        "description": "(gemeente)",
        "id": "drechterland"
    },
    {
        "name": "Drimmelen",
        "description": "(gemeente)",
        "id": "drimmelen"
    },
    {
        "name": "Dronten",
        "description": "(gemeente)",
        "id": "dronten"
    },
    {
        "name": "Druten",
        "description": "(gemeente)",
        "id": "druten"
    },
    {
        "name": "Duiven",
        "description": "(gemeente)",
        "id": "duiven"
    },
    {
        "name": "Echt-Susteren",
        "description": "(gemeente)",
        "id": "echt-susteren"
    },
    {
        "name": "Edam-Volendam",
        "description": "(gemeente)",
        "id": "edam-volendam"
    },
    {
        "name": "Ede",
        "description": "(gemeente)",
        "id": "ede"
    },
    {
        "name": "Eemnes",
        "description": "(gemeente)",
        "id": "eemnes"
    },
    {
        "name": "Eemsdelta",
        "description": "(gemeente)",
        "id": "eemsdelta"
    },
    {
        "name": "Eersel",
        "description": "(gemeente)",
        "id": "eersel"
    },
    {
        "name": "Eijsden-Margraten",
        "description": "(gemeente)",
        "id": "eijsden-margraten"
    },
    {
        "name": "Eindhoven",
        "description": "(gemeente)",
        "id": "eindhoven"
    },
    {
        "name": "Elburg",
        "description": "(gemeente)",
        "id": "elburg"
    },
    {
        "name": "Emmen",
        "description": "(gemeente)",
        "id": "emmen"
    },
    {
        "name": "Enkhuizen",
        "description": "(gemeente)",
        "id": "enkhuizen"
    },
    {
        "name": "Enschede",
        "description": "(gemeente)",
        "id": "enschede"
    },
    {
        "name": "Epe",
        "description": "(gemeente)",
        "id": "epe"
    },
    {
        "name": "Ermelo",
        "description": "(gemeente)",
        "id": "ermelo"
    },
    {
        "name": "Etten-Leur",
        "description": "(gemeente)",
        "id": "etten-leur"
    },
    {
        "name": "Geertruidenberg",
        "description": "(gemeente)",
        "id": "geertruidenberg"
    },
    {
        "name": "Geldrop-Mierlo",
        "description": "(gemeente)",
        "id": "geldrop-mierlo"
    },
    {
        "name": "Gemert-Bakel",
        "description": "(gemeente)",
        "id": "gemert-bakel"
    },
    {
        "name": "Gennep",
        "description": "(gemeente)",
        "id": "gennep"
    },
    {
        "name": "Gilze en Rijen",
        "description": "(gemeente)",
        "id": "gilze-en-rijen"
    },
    {
        "name": "Goeree-Overflakkee",
        "description": "(gemeente)",
        "id": "goeree-overflakkee"
    },
    {
        "name": "Goes",
        "description": "(gemeente)",
        "id": "goes"
    },
    {
        "name": "Goirle",
        "description": "(gemeente)",
        "id": "goirle"
    },
    {
        "name": "Gooise Meren",
        "description": "(gemeente)",
        "id": "gooise-meren"
    },
    {
        "name": "Gorinchem",
        "description": "(gemeente)",
        "id": "gorinchem"
    },
    {
        "name": "Gouda",
        "description": "(gemeente)",
        "id": "gouda"
    },
    {
        "name": "Groningen",
        "description": "(gemeente)",
        "id": "groningen"
    },
    {
        "name": "Gulpen-Wittem",
        "description": "(gemeente)",
        "id": "gulpen-wittem"
    },
    {
        "name": "Haaksbergen",
        "description": "(gemeente)",
        "id": "haaksbergen"
    },
    {
        "name": "Haarlem",
        "description": "(gemeente)",
        "id": "haarlem"
    },
    {
        "name": "Haarlemmermeer",
        "description": "(gemeente)",
        "id": "haarlemmermeer"
    },
    {
        "name": "Halderberge",
        "description": "(gemeente)",
        "id": "halderberge"
    },
    {
        "name": "Hardenberg",
        "description": "(gemeente)",
        "id": "hardenberg"
    },
    {
        "name": "Harderwijk",
        "description": "(gemeente)",
        "id": "harderwijk"
    },
    {
        "name": "Hardinxveld-Giessendam",
        "description": "(gemeente)",
        "id": "hardinxveld-giessendam"
    },
    {
        "name": "Harlingen",
        "description": "(gemeente)",
        "id": "harlingen"
    },
    {
        "name": "Hattem",
        "description": "(gemeente)",
        "id": "hattem"
    },
    {
        "name": "Heemskerk",
        "description": "(gemeente)",
        "id": "heemskerk"
    },
    {
        "name": "Heemstede",
        "description": "(gemeente)",
        "id": "heemstede"
    },
    {
        "name": "Heerde",
        "description": "(gemeente)",
        "id": "heerde"
    },
    {
        "name": "Heerenveen",
        "description": "(gemeente)",
        "id": "heerenveen"
    },
    {
        "name": "Heerlen",
        "description": "(gemeente)",
        "id": "heerlen"
    },
    {
        "name": "Heeze-Leende",
        "description": "(gemeente)",
        "id": "heeze-leende"
    },
    {
        "name": "Heiloo",
        "description": "(gemeente)",
        "id": "heiloo"
    },
    {
        "name": "Hellendoorn",
        "description": "(gemeente)",
        "id": "hellendoorn"
    },
    {
        "name": "Helmond",
        "description": "(gemeente)",
        "id": "helmond"
    },
    {
        "name": "Hendrik-Ido-Ambacht",
        "description": "(gemeente)",
        "id": "hendrik-ido-ambacht"
    },
    {
        "name": "Hengelo",
        "description": "(gemeente)",
        "id": "hengelo"
    },
    {
        "name": "'s-Hertogenbosch",
        "description": "(gemeente)",
        "id": "s-hertogenbosch"
    },
    {
        "name": "Het Hogeland",
        "description": "(gemeente)",
        "id": "het-hogeland"
    },
    {
        "name": "Heumen",
        "description": "(gemeente)",
        "id": "heumen"
    },
    {
        "name": "Heusden",
        "description": "(gemeente)",
        "id": "heusden"
    },
    {
        "name": "Hillegom",
        "description": "(gemeente)",
        "id": "hillegom"
    },
    {
        "name": "Hilvarenbeek",
        "description": "(gemeente)",
        "id": "hilvarenbeek"
    },
    {
        "name": "Hilversum",
        "description": "(gemeente)",
        "id": "hilversum"
    },
    {
        "name": "Hoeksche Waard",
        "description": "(gemeente)",
        "id": "hoeksche-waard"
    },
    {
        "name": "Hof van Twente",
        "description": "(gemeente)",
        "id": "hof-van-twente"
    },
    {
        "name": "Hollands Kroon",
        "description": "(gemeente)",
        "id": "hollands-kroon"
    },
    {
        "name": "Hoogeveen",
        "description": "(gemeente)",
        "id": "hoogeveen"
    },
    {
        "name": "Hoorn",
        "description": "(gemeente)",
        "id": "hoorn"
    },
    {
        "name": "Horst aan de Maas",
        "description": "(gemeente)",
        "id": "horst-aan-de-maas"
    },
    {
        "name": "Houten",
        "description": "(gemeente)",
        "id": "houten"
    },
    {
        "name": "Huizen",
        "description": "(gemeente)",
        "id": "huizen"
    },
    {
        "name": "Hulst",
        "description": "(gemeente)",
        "id": "hulst"
    },
    {
        "name": "IJsselstein",
        "description": "(gemeente)",
        "id": "ijsselstein"
    },
    {
        "name": "Kaag en Braassem",
        "description": "(gemeente)",
        "id": "kaag-en-braassem"
    },
    {
        "name": "Kampen",
        "description": "(gemeente)",
        "id": "kampen"
    },
    {
        "name": "Kapelle",
        "description": "(gemeente)",
        "id": "kapelle"
    },
    {
        "name": "Katwijk",
        "description": "(gemeente)",
        "id": "katwijk"
    },
    {
        "name": "Kerkrade",
        "description": "(gemeente)",
        "id": "kerkrade"
    },
    {
        "name": "Koggenland",
        "description": "(gemeente)",
        "id": "koggenland"
    },
    {
        "name": "Krimpen aan den IJssel",
        "description": "(gemeente)",
        "id": "krimpen-aan-den-ijssel"
    },
    {
        "name": "Krimpenerwaard",
        "description": "(gemeente)",
        "id": "krimpenerwaard"
    },
    {
        "name": "Laarbeek",
        "description": "(gemeente)",
        "id": "laarbeek"
    },
    {
        "name": "Land van Cuijk",
        "description": "(gemeente)",
        "id": "land-van-cuijk"
    },
    {
        "name": "Landgraaf",
        "description": "(gemeente)",
        "id": "landgraaf"
    },
    {
        "name": "Landsmeer",
        "description": "(gemeente)",
        "id": "landsmeer"
    },
    {
        "name": "Lansingerland",
        "description": "(gemeente)",
        "id": "lansingerland"
    },
    {
        "name": "Laren",
        "description": "(gemeente)",
        "id": "laren"
    },
    {
        "name": "Leeuwarden",
        "description": "(gemeente)",
        "id": "leeuwarden"
    },
    {
        "name": "Leiden",
        "description": "(gemeente)",
        "id": "leiden"
    },
    {
        "name": "Leiderdorp",
        "description": "(gemeente)",
        "id": "leiderdorp"
    },
    {
        "name": "Leidschendam-Voorburg",
        "description": "(gemeente)",
        "id": "leidschendam-voorburg"
    },
    {
        "name": "Lelystad",
        "description": "(gemeente)",
        "id": "lelystad"
    },
    {
        "name": "Leudal",
        "description": "(gemeente)",
        "id": "leudal"
    },
    {
        "name": "Leusden",
        "description": "(gemeente)",
        "id": "leusden"
    },
    {
        "name": "Lingewaard",
        "description": "(gemeente)",
        "id": "lingewaard"
    },
    {
        "name": "Lisse",
        "description": "(gemeente)",
        "id": "lisse"
    },
    {
        "name": "Lochem",
        "description": "(gemeente)",
        "id": "lochem"
    },
    {
        "name": "Loon op Zand",
        "description": "(gemeente)",
        "id": "loon-op-zand"
    },
    {
        "name": "Lopik",
        "description": "(gemeente)",
        "id": "lopik"
    },
    {
        "name": "Losser",
        "description": "(gemeente)",
        "id": "losser"
    },
    {
        "name": "Maasdriel",
        "description": "(gemeente)",
        "id": "maasdriel"
    },
    {
        "name": "Maasgouw",
        "description": "(gemeente)",
        "id": "maasgouw"
    },
    {
        "name": "Maashorst",
        "description": "(gemeente)",
        "id": "maashorst"
    },
    {
        "name": "Maassluis",
        "description": "(gemeente)",
        "id": "maassluis"
    },
    {
        "name": "Maastricht",
        "description": "(gemeente)",
        "id": "maastricht"
    },
    {
        "name": "Medemblik",
        "description": "(gemeente)",
        "id": "medemblik"
    },
    {
        "name": "Meerssen",
        "description": "(gemeente)",
        "id": "meerssen"
    },
    {
        "name": "Meierijstad",
        "description": "(gemeente)",
        "id": "meierijstad"
    },
    {
        "name": "Meppel",
        "description": "(gemeente)",
        "id": "meppel"
    },
    {
        "name": "Middelburg",
        "description": "(gemeente)",
        "id": "middelburg"
    },
    {
        "name": "Midden-Delfland",
        "description": "(gemeente)",
        "id": "midden-delfland"
    },
    {
        "name": "Midden-Drenthe",
        "description": "(gemeente)",
        "id": "midden-drenthe"
    },
    {
        "name": "Midden-Groningen",
        "description": "(gemeente)",
        "id": "midden-groningen"
    },
    {
        "name": "Moerdijk",
        "description": "(gemeente)",
        "id": "moerdijk"
    },
    {
        "name": "Molenlanden",
        "description": "(gemeente)",
        "id": "molenlanden"
    },
    {
        "name": "Montferland",
        "description": "(gemeente)",
        "id": "montferland"
    },
    {
        "name": "Montfoort",
        "description": "(gemeente)",
        "id": "montfoort"
    },
    {
        "name": "Mook en Middelaar",
        "description": "(gemeente)",
        "id": "mook-en-middelaar"
    },
    {
        "name": "Neder-Betuwe",
        "description": "(gemeente)",
        "id": "neder-betuwe"
    },
    {
        "name": "Nederweert",
        "description": "(gemeente)",
        "id": "nederweert"
    },
    {
        "name": "Nieuwegein",
        "description": "(gemeente)",
        "id": "nieuwegein"
    },
    {
        "name": "Nieuwkoop",
        "description": "(gemeente)",
        "id": "nieuwkoop"
    },
    {
        "name": "Nijkerk",
        "description": "(gemeente)",
        "id": "nijkerk"
    },
    {
        "name": "Nijmegen",
        "description": "(gemeente)",
        "id": "nijmegen"
    },
    {
        "name": "Nissewaard",
        "description": "(gemeente)",
        "id": "nissewaard"
    },
    {
        "name": "Noardeast-Fryslân",
        "description": "(gemeente)",
        "id": "noardeast-fryslan"
    },
    {
        "name": "Noord-Beveland",
        "description": "(gemeente)",
        "id": "noord-beveland"
    },
    {
        "name": "Noordenveld",
        "description": "(gemeente)",
        "id": "noordenveld"
    },
    {
        "name": "Noordoostpolder",
        "description": "(gemeente)",
        "id": "noordoostpolder"
    },
    {
        "name": "Noordwijk",
        "description": "(gemeente)",
        "id": "noordwijk"
    },
    {
        "name": "Nuenen, Gerwen en Nederwetten",
        "description": "(gemeente)",
        "id": "nuenen-gerwen-en-nederwetten"
    },
    {
        "name": "Nunspeet",
        "description": "(gemeente)",
        "id": "nunspeet"
    },
    {
        "name": "Oegstgeest",
        "description": "(gemeente)",
        "id": "oegstgeest"
    },
    {
        "name": "Oirschot",
        "description": "(gemeente)",
        "id": "oirschot"
    },
    {
        "name": "Oisterwijk",
        "description": "(gemeente)",
        "id": "oisterwijk"
    },
    {
        "name": "Oldambt",
        "description": "(gemeente)",
        "id": "oldambt"
    },
    {
        "name": "Oldebroek",
        "description": "(gemeente)",
        "id": "oldebroek"
    },
    {
        "name": "Oldenzaal",
        "description": "(gemeente)",
        "id": "oldenzaal"
    },
    {
        "name": "Olst-Wijhe",
        "description": "(gemeente)",
        "id": "olst-wijhe"
    },
    {
        "name": "Ommen",
        "description": "(gemeente)",
        "id": "ommen"
    },
    {
        "name": "Oost Gelre",
        "description": "(gemeente)",
        "id": "oost-gelre"
    },
    {
        "name": "Oosterhout",
        "description": "(gemeente)",
        "id": "oosterhout"
    },
    {
        "name": "Ooststellingwerf",
        "description": "(gemeente)",
        "id": "ooststellingwerf"
    },
    {
        "name": "Oostzaan",
        "description": "(gemeente)",
        "id": "oostzaan"
    },
    {
        "name": "Opmeer",
        "description": "(gemeente)",
        "id": "opmeer"
    },
    {
        "name": "Opsterland",
        "description": "(gemeente)",
        "id": "opsterland"
    },
    {
        "name": "Oss",
        "description": "(gemeente)",
        "id": "oss"
    },
    {
        "name": "Oude IJsselstreek",
        "description": "(gemeente)",
        "id": "oude-ijsselstreek"
    },
    {
        "name": "Ouder-Amstel",
        "description": "(gemeente)",
        "id": "ouder-amstel"
    },
    {
        "name": "Oudewater",
        "description": "(gemeente)",
        "id": "oudewater"
    },
    {
        "name": "Overbetuwe",
        "description": "(gemeente)",
        "id": "overbetuwe"
    },
    {
        "name": "Papendrecht",
        "description": "(gemeente)",
        "id": "papendrecht"
    },
    {
        "name": "Peel en Maas",
        "description": "(gemeente)",
        "id": "peel-en-maas"
    },
    {
        "name": "Pekela",
        "description": "(gemeente)",
        "id": "pekela"
    },
    {
        "name": "Pijnacker-Nootdorp",
        "description": "(gemeente)",
        "id": "pijnacker-nootdorp"
    },
    {
        "name": "Purmerend",
        "description": "(gemeente)",
        "id": "purmerend"
    },
    {
        "name": "Putten",
        "description": "(gemeente)",
        "id": "putten"
    },
    {
        "name": "Raalte",
        "description": "(gemeente)",
        "id": "raalte"
    },
    {
        "name": "Reimerswaal",
        "description": "(gemeente)",
        "id": "reimerswaal"
    },
    {
        "name": "Renkum",
        "description": "(gemeente)",
        "id": "renkum"
    },
    {
        "name": "Renswoude",
        "description": "(gemeente)",
        "id": "renswoude"
    },
    {
        "name": "Reusel-De Mierden",
        "description": "(gemeente)",
        "id": "reusel-de-mierden"
    },
    {
        "name": "Rheden",
        "description": "(gemeente)",
        "id": "rheden"
    },
    {
        "name": "Rhenen",
        "description": "(gemeente)",
        "id": "rhenen"
    },
    {
        "name": "Ridderkerk",
        "description": "(gemeente)",
        "id": "ridderkerk"
    },
    {
        "name": "Rijssen-Holten",
        "description": "(gemeente)",
        "id": "rijssen-holten"
    },
    {
        "name": "Rijswijk",
        "description": "(gemeente)",
        "id": "rijswijk"
    },
    {
        "name": "Roerdalen",
        "description": "(gemeente)",
        "id": "roerdalen"
    },
    {
        "name": "Roermond",
        "description": "(gemeente)",
        "id": "roermond"
    },
    {
        "name": "Roosendaal",
        "description": "(gemeente)",
        "id": "roosendaal"
    },
    {
        "name": "Rotterdam",
        "description": "(gemeente)",
        "id": "rotterdam"
    },
    {
        "name": "Rozendaal",
        "description": "(gemeente)",
        "id": "rozendaal"
    },
    {
        "name": "Rucphen",
        "description": "(gemeente)",
        "id": "rucphen"
    },
    {
        "name": "Schagen",
        "description": "(gemeente)",
        "id": "schagen"
    },
    {
        "name": "Scherpenzeel",
        "description": "(gemeente)",
        "id": "scherpenzeel"
    },
    {
        "name": "Schiedam",
        "description": "(gemeente)",
        "id": "schiedam"
    },
    {
        "name": "Schiermonnikoog",
        "description": "(gemeente)",
        "id": "schiermonnikoog"
    },
    {
        "name": "Schouwen-Duiveland",
        "description": "(gemeente)",
        "id": "schouwen-duiveland"
    },
    {
        "name": "Simpelveld",
        "description": "(gemeente)",
        "id": "simpelveld"
    },
    {
        "name": "Sint-Michielsgestel",
        "description": "(gemeente)",
        "id": "sint-michielsgestel"
    },
    {
        "name": "Sittard-Geleen",
        "description": "(gemeente)",
        "id": "sittard-geleen"
    },
    {
        "name": "Sliedrecht",
        "description": "(gemeente)",
        "id": "sliedrecht"
    },
    {
        "name": "Sluis",
        "description": "(gemeente)",
        "id": "sluis"
    },
    {
        "name": "Smallingerland",
        "description": "(gemeente)",
        "id": "smallingerland"
    },
    {
        "name": "Soest",
        "description": "(gemeente)",
        "id": "soest"
    },
    {
        "name": "Someren",
        "description": "(gemeente)",
        "id": "someren"
    },
    {
        "name": "Son en Breugel",
        "description": "(gemeente)",
        "id": "son-en-breugel"
    },
    {
        "name": "Stadskanaal",
        "description": "(gemeente)",
        "id": "stadskanaal"
    },
    {
        "name": "Staphorst",
        "description": "(gemeente)",
        "id": "staphorst"
    },
    {
        "name": "Stede Broec",
        "description": "(gemeente)",
        "id": "stede-broec"
    },
    {
        "name": "Steenbergen",
        "description": "(gemeente)",
        "id": "steenbergen"
    },
    {
        "name": "Steenwijkerland",
        "description": "(gemeente)",
        "id": "steenwijkerland"
    },
    {
        "name": "Stein",
        "description": "(gemeente)",
        "id": "stein"
    },
    {
        "name": "Stichtse Vecht",
        "description": "(gemeente)",
        "id": "stichtse-vecht"
    },
    {
        "name": "Súdwest-Fryslân",
        "description": "(gemeente)",
        "id": "sudwest-fryslan"
    },
    {
        "name": "Terneuzen",
        "description": "(gemeente)",
        "id": "terneuzen"
    },
    {
        "name": "Terschelling",
        "description": "(gemeente)",
        "id": "terschelling"
    },
    {
        "name": "Texel",
        "description": "(gemeente)",
        "id": "texel"
    },
    {
        "name": "Teylingen",
        "description": "(gemeente)",
        "id": "teylingen"
    },
    {
        "name": "Tholen",
        "description": "(gemeente)",
        "id": "tholen"
    },
    {
        "name": "Tiel",
        "description": "(gemeente)",
        "id": "tiel"
    },
    {
        "name": "Tilburg",
        "description": "(gemeente)",
        "id": "tilburg"
    },
    {
        "name": "Tubbergen",
        "description": "(gemeente)",
        "id": "tubbergen"
    },
    {
        "name": "Twenterand",
        "description": "(gemeente)",
        "id": "twenterand"
    },
    {
        "name": "Tynaarlo",
        "description": "(gemeente)",
        "id": "tynaarlo"
    },
    {
        "name": "Tytsjerksteradiel",
        "description": "(gemeente)",
        "id": "tytsjerksteradiel"
    },
    {
        "name": "Uitgeest",
        "description": "(gemeente)",
        "id": "uitgeest"
    },
    {
        "name": "Uithoorn",
        "description": "(gemeente)",
        "id": "uithoorn"
    },
    {
        "name": "Urk",
        "description": "(gemeente)",
        "id": "urk"
    },
    {
        "name": "Utrecht",
        "description": "(gemeente)",
        "id": "utrecht"
    },
    {
        "name": "Utrechtse Heuvelrug",
        "description": "(gemeente)",
        "id": "utrechtse-heuvelrug"
    },
    {
        "name": "Vaals",
        "description": "(gemeente)",
        "id": "vaals"
    },
    {
        "name": "Valkenburg aan de Geul",
        "description": "(gemeente)",
        "id": "valkenburg-aan-de-geul"
    },
    {
        "name": "Valkenswaard",
        "description": "(gemeente)",
        "id": "valkenswaard"
    },
    {
        "name": "Veendam",
        "description": "(gemeente)",
        "id": "veendam"
    },
    {
        "name": "Veenendaal",
        "description": "(gemeente)",
        "id": "veenendaal"
    },
    {
        "name": "Veere",
        "description": "(gemeente)",
        "id": "veere"
    },
    {
        "name": "Veldhoven",
        "description": "(gemeente)",
        "id": "veldhoven"
    },
    {
        "name": "Velsen",
        "description": "(gemeente)",
        "id": "velsen"
    },
    {
        "name": "Venlo",
        "description": "(gemeente)",
        "id": "venlo"
    },
    {
        "name": "Venray",
        "description": "(gemeente)",
        "id": "venray"
    },
    {
        "name": "Vijfheerenlanden",
        "description": "(gemeente)",
        "id": "vijfheerenlanden"
    },
    {
        "name": "Vlaardingen",
        "description": "(gemeente)",
        "id": "vlaardingen"
    },
    {
        "name": "Vlieland",
        "description": "(gemeente)",
        "id": "vlieland"
    },
    {
        "name": "Vlissingen",
        "description": "(gemeente)",
        "id": "vlissingen"
    },
    {
        "name": "Voerendaal",
        "description": "(gemeente)",
        "id": "voerendaal"
    },
    {
        "name": "Voorne aan Zee",
        "description": "(gemeente)",
        "id": "voorne-aan-zee"
    },
    {
        "name": "Voorschoten",
        "description": "(gemeente)",
        "id": "voorschoten"
    },
    {
        "name": "Voorst",
        "description": "(gemeente)",
        "id": "voorst"
    },
    {
        "name": "Vught",
        "description": "(gemeente)",
        "id": "vught"
    },
    {
        "name": "Waadhoeke",
        "description": "(gemeente)",
        "id": "waadhoeke"
    },
    {
        "name": "Waalre",
        "description": "(gemeente)",
        "id": "waalre"
    },
    {
        "name": "Waalwijk",
        "description": "(gemeente)",
        "id": "waalwijk"
    },
    {
        "name": "Waddinxveen",
        "description": "(gemeente)",
        "id": "waddinxveen"
    },
    {
        "name": "Wageningen",
        "description": "(gemeente)",
        "id": "wageningen"
    },
    {
        "name": "Wassenaar",
        "description": "(gemeente)",
        "id": "wassenaar"
    },
    {
        "name": "Waterland",
        "description": "(gemeente)",
        "id": "waterland"
    },
    {
        "name": "Weert",
        "description": "(gemeente)",
        "id": "weert"
    },
    {
        "name": "West Betuwe",
        "description": "(gemeente)",
        "id": "west-betuwe"
    },
    {
        "name": "West Maas en Waal",
        "description": "(gemeente)",
        "id": "west-maas-en-waal"
    },
    {
        "name": "Westerkwartier",
        "description": "(gemeente)",
        "id": "westerkwartier"
    },
    {
        "name": "Westerveld",
        "description": "(gemeente)",
        "id": "westerveld"
    },
    {
        "name": "Westervoort",
        "description": "(gemeente)",
        "id": "westervoort"
    },
    {
        "name": "Westerwolde",
        "description": "(gemeente)",
        "id": "westerwolde"
    },
    {
        "name": "Westland",
        "description": "(gemeente)",
        "id": "westland"
    },
    {
        "name": "Weststellingwerf",
        "description": "(gemeente)",
        "id": "weststellingwerf"
    },
    {
        "name": "Wierden",
        "description": "(gemeente)",
        "id": "wierden"
    },
    {
        "name": "Wijchen",
        "description": "(gemeente)",
        "id": "wijchen"
    },
    {
        "name": "Wijdemeren",
        "description": "(gemeente)",
        "id": "wijdemeren"
    },
    {
        "name": "Wijk bij Duurstede",
        "description": "(gemeente)",
        "id": "wijk-bij-duurstede"
    },
    {
        "name": "Winterswijk",
        "description": "(gemeente)",
        "id": "winterswijk"
    },
    {
        "name": "Woensdrecht",
        "description": "(gemeente)",
        "id": "woensdrecht"
    },
    {
        "name": "Woerden",
        "description": "(gemeente)",
        "id": "woerden"
    },
    {
        "name": "Wormerland",
        "description": "(gemeente)",
        "id": "wormerland"
    },
    {
        "name": "Woudenberg",
        "description": "(gemeente)",
        "id": "woudenberg"
    },
    {
        "name": "Zaanstad",
        "description": "(gemeente)",
        "id": "zaanstad"
    },
    {
        "name": "Zaltbommel",
        "description": "(gemeente)",
        "id": "zaltbommel"
    },
    {
        "name": "Zandvoort",
        "description": "(gemeente)",
        "id": "zandvoort"
    },
    {
        "name": "Zeewolde",
        "description": "(gemeente)",
        "id": "zeewolde"
    },
    {
        "name": "Zeist",
        "description": "(gemeente)",
        "id": "zeist"
    },
    {
        "name": "Zevenaar",
        "description": "(gemeente)",
        "id": "zevenaar"
    },
    {
        "name": "Zoetermeer",
        "description": "(gemeente)",
        "id": "zoetermeer"
    },
    {
        "name": "Zoeterwoude",
        "description": "(gemeente)",
        "id": "zoeterwoude"
    },
    {
        "name": "Zuidplas",
        "description": "(gemeente)",
        "id": "zuidplas"
    },
    {
        "name": "Zundert",
        "description": "(gemeente)",
        "id": "zundert"
    },
    {
        "name": "Zutphen",
        "description": "(gemeente)",
        "id": "zutphen"
    },
    {
        "name": "Zwartewaterland",
        "description": "(gemeente)",
        "id": "zwartewaterland"
    },
    {
        "name": "Zwijndrecht",
        "description": "(gemeente)",
        "id": "zwijndrecht"
    },
    {
        "name": "Zwolle",
        "description": "(gemeente)",
        "id": "zwolle"
    }
];

export const allLocationsBelgium: Location[] = [ // alle gemeentes in België
    {
        "name": "Brussel-Stad",
        "description": "(gemeente)",
        "id": "brussel-stad"
    },
    {
        "name": "Antwerpen",
        "description": "(gemeente)",
        "id": "antwerpen"
    },
    {
        "name": "Gent",
        "description": "(gemeente)",
        "id": "gent"
    },
    {
        "name": "Charleroi",
        "description": "(gemeente)",
        "id": "charleroi"
    },
    {
        "name": "Liège",
        "description": "(gemeente)",
        "id": "liege"
    },
    {
        "name": "Brugge",
        "description": "(gemeente)",
        "id": "brugge"
    },
    {
        "name": "Knokke",
        "description": "(gemeente)",
        "id": "knokke"
    },
    {
        "name": "Schaerbeek",
        "description": "(gemeente)",
        "id": "schaerbeek"
    },
    {
        "name": "Anderlecht",
        "description": "(gemeente)",
        "id": "anderlecht"
    },
    {
        "name": "Namur",
        "description": "(gemeente)",
        "id": "namur"
    },
    {
        "name": "Leuven",
        "description": "(gemeente)",
        "id": "leuven"
    },
    {
        "name": "Sint-Jans-Molenbeek",
        "description": "(gemeente)",
        "id": "sint-jans-molenbeek"
    },
    {
        "name": "Mons",
        "description": "(gemeente)",
        "id": "mons"
    },
    {
        "name": "Elsene",
        "description": "(gemeente)",
        "id": "elsene"
    },
    {
        "name": "Aalst",
        "description": "(gemeente)",
        "id": "aalst"
    },
    {
        "name": "Mechelen",
        "description": "(gemeente)",
        "id": "mechelen"
    },
    {
        "name": "Hasselt",
        "description": "(gemeente)",
        "id": "hasselt"
    },
    {
        "name": "Sint-Niklaas",
        "description": "(gemeente)",
        "id": "sint-niklaas"
    },
    {
        "name": "Kortrijk",
        "description": "(gemeente)",
        "id": "kortrijk"
    },
    {
        "name": "Ukkel",
        "description": "(gemeente)",
        "id": "ukkel"
    },
    {
        "name": "Ieper",
        "description": "(gemeente)",
        "id": "ieper"
    },
    {
        "name": "Halle",
        "description": "(gemeente)",
        "id": "halle"
    },
    {
        "name": "Genk",
        "description": "(gemeente)",
        "id": "genk"
    },
    {
        "name": "Louvain-la-Neuve",
        "description": "(gemeente)",
        "id": "louvain-la-neuve"
    },
    {
        "name": "Braine-l'Alleud",
        "description": "(gemeente)",
        "id": "braine-l-alleud"
    },
    {
        "name": "Zaventem",
        "description": "(gemeente)",
        "id": "zaventem"
    },
    {
        "name": "Wavre",
        "description": "(gemeente)",
        "id": "wavre"
    },
    {
        "name": "Roeselare",
        "description": "(gemeente)",
        "id": "roeselare"
    },
    {
        "name": "Geel",
        "description": "(gemeente)",
        "id": "geel"
    },
    {
        "name": "Tournai",
        "description": "(gemeente)",
        "id": "tournai"
    }
];

export const allLocationsGermany: Location[] = [ // alle gemeentes in Duitsland
    {
        "name": "Duisburg",
        "description": "(gemeente)",
        "id": "duisburg"
    },
    {
        "name": "Essen",
        "description": "(gemeente)",
        "id": "essen"
    },
    {
        "name": "Düsseldorf",
        "description": "(gemeente)",
        "id": "dusseldorf"
    },
    {
        "name": "Mönchengladbach",
        "description": "(gemeente)",
        "id": "monchengladbach"
    },
    {
        "name": "Krefeld",
        "description": "(gemeente)",
        "id": "krefeld"
    },
    {
        "name": "Bocholt",
        "description": "(gemeente)",
        "id": "bocholt"
    },
    {
        "name": "Kalkar",
        "description": "(gemeente)",
        "id": "kalkar"
    },
    {
        "name": "Wesel",
        "description": "(gemeente)",
        "id": "wesel"
    },
    {
        "name": "Xanten",
        "description": "(gemeente)",
        "id": "xanten"
    },
    {
        "name": "Kamp-Lintfort",
        "description": "(gemeente)",
        "id": "kamp-lintfort"
    },
    {
        "name": "Goch",
        "description": "(gemeente)",
        "id": "goch"
    },
    {
        "name": "Kevelaer",
        "description": "(gemeente)",
        "id": "kevelaer"
    },
];

export const allLocations: Location[] = [ // merge all locations together
    ...allLocationsNetherlands,
    ...allLocationsBelgium,
    ...allLocationsGermany
];
// ===============================================================================================================================================================================

// Types
// ===============================================================================================================================================================================
export interface Type {
    name: string;
    description: string;
    link?: Path;
    inflected?: boolean;
    id: string;
    options: OptionIds[];
}
export const allTypes: Type[] = [
    {
        name: "wordpress",
        description: "WordPress website",
        link: {
            en: "wordpress",
            nl: "wordpress"
        },
        id: "wordpress",
        options: [
            "website",
            "web-app",
            "portal",
            "plugin"
        ]
    },
    {
        name: "cheap_inflected",
        description: "Goedkope",
        link: {
            en: "cheap",
            nl: "goedkope"
        },
        id: "cheapInflected",
        inflected: true,
        options: [
            "website"
        ]
    },
    {
        name: "cheap",
        description: "Goedkoop",
        link: {
            en: "cheap",
            nl: "goedkoop"
        },
        id: "cheap",
        inflected: false,
        options: []
    },
    {
        name: "custom",
        description: "Maatwerk website",
        link: {
            en: "custom",
            nl: "maatwerk"
        },
        id: "custom",
        options: [
            "website",
            "web-app",
            "platform",
            "portal"
        ]
    },
    {
        name: "professional",
        description: "Professioneel",
        link: {
            en: "professional",
            nl: "professioneel"
        },
        id: "professional",
        inflected: false,
        options: [
            "portal"
        ]
    },
    {
        name: "professional_inflected",
        description: "Professionele",
        link: {
            en: "professional",
            nl: "professionele"
        },
        id: "professionalInflected",
        inflected: true,
        options: [
            "website",
            "web-app"
        ]
    },
    {
        name: "laravel",
        description: "Laravel website",
        link: {
            en: "laravel",
            nl: "laravel"
        },
        id: "laravel",
        options: [
            "website",
            "web-app",
            "portal"
        ]
    },
    {
        name: "react_js",
        description: "ReactJS website",
        link: {
            en: "react-js",
            nl: "react-js"
        },
        id: "react-js",
        options: [
            "website",
            "web-app",
            "portal"
        ]
    },
    {
        name: "php",
        description: "PHP website",
        link: {
            en: "php",
            nl: "php"
        },
        id: "php",
        options: [
            "website",
            "web-app",
            "portal"
        ]
    },
    {
        name: "vue",
        description: "Vue website",
        link: {
            en: "vue",
            nl: "vue"
        },
        id: "vue",
        options: [
            "website",
            "web-app",
            "portal"
        ]
    },
    {
        name: "wcag",
        description: "WCAG website",
        link: {
            en: "wcag",
            nl: "wcag"
        },
        id: "wcag",
        options: [
            "website",
            "web-app",
            "portal"
        ]
    },
];
// ===============================================================================================================================================================================

// Options
// ===============================================================================================================================================================================
export interface Option {
    name: string;
    description: string;
    id: string;
}
export type OptionIds = "website" | "web-app" | "platform" | "portal" | "plugin";
export const allOptions: Option[] = [
    {
        name: "website",
        description: "Website",
        id: "website"
    },
    {
        name: "web-app",
        description: "Web app",
        id: "web-app"
    },
    {
        name: "platform",
        description: "Platform",
        id: "platform"
    },
    {
        name: "portal",
        description: "Portal",
        id: "portal"
    },
    {
        name: "plugin",
        description: "Plugin",
        id: "plugin"
    }
];
// ===============================================================================================================================================================================















// Pathnames
// ===============================================================================================================================================================================

// Options:
    const allTypesLength = allTypes.length;
    const allLocationsLength = allLocations.length;

    const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    const allLocationsBelgiumLength = allLocationsBelgium.length;
    const allLocationsGermanyLength = allLocationsGermany.length;


    // - website
    export const dynamicWebsiteTypesPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebsiteLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebsiteTypesNotFilledLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebsiteTypesLocationsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allTypesLength = allTypes.length;
    // const allLocationsLength = allLocations.length;
    for (let j = 0; j < allTypesLength; j++) {
        if (!allTypes[j].options.includes('website')) continue;

        const websiteTypeValue = allTypes[j];

        const typePage = {
            en: `/${websiteTypeValue.link?.en}-website-creation`,
            nl: `/${websiteTypeValue.link?.nl}-website-laten-maken`,
            name: websiteTypeValue.name,
            type: websiteTypeValue.name,
            breadcrumbParentKey: "/website-creation",
        }
        dynamicWebsiteTypesPathnames[`/${websiteTypeValue.link?.en}-website-creation`] = typePage
        
        for (let i = 0; i < allLocationsLength; i++) {
            const locationValue = allLocations[i];
            const locationKeyLower = locationValue.id;
            const newPage = {
                en: `/${websiteTypeValue.link?.en}-website-creation-${locationKeyLower}`,
                nl: `/${websiteTypeValue.link?.nl}-website-laten-maken-${locationKeyLower}`,
                name: locationValue.name,
                combinedName: `{type}-{option}-creation-{location}`,
                type: websiteTypeValue.name,
                option: 'website',
                location: locationValue.name,
                inflected: websiteTypeValue.inflected || false,
                breadcrumbTranslate: false,
                breadcrumbParentKey: `/${websiteTypeValue.link?.en}-website-creation`,
            }
            dynamicWebsiteTypesLocationsPathnames[`/${websiteTypeValue.link?.en}-website-creation-${locationKeyLower}`] = newPage
        }
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-website-creation-${locationKeyLower}`,
            nl: `/{type}-website-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebsiteTypesNotFilledLocationsPathnames[`/{type}-website-creation-${locationKeyLower}`] = locationPage
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/website-creation-${locationKeyLower}`,
            nl: `/website-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
            breadcrumbTranslate: false,
            breadcrumbParentKey: `/website-creation`,
        }
        dynamicWebsiteLocationsPathnames[`/website-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebsiteLocationsNetherlandsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    for (let i = 0; i < allLocationsNetherlandsLength; i++) {
        const locationValue = allLocationsNetherlands[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-website-creation-${locationKeyLower}`,
            nl: `/{type}-website-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebsiteLocationsNetherlandsPathnames[`/{type}-website-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebsiteLocationsBelgiumPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsBelgiumLength = allLocationsBelgium.length;
    for (let i = 0; i < allLocationsBelgiumLength; i++) {
        const locationValue = allLocationsBelgium[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-website-creation-${locationKeyLower}`,
            nl: `/{type}-website-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebsiteLocationsBelgiumPathnames[`/{type}-website-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebsiteLocationsGermanyPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsGermanyLength = allLocationsGermany.length;
    for (let i = 0; i < allLocationsGermanyLength; i++) {
        const locationValue = allLocationsGermany[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-website-creation-${locationKeyLower}`,
            nl: `/{type}-website-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebsiteLocationsGermanyPathnames[`/{type}-website-creation-${locationKeyLower}`] = locationPage
    }



    // - web-app
    export const dynamicWebappTypesPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebappLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebappTypesNotFilledLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicWebappTypesLocationsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allTypesLength = allTypes.length;
    // const allLocationsLength = allLocations.length;
    for (let j = 0; j < allTypesLength; j++) {
        if (!allTypes[j].options.includes('web-app')) continue;

        const webappTypeValue = allTypes[j];

        const typePage = {
            en: `/${webappTypeValue.link?.en}-web-app-creation`,
            nl: `/${webappTypeValue.link?.nl}-web-app-laten-maken`,
            name: webappTypeValue.name,
            type: webappTypeValue.name,
            breadcrumbParentKey: "/web-app-creation",
        }
        dynamicWebappTypesPathnames[`/${webappTypeValue.link?.en}-web-app-creation`] = typePage

        for (let i = 0; i < allLocationsLength; i++) {
            const locationValue = allLocations[i];
            const locationKeyLower = locationValue.id;
            const newPage = {
                en: `/${webappTypeValue.link?.en}-web-app-creation-${locationKeyLower}`,
                nl: `/${webappTypeValue.link?.nl}-web-app-laten-maken-${locationKeyLower}`,
                name: locationValue.name,
                combinedName: `{type}-{option}-creation-{location}`,
                type: webappTypeValue.name,
                option: 'web-app',
                location: locationValue.name,
                breadcrumbTranslate: false,
                breadcrumbParentKey: `/${webappTypeValue.link?.en}-web-app-creation`,
            }
            dynamicWebappTypesLocationsPathnames[`/${webappTypeValue.link?.en}-web-app-creation-${locationKeyLower}`] = newPage
        }
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-web-app-creation-${locationKeyLower}`,
            nl: `/{type}-web-app-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebappTypesNotFilledLocationsPathnames[`/{type}-web-app-creation-${locationKeyLower}`] = locationPage
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/web-app-creation-${locationKeyLower}`,
            nl: `/web-app-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
            breadcrumbTranslate: false,
            breadcrumbParentKey: `/web-app-creation`,
        }
        dynamicWebappLocationsPathnames[`/web-app-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebappLocationsNetherlandsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    for (let i = 0; i < allLocationsNetherlandsLength; i++) {
        const locationValue = allLocationsNetherlands[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-web-app-creation-${locationKeyLower}`,
            nl: `/{type}-web-app-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebappLocationsNetherlandsPathnames[`/{type}-web-app-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebappLocationsBelgiumPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsBelgiumLength = allLocationsBelgium.length;
    for (let i = 0; i < allLocationsBelgiumLength; i++) {
        const locationValue = allLocationsBelgium[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-web-app-creation-${locationKeyLower}`,
            nl: `/{type}-web-app-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebappLocationsBelgiumPathnames[`/{type}-web-app-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicWebappLocationsGermanyPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsGermanyLength = allLocationsGermany.length;
    for (let i = 0; i < allLocationsGermanyLength; i++) {
        const locationValue = allLocationsGermany[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-web-app-creation-${locationKeyLower}`,
            nl: `/{type}-web-app-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicWebappLocationsGermanyPathnames[`/{type}-web-app-creation-${locationKeyLower}`] = locationPage
    }


    // - platform
    export const dynamicPlatformTypesPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPlatformLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPlatformTypesNotFilledLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPlatformTypesLocationsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allTypesLength = allTypes.length;
    // const allLocationsLength = allLocations.length;
    for (let j = 0; j < allTypesLength; j++) {
        if (!allTypes[j].options.includes('platform')) continue;

        const platformTypeValue = allTypes[j];

        const typePage = {
            en: `/${platformTypeValue.link?.en}-platform-creation`,
            nl: `/${platformTypeValue.link?.nl}-platform-laten-maken`,
            name: platformTypeValue.name,
            type: platformTypeValue.name,
            breadcrumbParentKey: "/platform-creation",
        }
        dynamicPlatformTypesPathnames[`/${platformTypeValue.link?.en}-platform-creation`] = typePage

        for (let i = 0; i < allLocationsLength; i++) {
            const locationValue = allLocations[i];
            const locationKeyLower = locationValue.id;
            const newPage = {
                en: `/${platformTypeValue.link?.en}-platform-creation-${locationKeyLower}`,
                nl: `/${platformTypeValue.link?.nl}-platform-laten-maken-${locationKeyLower}`,
                name: locationValue.name,
                combinedName: `{type}-{option}-creation-{location}`,
                type: platformTypeValue.name,
                option: 'platform',
                location: locationValue.name,
                breadcrumbTranslate: false,
                breadcrumbParentKey: `/${platformTypeValue.link?.en}-platform-creation`,
            }
            dynamicPlatformTypesLocationsPathnames[`/${platformTypeValue.link?.en}-platform-creation-${locationKeyLower}`] = newPage
        }
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-platform-creation-${locationKeyLower}`,
            nl: `/{type}-platform-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPlatformTypesNotFilledLocationsPathnames[`/{type}-platform-creation-${locationKeyLower}`] = locationPage
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/platform-creation-${locationKeyLower}`,
            nl: `/platform-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
            breadcrumbTranslate: false,
            breadcrumbParentKey: `/platform-creation`,
        }
        dynamicPlatformLocationsPathnames[`/platform-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPlatformLocationsNetherlandsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    for (let i = 0; i < allLocationsNetherlandsLength; i++) {
        const locationValue = allLocationsNetherlands[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-platform-creation-${locationKeyLower}`,
            nl: `/{type}-platform-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPlatformLocationsNetherlandsPathnames[`/{type}-platform-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPlatformLocationsBelgiumPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsBelgiumLength = allLocationsBelgium.length;
    for (let i = 0; i < allLocationsBelgiumLength; i++) {
        const locationValue = allLocationsBelgium[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-platform-creation-${locationKeyLower}`,
            nl: `/{type}-platform-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPlatformLocationsBelgiumPathnames[`/{type}-platform-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPlatformLocationsGermanyPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsGermanyLength = allLocationsGermany.length;
    for (let i = 0; i < allLocationsGermanyLength; i++) {
        const locationValue = allLocationsGermany[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-platform-creation-${locationKeyLower}`,
            nl: `/{type}-platform-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPlatformLocationsGermanyPathnames[`/{type}-platform-creation-${locationKeyLower}`] = locationPage
    }


    // - portal
    export const dynamicPortalTypesPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPortalLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPortalTypesNotFilledLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPortalTypesLocationsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allTypesLength = allTypes.length;
    // const allLocationsLength = allLocations.length;
    for (let j = 0; j < allTypesLength; j++) {
        if (!allTypes[j].options.includes('portal')) continue;

        const portalTypeValue = allTypes[j];

        const typePage = {
            en: `/${portalTypeValue.link?.en}-portal-creation`,
            nl: `/${portalTypeValue.link?.nl}-portaal-laten-maken`,
            name: portalTypeValue.name,
            type: portalTypeValue.name,
            breadcrumbParentKey: "/portal-creation",
        }
        dynamicPortalTypesPathnames[`/${portalTypeValue.link?.en}-portal-creation`] = typePage

        for (let i = 0; i < allLocationsLength; i++) {
            const locationValue = allLocations[i];
            const locationKeyLower = locationValue.id;
            const newPage = {
                en: `/${portalTypeValue.link?.en}-portal-creation-${locationKeyLower}`,
                nl: `/${portalTypeValue.link?.nl}-portaal-laten-maken-${locationKeyLower}`,
                name: locationValue.name,
                combinedName: `{type}-{option}-creation-{location}`,
                type: portalTypeValue.name,
                option: 'portal',
                location: locationValue.name,
                breadcrumbTranslate: false,
                breadcrumbParentKey: `/${portalTypeValue.link?.en}-portal-creation`,
            }
            dynamicPortalTypesLocationsPathnames[`/${portalTypeValue.link?.en}-portal-creation-${locationKeyLower}`] = newPage
        }
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-portal-creation-${locationKeyLower}`,
            nl: `/{type}-portaal-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPortalTypesNotFilledLocationsPathnames[`/{type}-portal-creation-${locationKeyLower}`] = locationPage
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/portal-creation-${locationKeyLower}`,
            nl: `/portaal-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
            breadcrumbTranslate: false,
            breadcrumbParentKey: `/portal-creation`,
        }
        dynamicPortalLocationsPathnames[`/portal-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPortalLocationsNetherlandsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    for (let i = 0; i < allLocationsNetherlandsLength; i++) {
        const locationValue = allLocationsNetherlands[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-portal-creation-${locationKeyLower}`,
            nl: `/{type}-portaal-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPortalLocationsNetherlandsPathnames[`/{type}-portal-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPortalLocationsBelgiumPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsBelgiumLength = allLocationsBelgium.length;
    for (let i = 0; i < allLocationsBelgiumLength; i++) {
        const locationValue = allLocationsBelgium[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-portal-creation-${locationKeyLower}`,
            nl: `/{type}-portaal-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPortalLocationsBelgiumPathnames[`/{type}-portal-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPortalLocationsGermanyPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsGermanyLength = allLocationsGermany.length;
    for (let i = 0; i < allLocationsGermanyLength; i++) {
        const locationValue = allLocationsGermany[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-portal-creation-${locationKeyLower}`,
            nl: `/{type}-portaal-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPortalLocationsGermanyPathnames[`/{type}-portal-creation-${locationKeyLower}`] = locationPage
    }


    // - plugin
    export const dynamicPluginTypesPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPluginLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPluginTypesNotFilledLocationsPathnames = {} as ExtendedPathnames<typeof locales>;
    export const dynamicPluginTypesLocationsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allTypesLength = allTypes.length;
    // const allLocationsLength = allLocations.length;
    for (let j = 0; j < allTypesLength; j++) {
        if (!allTypes[j].options.includes('plugin')) continue;

        const pluginTypeValue = allTypes[j];

        const typePage = {
            en: `/${pluginTypeValue.link?.en}-plugin-creation`,
            nl: `/${pluginTypeValue.link?.nl}-plugin-laten-maken`,
            name: pluginTypeValue.name,
            type: pluginTypeValue.name,
            breadcrumbParentKey: "/plugin-creation",
        }
        dynamicPluginTypesPathnames[`/${pluginTypeValue.link?.en}-plugin-creation`] = typePage

        for (let i = 0; i < allLocationsLength; i++) {
            const locationValue = allLocations[i];
            const locationKeyLower = locationValue.id;
            const newPage = {
                en: `/${pluginTypeValue.link?.en}-plugin-creation-${locationKeyLower}`,
                nl: `/${pluginTypeValue.link?.nl}-plugin-laten-maken-${locationKeyLower}`,
                name: locationValue.name,
                combinedName: `{type}-{option}-creation-{location}`,
                type: pluginTypeValue.name,
                option: 'plugin',
                location: locationValue.name,
                breadcrumbTranslate: false,
                breadcrumbParentKey: `/${pluginTypeValue.link?.en}-plugin-creation`,
            }
            dynamicPluginTypesLocationsPathnames[`/${pluginTypeValue.link?.en}-plugin-creation-${locationKeyLower}`] = newPage
        }
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-plugin-creation-${locationKeyLower}`,
            nl: `/{type}-plugin-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPluginTypesNotFilledLocationsPathnames[`/{type}-plugin-creation-${locationKeyLower}`] = locationPage
    }

    for (let i = 0; i < allLocationsLength; i++) {
        const locationValue = allLocations[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/plugin-creation-${locationKeyLower}`,
            nl: `/plugin-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
            breadcrumbTranslate: false,
            breadcrumbParentKey: `/plugin-creation`,
        }
        dynamicPluginLocationsPathnames[`/plugin-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPluginLocationsNetherlandsPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsNetherlandsLength = allLocationsNetherlands.length;
    for (let i = 0; i < allLocationsNetherlandsLength; i++) {
        const locationValue = allLocationsNetherlands[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-plugin-creation-${locationKeyLower}`,
            nl: `/{type}-plugin-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPluginLocationsNetherlandsPathnames[`/{type}-plugin-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPluginLocationsBelgiumPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsBelgiumLength = allLocationsBelgium.length;
    for (let i = 0; i < allLocationsBelgiumLength; i++) {
        const locationValue = allLocationsBelgium[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-plugin-creation-${locationKeyLower}`,
            nl: `/{type}-plugin-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPluginLocationsBelgiumPathnames[`/{type}-plugin-creation-${locationKeyLower}`] = locationPage
    }

    export const dynamicPluginLocationsGermanyPathnames = {} as ExtendedPathnames<typeof locales>;

    // const allLocationsGermanyLength = allLocationsGermany.length;
    for (let i = 0; i < allLocationsGermanyLength; i++) {
        const locationValue = allLocationsGermany[i];
        const locationKeyLower = locationValue.id;
        const locationPage = {
            en: `/{type}-plugin-creation-${locationKeyLower}`,
            nl: `/{type}-plugin-laten-maken-${locationKeyLower}`,
            name: capitalize(locationValue.name),
        }
        dynamicPluginLocationsGermanyPathnames[`/{type}-plugin-creation-${locationKeyLower}`] = locationPage
    }


    // - all
    // merge all dynamicPluginTypesLocationsPathnames together
    export const dynamicAllTypesLocationsPathnames = {
        ...dynamicWebsiteTypesLocationsPathnames,
        ...dynamicWebappTypesLocationsPathnames,
        // ...dynamicPlatformTypesLocationsPathnames,
        // ...dynamicPortalTypesLocationsPathnames,
        // ...dynamicPluginTypesLocationsPathnames
    } as ExtendedPathnames<typeof locales>;
// ===============================================================================================================================================================================