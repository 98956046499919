'use client';

import React, { useState } from 'react';

import Image from '@/components/shared/Image';

type Props = {
    images : Array<any>,
    activeImageWidth? : string,
    className? : string,
}

const ImageCarousel = ({ images, activeImageWidth, className }: Props) => {
    const [active, setActive] = useState(0);

    return (
        <div className={`${className} relative`}>
            <Image
                width={530}
                height={365}
                loading="lazy"
                src={images[active].src}
                alt={images[active].alt}
                className={`${activeImageWidth ? activeImageWidth : 'w-4/5'} h-auto rounded-xl ${images[active].className ? images[active].className : ''}`}
            />

            <div className='w-full flex absolute gap-3 px-6 -translate-y-1/2 max-w-full overflow-x-auto no-scrollbar'>
                {images.map((image, index) => (
                    index !== active && (
                        <Image
                            key={index}
                            width={120.5}
                            height={83}
                            src={image.src}
                            alt={image.alt}
                            className={`small-image w-1/4 h-auto rounded-xl ${image.className ? image.className : ''} cursor-pointer`}
                            onClick={() => setActive(index)}
                        />
                    )
                ))}
            </div>
        </div>
    );
}

export default ImageCarousel;