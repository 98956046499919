import { useEffect, useState } from "react";
import { decryptData, encryptData } from "@/lib/helpers/EncryptionHelper";
export default function useEncryptedLocalStorage<T>(
	key: string,
	initialValue: T
): [
		T,
		(value: T, from: string ) => void,
		boolean,
		boolean,
		() => void
	] {
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [usingInitialValue, setUsingInitialValue] = useState(true);
	const [storedValue, setStoredValue] = useState(initialValue);

	const setStoredValueWrapper = (value: T, from = 'not-defined') => {
		setUsingInitialValue(false);
		setStoredValue(value);
		console.log(`setStoredValueWrapper Key=${key}: ${from}`, value);
		
	}

	useEffect(() => {
		if (isFirstLoad) {
			const encryptedStoredValue = window.localStorage.getItem(key);
			const item = encryptedStoredValue ? decryptData(encryptedStoredValue) : null;

			let itemInitial: string | boolean = window.localStorage.getItem(`${key}Inital`) ?? 'true';
			try {
				if (itemInitial) {
					itemInitial = JSON.parse(itemInitial as string) as boolean;
				}
			} catch (error) {
				itemInitial = true;
			}

			if (item) {
				try {
					setUsingInitialValue(itemInitial as boolean);
					setStoredValue(JSON.parse(item) as T);
				} catch (error) {
					setUsingInitialValue(true);
					setStoredValue(initialValue);
				}
			}
			setIsFirstLoad(!isFirstLoad);
		}
	}, [key, isFirstLoad]);

	useEffect(() => {
		if (!isFirstLoad) {

			const encryptedStoredValue = encryptData(JSON.stringify(storedValue));
			if (usingInitialValue) {
				window.localStorage.setItem(`${key}Inital`, JSON.stringify(true));
			} else {
				window.localStorage.setItem(`${key}Inital`, JSON.stringify(false));
			}
			window.localStorage.setItem(key, encryptedStoredValue);
		}
	}, [storedValue, key, isFirstLoad]);


	function reset() {
		window.localStorage.removeItem(key);
		// window.localStorage.removeItem(`${key}Inital`);
		window.localStorage.setItem(`${key}Inital`, JSON.stringify(true));
		setIsFirstLoad(true);
		setUsingInitialValue(true);
		setStoredValue(initialValue);
	}

	return [storedValue, setStoredValueWrapper, isFirstLoad, usingInitialValue, reset];
}