import React, { use, useState } from 'react';
import { Typography } from '@mui/material';
import ErrorMessage from '@/components/shared/forms/ErrorMessage';
import { useTranslations } from 'next-intl';

import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';

type Props = {
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formik?: any;
  errors?: any;
};

type FileWithPreview = {
  name: string;
  preview: string;
};

const Dropzone = ({ handleChange: externalHandleChange, formik, errors }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const t = useTranslations();

  const onDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsHovering(true);
  };

  const onDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsHovering(false);
  };

  const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsHovering(false);
    const newFiles = Array.from(e.dataTransfer.files).map(file => ({
      name: file.name,
      preview: URL.createObjectURL(file),
    }));

    const totalFiles = files.length + newFiles.length > 1 ? newFiles.slice(0, 1 - files.length) : newFiles;
    setFiles([...files, ...totalFiles]);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).map(file => ({
        name: file.name,
        preview: URL.createObjectURL(file),
      }));

      const totalFiles = files.length + newFiles.length > 1 ? newFiles.slice(0, 1 - files.length) : newFiles;
      setFiles([...files, ...totalFiles]);
      formik.setFieldTouched("fileUpload", true);

      if (externalHandleChange) {
        externalHandleChange(e);
      }
    }
  };

  const removeFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  const dropzoneStyle = {
    display: 'inline-block',
    width: '100%',
    borderRadius: '10px',
    cursor: 'pointer',
    backgroundColor: isHovering ? '#e9e9e9' : '#f9f9f9',
    transition: 'background-color 0.3s',
  };

  return (
    <label
      htmlFor='file-upload'
      style={dropzoneStyle}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className='outline-dashed outline-1 outline-gray-400 rounded-lg h-full m-4 pt-3 pb-2'>
        <input
          id='file-upload'
          type="file"
          name='fileUpload'
          style={{ display: 'none' }}
          onChange={handleFileChange}
          multiple
          accept=".pdf, .png"
        />
        {files.length > 0 ? (
          <div>
            {files.map((file, index) => (
              <div key={index} className="flex justify-between px-3" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <div className='flex gap-2 items-center'>
                  <Image
                    loading="lazy"
                    src="Icons/document_rfm1vk"
                    alt='Document'
                    width={24}
                    height={31.11}
                    className='h-6 object-contain'
                  />

                  <Typography variant="body1" className='font-normal normal-case text-black'>
                    {file.name}
                  </Typography>
                </div>
                <Image
                  loading="lazy"
                  src="Icons/cross_rsjvsq"
                  alt="Remove file"
                  onClick={() => removeFile(file.name)}
                  width={16}
                  height={16}
                  className='h-auto'
                />
              </div>
            ))}
            <ErrorMessage>
              {errors.fileUpload && errors.fileUpload}
            </ErrorMessage>
          </div>
          ) : (
          <div className="text-center text-black">
            <HeadingText variant="h6" className='text-gray-600 cursor-pointer'>{t('upload_your_cv')}</HeadingText>
            <Typography variant="body1" className='font-normal normal-case mb-3 text-gray-400 cursor-pointer'>{t('pdf_or_png_max_1_file')}</Typography>
          </div>
        )}
      </div>
    </label>
  );
};

export default Dropzone;