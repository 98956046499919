import { Container, Typography } from '@mui/material';
import React from 'react';
import NPM_ArrowCard from '@/components/shared/_NPM_ArrowCard';
import classNames from 'classnames';

type Card = {
    type?: "inside" | "icon";
    icon?: string;
    title?: string;
    text?: string;
    arrow?: boolean;
    link?: string;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    padding?: string;
    className?: string;
    titleClassName?: string;
};

type Props = {
    cards: Card[];
    text?: string;
    gap?: string;
    className?: string;
};

const ArrowCardGrid = ({ cards, text, gap, className }: Props) => {
    return (
        <>
            <div className={`grid grid-cols-1 md:grid-cols-2 ${className} ${gap ? gap : 'gap-7'} mt-5`}>
                {cards.map((card, index) => (
                    <NPM_ArrowCard
                        key={index}
                        type="inside"
                        arrow={false}
                        title={card.title}
                        text={card.text}
                        padding='p-0'
                        className='min-h-175px'
                    />
                ))}
            </div>

            {text &&
                <Typography className='text-center md:text-start normal-case mt-5 max-w-screen-md' variant='body1'>
                    {text}
                </Typography>
            }
        </>
    );
};

export default ArrowCardGrid;