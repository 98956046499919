'use client'

import { DefaultFieldProps, FormValue, Option } from '@/lib/types/shared/form.types'
import { Params } from '@/lib/types/shared/page.types'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'


type Props = DefaultFieldProps & {
    params: Params,
    selectedValue: FormValue,
    options: Array<Option>
    isMultiple?: boolean
}

export default function Select({
    params,
    changeCallBack,
    translation = null,
    className = "",
    name,
    id,
    options,
    isMultiple = false,
    selectedValue,
}: Props) {
    const t_global = useTranslations();
    let t = useTranslations(translation?.namespace ?? '') as any;
    if (translation === null) {
        t = (string: string) => string;
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (isMultiple) {
            const value = Array.from(event.target.selectedOptions).map(option => option.value);
            changeCallBack(name, value)
        } else {
            const value = event.target.value;
            changeCallBack(name, value)
        }
    }

    const spreadProps: any = {};
    if (isMultiple) {
        if (selectedValue == "null" || selectedValue == null) {
            selectedValue = [];   
        }
        spreadProps.value = selectedValue ?? [];
        spreadProps.multiple = true;
    } else {
        spreadProps.value = selectedValue ?? 'null';
    }
    

    return (
        <>
            <select {...spreadProps} autoComplete='no' onChange={(e) => handleChange(e)} id={id} name={name} className={`${className} py-2 px-5 rounded-xl border-none inline-flex text-gray-950 ring-0 ring-transparent shadow-inner text-base font-bold`}>
                {isMultiple ? (
                    <option disabled value={'null'}>{t_global('shared.input_types.multiple_select.placeholder')}</option>
                ) : (
                    <option disabled value={'null'}>{t_global('shared.input_types.select.placeholder')}</option>
                )}
                {options.map((option, index) => {
                    const optionSpreadProps: any = {};

                    optionSpreadProps.disabled = option.disabled ?? false;

                    return (
                        <option {...optionSpreadProps} key={index} value={option.value ?? 'null'}>{t(option.label)}</option>
                    )
                })}
            </select>
        </>
    )
}
