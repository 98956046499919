'use client'

import React, { useState } from 'react'

type Props = {
    cookiename: string
    label: string
    disabled?: boolean
    onchange: (name: string, value: string) => void,
    checked?: boolean
}

const Toggle = ({ cookiename, label, disabled = false, onchange, checked = false }: Props) => {
    const [isChecked, setIsChecked] = useState(checked)
    return (
        <label aria-disabled={disabled} className={`flex gap-3 cursor-pointer select-none items-center ${disabled ? 'cursor-default opacity-60' : ''}`}>
            <div className='relative'>
                <input
                    disabled={disabled}
                    type='checkbox'
                    checked={isChecked}
                        onChange={() => {
                            setIsChecked(!isChecked)
                            onchange(cookiename, isChecked ? 'denied' : 'granted')}
                        } 
                    className='sr-only'
                />
                <div className={`block p-1 rounded-full ${isChecked ? 'bg-primary-500' : 'bg-white-accent-50'} transition-all duration-200`}>
                    <div className='h-3 w-8'></div>
                </div>
                <div className={`h-3 w-3 dot absolute ${isChecked ? 'left-9/10 -translate-x-full bg-primary-yellow-400' : 'left-1/20 bg-primary-yellow-400'} top-1 rounded-full transition-all duration-200`}></div>
            </div>
            <div>
                <span>
                    {label}
                </span>
            </div>
        </label>
    )
}

export default Toggle